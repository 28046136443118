import MainLayout from "../layouts/MainLayout";
import Error404 from "../pages/Error404";
import calendar from "./calendar";
import customer from "./customer";
import document from "./document";
import offer from "./offer";
import order from "./order";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [...customer, ...offer, ...document, ...order, ...calendar],
  },
  {
    path: "*",
    element: <Error404 />,
  },
];
export default routes;
