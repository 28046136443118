import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { storeType } from "../types";

type defaultFilterType = {
  productFamilyId: null | string;
  storeId: null | string;
};
type storeDefaultFilterType = Omit<storeType, "storeId"> & { id: string };
type profileType = {
  userId: string;
  userGroupId: string;
  userGroupName: string;
  companyAvatarThumbnailUrl: string | null;
  companyId: string;
  companyName: string;
  userType: number;
  firstName: string;
  lastName: string;
  username: string;
  emailAddress: string;
  personalNumber: null;
  dateOfBirth: string;
  gender: number;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  mobileNumber: string;
  phoneNumber: string;
  imageUrl: null;
  userObjectId: null;
  defaultFilter: null | defaultFilterType;
  storeDefaultFilter: null | storeDefaultFilterType;
  productFamilyDefaultFilter: null;
  authenticationProviders: null;
  invitationInfo: null;
  stores: null | storeType[];
};
const profile = createSlice({
  name: "profile",
  initialState: {} as profileType,
  reducers: {
    setProfile: (state, action: PayloadAction<profileType>) => {
      return action.payload;
    },
  },
});

export const { setProfile } = profile.actions;
export default profile.reducer;
