import { useContext } from "react";
import { Icon, Text, Wrapper } from "../../../../components";
import { dateFormats } from "../../../../constants";
import { OfferContext } from "..";
import { copyText } from "../../../../methods";
import { toast } from "react-hot-toast";
import { useConverters } from "../../../../hooks";
export default function Info() {
  const { offerData } = useContext(OfferContext);
  const { convertDate } = useConverters();
  // const submit = () => {
  //   const url = "";
  //   const body = {};
  //   axios.put(url, body).then(() => {});
  // };
  const copyOfferNumber = () => {
    copyText(offerData.offerNumber).then(() => {
      toast.success("toast.success.copyOfferNumber");
    });
  };
  return (
    <div className="grid grid-cols-12 gap-4">
      {/* <Form onSubmit={submit} className="space-y-4 col-span-full">
        <div className="flex items-center justify-between">
          <h6>
            <Text>global.submit</Text>
          </h6>
          <Button type="submit">
            <Text>button.submitChanges</Text>
          </Button>
        </div>
        <Wrapper>
          <Wrapper.Body>
            <InputGroup
              as="textarea"
              label="formControls.note"
              value={""}
              setValue={() => {}}
              rules={rules.required}
            />
          </Wrapper.Body>
        </Wrapper>
      </Form> */}
      <Wrapper className="col-span-full">
        <Wrapper.Body className="flex items-start gap-4">
          <Icon
            name="DiscountShape"
            variant="Outline"
            size={24}
            className="text-primary"
          />
          <div className="flex-1 space-y-1">
            <h6>{offerData.title}</h6>
            <p className="text-secondary leading-7" onClick={copyOfferNumber}>
              #{offerData.offerNumber}
            </p>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="col-span-6">
        <Wrapper.Body className="flex items-start gap-4">
          <Icon
            name="User"
            variant="Outline"
            size={24}
            className="text-primary"
          />
          <div className="flex-1 space-y-1">
            <h6>
              <Text>global.customerInfo</Text>
            </h6>
            <h6 className="font-normal">
              {offerData.customer?.personInfo?.firstName}{" "}
              {offerData.customer?.personInfo?.lastName}
            </h6>
            <p className="text-secondary leading-7">
              #{offerData.customer?.cimNumber} <br />
              {offerData.customer?.personInfo?.emailAddress} |{" "}
              {offerData.customer?.personInfo?.mobileNumber} <br />
            </p>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="col-span-3">
        <Wrapper.Body className="flex items-start gap-4">
          <Icon
            name="InfoCircle"
            variant="Outline"
            size={24}
            className="text-primary"
          />
          <div className="flex-1 space-y-1">
            <h6>
              <Text>global.createdAt</Text>
            </h6>
            <p className="text-secondary leading-7">
              {convertDate(offerData.creationAt)} <br />
              {convertDate(offerData.creationAt, dateFormats.onlyTime)} <br />
              <Text>global.by</Text>: {offerData.creatorName}
            </p>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="col-span-3">
        <Wrapper.Body className="flex items-start gap-4">
          <Icon
            name="InfoCircle"
            variant="Outline"
            size={24}
            className="text-primary"
          />
          <div className="flex-1 space-y-1">
            <h6>
              <Text>global.lastUpdate</Text>
            </h6>
            {offerData.updatedAt ? (
              <p className="text-secondary leading-7">
                {convertDate(offerData.updatedAt)} <br />
                {convertDate(offerData.updatedAt, dateFormats.onlyTime)} <br />
                <Text>global.by</Text>: {offerData.editorName}
              </p>
            ) : (
              <p className="text-secondary leading-7">
                -- <br />
                -- <br />
                --
              </p>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
