import { Link } from "react-router-dom";
import Text from "./_Text";
import Icon from "./_Icon";
type backButtonProps = {
  className?: string;
};
export default function BackButton({ className }: backButtonProps) {
  return (
    // @ts-ignore: Unreachable code error
    <Link to={-1} className={className}>
      <Icon name="ArrowLeft2" size={24} />
      <Text>button.back</Text>
    </Link>
  );
}
