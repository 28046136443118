import { fileDataType } from "../types";
import useAxios from "./useAxios";

type fileType = Blob | string;
type uploadFileType = (file: fileType) => Promise<fileDataType>;

export default function useUploadFile() {
  const { axios } = useAxios();
  const uploadFile: uploadFileType = (file) => {
    return new Promise((resolve, reject) => {
      const isFile = typeof file === "object";
      if (!isFile) return resolve({ url: file } as fileDataType);
      const url = "/assetservice/api/assets/batch";
      const body = new FormData();
      const config = { timeout: 10 * 60 * 1000 };
      body.set("formFile", file);
      axios
        .post(url, body, config)
        .then(({ data }) => {
          resolve(data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return uploadFile;
}
