import DocumentDetails from "../pages/Documents/Details";

const document = [
  {
    path: "/offers/:offerId/documents/:documentId",
    element: <DocumentDetails />,
  },
  {
    path: "/orders/:orderId/documents/:documentId",
    element: <DocumentDetails />,
  },
];
export default document;
