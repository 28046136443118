import { useState } from "react";
import { Dropdown, Icon, InputGroup, Loading } from "../components";
import { useAxios } from "../hooks";
import { debounce } from "../methods";
import { searchAddressType } from "../types";
type addressItemsType = {
  name: string;
  id: string;
  address: searchAddressType;
}[];
type searchAddressProps = {
  label?: string;
  setValue: (val: searchAddressType) => void;
};
export default function SearchAddress({ label, setValue }: searchAddressProps) {
  const { axios, loading } = useAxios();
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<addressItemsType>([]);
  const handleSetSearch = (e: string) => {
    setSearch(e);
    debounce(() => handleSearchAddress(e));
  };
  const handleSearchAddress = (query: string) => {
    if (!query) return setItems([]);
    const url = "/addressservice/api/address/search";
    const config = { params: { countrySet: "DE", query } };
    axios.get(url, config).then(({ data }) => {
      const result = data.results.map((e: any) => ({
        name: e.address?.freeformAddress,
        id: e.id,
        address: {
          street: e.address?.streetName,
          number: e.address?.streetNumber,
          postalCode: e.address?.postalCode,
          city: e.address?.municipality,
          state: e.address?.countrySubdivision,
          country: e.address?.countryCode,
        },
      }));
      setItems(result);
    });
  };
  const handleSetAddress = (id: string) => {
    const address = items.find((e) => e.id === id)?.address;
    if (address) setValue(address);
  };
  return (
    <Dropdown
      data-active={"true"}
      className="w-full"
      onSelect={handleSetAddress}
    >
      <Dropdown.Toggle
        as={InputGroup}
        value={search}
        setValue={handleSetSearch}
        label={label}
        placeholder="placeholders.searchAddress"
        append={
          <span className="input-group-text text-primary">
            <Icon name="ArrowDown2" size={18} />
          </span>
        }
      />
      <Dropdown.Menu>
        {loading.get ? (
          <Loading.Inline />
        ) : (
          items.map((e) => (
            <Dropdown.Item key={e.id} eventKey={e.id}>
              {e.name}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
