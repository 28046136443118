import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { authInstance, loginRequest } from "../constants";

export default async function getToken() {
  return await authInstance
    .acquireTokenSilent(loginRequest)
    .then((res) => {
      return res.idToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return authInstance.acquireTokenRedirect(loginRequest);
      }
    });
}
