import { Fragment, useContext, useEffect, useState } from "react";
import { ImportBasketContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { useAxios, useConverters } from "../../../../../hooks";
import { basketItemType, pagingDataType } from "../../../../../types";

type dataType = pagingDataType<basketItemType>;
type paramsType = {
  pageNumber: number;
  pageSize: number;
  keyword: string | null;
};
export default function BasketList() {
  const { axios, loading } = useAxios();
  const { convertDate, convertAmount } = useConverters();
  const { setActiveBasket } = useContext(ImportBasketContext);
  const [data, setData] = useState({} as dataType);
  const [params, setParams] = useState<paramsType>({
    keyword: null,
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const getData = () => {
    const url = "/productservice/api/offers/get-iwofurn-baskets";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const handleSetParams = (key: keyof paramsType) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) =>
      setParams((p) => ({
        ...p,
        [key]: value,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox
          onSubmit={handleSetParams("keyword")}
          variant="gray"
          placeholder="placeholders.searchBaskets"
        />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {data.items?.map((e) => (
            <List.Item
              key={e.tag}
              onClick={() => setActiveBasket(e)}
              className="flex items-start w-full text-start gap-4 py-2 border-b border-dashed border-gray-100 last:border-none"
            >
              <Icon.Wrapper>
                <Icon name="ShoppingCart" variant="Bold" />
              </Icon.Wrapper>
              <section className="flex-1">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <h6 className="block truncate text-gray-900">
                      #{e.internalTag}
                    </h6>
                    <p className="text-sm truncate text-secondary">
                      {convertAmount(e.price)}
                    </p>
                  </div>
                  <Button as="span" light>
                    <Text>button.choose</Text>
                  </Button>
                </div>
                <div className="flex items-center text-sm text-gray-900 mt-4">
                  <p className="truncate flex-1">#{e.tag}</p>
                  <p>
                    <Icon name="Calendar" variant="Bold" size={16} />{" "}
                    <Text>global.createdAt</Text>: {convertDate(e.stamp)}
                  </p>
                </div>
              </section>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          pageNumber={params.pageNumber}
          setActivePage={handleSetParams("pageNumber")}
          pageSize={params.pageSize}
          totalItems={data.totalItems}
          totalPages={data.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
