import { types } from "../constants";
import Badge from "./_Badge";
import Icon from "./_Icon";
import Text from "./_Text";
type typesProps = {
  id: number;
};
function Program({ id }: typesProps) {
  const activeType = types.program.find((e) => e.id === id);
  return (
    <Badge variant={activeType?.variant ?? "dark"} fill>
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function Product({ id }: typesProps) {
  const activeType = types.product.find((e) => e.id === id);
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      // className="py-1 px-2 text-xs"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function IncidentPriority({ id }: typesProps) {
  const activeType = types.incidentPriority.find((e) => e.id === id);
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      className="py-1 px-2 text-xs"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function IncidentOrigin({ id }: typesProps) {
  const activeType = types.incidentOrigin.find((e) => e.id === id);
  return (
    <Badge variant={null} className="py-1 px-2 bg-gray-50 text-primary">
      {activeType?.icon && (
        <Icon
          name={activeType.icon}
          variant="Bulk"
          size={20}
          className="mr-1"
        />
      )}
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
const Types = { Program, Product, IncidentPriority, IncidentOrigin };
export default Types;
