import { createSlice } from "@reduxjs/toolkit";

const selectStoreActions = createSlice({
  name: "selectStoreActions",
  initialState: {
    showSelectStoreModal: false,
    showSelectStoreMenu: false,
  },
  reducers: {
    toggleSelectStoreModal: (state) => {
      state.showSelectStoreModal = !state.showSelectStoreModal;
      return state;
    },
    toggleSelectStoreMenu: (state) => {
      state.showSelectStoreMenu = !state.showSelectStoreMenu;
      return state;
    },
  },
});

export const { toggleSelectStoreMenu, toggleSelectStoreModal } =
  selectStoreActions.actions;
export default selectStoreActions.reducer;
