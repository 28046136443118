import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { ProductContext } from ".";
import { OfferContext } from "../..";
import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Loading,
  Text,
} from "../../../../../components";
import { rules } from "../../../../../constants";
import { useAxios, useConverters } from "../../../../../hooks";
import { convertNumber, debounce } from "../../../../../methods";
import { discountLineItemType, lineItemType } from "../../../../../types";
export default function ManualForm() {
  const { axios, loading } = useAxios();
  const { offerData } = useContext(OfferContext);
  const {
    supplier,
    program,
    product,
    setProduct,
    clearProduct,
    isPimProduct,
    toggle,
  } = useContext(ProductContext);
  const { convertAmount } = useConverters();
  const calculatedPrice = product?.discount ?? null;
  // const hasSupplierArticleNumber = useMemo(() => {
  //   return !!product?.supplierArticleNumber;
  // }, []);
  const totalLineItemPrice = useMemo(() => {
    const quantity = Math.floor(product?.quantity || 1);
    const finalPrice = calculatedPrice?.finalPrice || 0;
    return quantity * finalPrice;
  }, [calculatedPrice, product]);
  const setCalculatedPrice = (discount: discountLineItemType | null) => {
    setProduct((p) => {
      const data = cloneDeep(p);
      if (!!data) data.discount = discount;
      return data;
    });
  };
  const handleValue = (key: keyof lineItemType) => {
    if (!product) return "";
    if (key === "translates") return product.translates?.at(0)?.displayName;
    if (key === "unitPrice") return product.unitPrice?.amount;
    if (key === "quantity") return product.quantity ?? "";
    return product[key];
  };
  const handleSetValue = (
    key: keyof Omit<
      lineItemType,
      "productType" | "totalSalePrice" | "totalDiscountAmount"
    >
  ) => {
    return (value: any) => {
      key === "unitPrice" && debounce(() => calculatePrice(value));
      setProduct((p) => {
        const data = !!p ? cloneDeep(p) : ({} as lineItemType);
        if (key === "translates") {
          data.translates = [{ language: "de", displayName: value }];
          return data;
        }
        if (key === "unitPrice") {
          data.unitPrice = {
            amount: value,
            currency: "EUR",
            currencySymbol: "€",
          };
          return data;
        }
        if (key === "quantity") {
          data.quantity = value;
          return data;
        }
        data[key] = value;
        return data;
      });
    };
  };
  const handleChangeAttribute = (
    index: number,
    key: "name" | "code" | "value"
  ) => {
    return (value: string) =>
      setProduct((p) => {
        const prevData = !!p ? cloneDeep(p) : ({} as lineItemType);
        const attributes = prevData.programAttributes;
        attributes[index][key] = value;
        return prevData;
      });
  };
  const handleRemoveAttribute = (index: number) => {
    return () =>
      setProduct((p) => {
        const prevData = !!p ? cloneDeep(p) : ({} as lineItemType);
        const attributes = prevData.programAttributes;
        attributes.splice(index, 1);
        return prevData;
      });
  };
  const addNewAttribute = () => {
    setProduct((p) => {
      const prevData = !!p ? cloneDeep(p) : ({} as lineItemType);
      const attributes = prevData.programAttributes ?? [];
      const attribute = {
        name: "",
        code: "",
        value: "",
        description: "placeholders.value",
        isRequired: true,
        isAttributeProgram: false,
      };
      attributes.push(attribute);
      prevData.programAttributes = attributes;
      return prevData;
    });
  };
  const calculatePrice = (amount: number | null | undefined) => {
    setCalculatedPrice(null);
    const hasPrice = !!amount && `${amount}` !== "0";
    if (!hasPrice) return;
    const url = "/productservice/api/priceengine";
    const body = {
      supplierId: supplier?.supplierId,
      programId: program?.programId,
      storeId: offerData.storeInfo?.storeId,
      basePrice: amount,
    };
    axios.post(url, body).then(({ data }) => {
      setCalculatedPrice({
        discountDetails: data.discounts,
        finalPrice: data.finalPrice,
        productPrice: data.productPrice,
      });
    });
  };
  const handleSetRequiredAttributes = () => {
    if (!program) return;
    setProduct((p) => {
      if (!p) return p;
      const prevData = cloneDeep(p);
      if (!!prevData.programAttributes?.length) return prevData;
      prevData.programAttributes = program.attributes?.map((e) => ({
        name: e.name,
        code: e.code,
        value: e.value,
        description: e.description,
        isRequired: e.isRequired,
        isAttributeProgram: true,
      }));
      prevData.programAttributes ??= [];
      return prevData;
    });
  };
  const handleSetCalculatedPrice = () => {
    if (calculatedPrice) return;
    calculatePrice(product?.unitPrice?.amount);
  };
  useEffect(handleSetRequiredAttributes, [program]);
  useEffect(handleSetCalculatedPrice, []);
  return (
    <Fragment>
      <Drawer.Body className="space-y-6">
        <Button
          type="button"
          outline
          className="block w-fit btn-sm"
          onClick={clearProduct}
        >
          <Icon name="ArrowLeft2" size={16} /> <Text>button.productList</Text>
        </Button>
        <InputGroup
          label="formControls.productTitle"
          value={handleValue("translates")}
          setValue={handleSetValue("translates")}
          rules={rules.required}
        />
        <InputGroup
          label="formControls.supplierArticleNumber"
          value={handleValue("supplierArticleNumber")}
          setValue={handleSetValue("supplierArticleNumber")}
          // readOnly={isPimProduct && hasSupplierArticleNumber}
          rules={rules.required}
        />
        <div className="flex gap-4">
          <div className="flex-[2]">
            <InputGroup
              label="formControls.articleNumber"
              value={handleValue("articleNumber")}
              setValue={handleSetValue("articleNumber")}
              readOnly={isPimProduct}
              rules={rules.required}
            />
          </div>
          <div className="flex-1">
            <InputGroup
              label="formControls.quantity"
              value={handleValue("quantity")}
              setValue={handleSetValue("quantity")}
              type="number"
              rules={rules.integer}
            />
          </div>
        </div>
        <div className="space-y-1 [&_input]:text-end [&_input::placeholder]:text-start">
          <InputGroup
            label="formControls.price"
            type="price"
            value={handleValue("unitPrice")}
            setValue={handleSetValue("unitPrice")}
            placeholder="global.supplierPrice"
            rules={rules.required}
          />
          {loading.post ? (
            <Loading.Inline />
          ) : (
            calculatedPrice && (
              <table className="w-full [&_td]:py-1 [&_td]:px-4 text-gray-900">
                <tbody>
                  <tr className="text-sm">
                    <td className="text-start">
                      <Text>global.productPrice</Text>:
                    </td>
                    <td></td>
                    <td className="text-end">
                      {convertAmount(calculatedPrice.productPrice)}
                    </td>
                  </tr>
                  {calculatedPrice.discountDetails
                    ?.sort((a, b) => a.order - b.order)
                    .map((e) => (
                      <tr key={`${e.title}${e.percent}`} className="text-sm">
                        <td className="text-start">{e.title}</td>
                        <td className="text-start">
                          %{convertNumber(e.percent)}
                        </td>
                        <td className="text-end text-danger">
                          -{convertAmount(e.amount)}
                        </td>
                      </tr>
                    ))}
                  <tr className="border-t border-gray-50 [&_td]:py-2">
                    <td className="text-start">
                      <Text>global.finalPrice</Text> (€)
                    </td>
                    <td></td>
                    <td className="text-end">
                      {convertAmount(calculatedPrice.finalPrice)}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-50 [&_td]:py-2">
                    <td className="text-start">
                      <Text>global.totalLineItemPrice</Text> (€)
                    </td>
                    <td></td>
                    <td className="text-end">
                      {convertAmount(totalLineItemPrice)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          )}
        </div>
        <InputGroup
          as="textarea"
          label="formControls.description"
          value={handleValue("shortDescription")}
          setValue={handleSetValue("shortDescription")}
        />
        {product?.programAttributes?.map((e, index) => (
          <div key={index} className="space-y-2">
            {e.isAttributeProgram ? (
              <section className="flex-center">
                <div className="flex-1">
                  <h6>{e.name}</h6>
                  <span className="block text-secondary">{e.code}</span>
                </div>
              </section>
            ) : (
              <section className="flex gap-2">
                <div className="flex-[2]">
                  <InputGroup
                    value={e.name}
                    setValue={handleChangeAttribute(index, "name")}
                    placeholder="placeholders.attributeName"
                    rules={rules.required}
                  />
                </div>
                <div className="flex-1">
                  <InputGroup
                    value={e.code}
                    setValue={handleChangeAttribute(index, "code")}
                    placeholder="placeholders.code"
                    rules={rules.required}
                  />
                </div>
                <button
                  type="button"
                  onClick={handleRemoveAttribute(index)}
                  className="bg-gray-50 h-11 aspect-square flex-center rounded-md"
                >
                  <Icon
                    name="Trash"
                    variant="Bold"
                    className="text-danger"
                    size={20}
                  />
                </button>
              </section>
            )}
            <InputGroup
              value={e.value}
              setValue={handleChangeAttribute(index, "value")}
              rules={e.isRequired ? rules.required : []}
              placeholder={e.description}
            />
          </div>
        ))}
        <Button outline onClick={addNewAttribute}>
          <Icon name="Add" size={20} /> <Text>button.addAttribute</Text>
        </Button>
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button variant="danger" onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button type="submit" disabled={loading.post}>
          <Text>button.submit</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
