import { Fragment } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { Button, Icon, Modal, Text } from "../../../components";
import { useAxios } from "../../../hooks";
import { iconNameType, togglePropsType } from "../../../types";
type createOfferProps = togglePropsType & {
  data: any | null;
};
type actionType = {
  icon: iconNameType;
  label: string;
  action: () => void;
};
export default function OfferActions({
  isOpen,
  toggle,
  data,
}: createOfferProps) {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const goToCustomerDetails = () => {
    navigate(`/customers/${data?.customer?.id}`);
  };
  const sendEmail = () => {
    const url = `/productservice/api/offers/${data?.offerId}/sendmail`;
    axios.post(url).then(() => {
      toast.success("toast.success.sendOfferMail");
      toggle();
    });
  };
  const actions: actionType[] = [
    {
      icon: "Send2",
      label: "actionTitles.sendEmailOffer",
      action: sendEmail,
    },
    {
      icon: "UserSquare",
      label: "actionTitles.customerDetailsOffer",
      action: goToCustomerDetails,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="mb-1">{data?.title}</h6>
        <span className="block text-secondary">#{data?.offerNumber}</span>
      </Modal.Header>
      <Modal.Body>
        {actions.map((e, i) => (
          <Fragment key={e.label}>
            {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
            <button
              type="button"
              className="w-full flex-center gap-4"
              onClick={e.action}
              disabled={loading.post}
            >
              <Icon.Wrapper variant="secondary">
                <Icon name={e.icon} variant="Bold" className="text-primary" />
              </Icon.Wrapper>
              <span className="flex-1 text-start">
                <Text>{e.label}</Text>
              </span>
            </button>
          </Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle} loading={loading.post}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
