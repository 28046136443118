import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { CustomerContext } from ".";
import {
  Button,
  CheckBox,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Wrapper,
} from "../../../components";
import { countries, rules, types } from "../../../constants";
import { SearchAddress } from "../../../containers";
import { useAxios, useDataState, useToggleState } from "../../../hooks";

export default function Overview() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const { customerId } = useParams();
  const { customerData, setCustomerData } = useContext(CustomerContext);
  const [activeConfirm, toggleConfirm] = useToggleState(false);
  const [data, setData, setBaseData, isChanged] =
    useDataState<any>(customerData);
  const handleValue = (key: string, parentKey: string) => {
    if (parentKey === "addresses") return data.addresses[0][key];
    return data.personInfo[key];
  };
  const handleSetData = (key: string, parentKey: string) => {
    if (parentKey === "addresses")
      return (val: string) =>
        setData((p: any) => ({
          ...p,
          addresses: [{ ...p.addresses[0], [key]: val }],
        }));
    return (val: string) =>
      setData((p: any) => ({
        ...p,
        personInfo: { ...p.personInfo, [key]: val },
      }));
  };
  const removeCustomer = () => {
    const url = `/accountservice/api/customers/${customerId}`;
    axios.delete(url).then(() => {
      toast.success("toast.success.removeCustomer");
      navigate("/customers");
    });
  };
  const submitChange = () => {
    const url = `/accountservice/api/customers/${customerId}`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      const message = "toast.success.editCustomer";
      toast.success(message);
      setCustomerData(body);
      setBaseData(body);
    });
  };
  const formItems1 = [
    {
      parentKey: "personInfo",
      items: [
        [
          {
            label: "formControls.firstName",
            state: "firstName",
            rules: rules.required,
          },
        ],
        [
          {
            label: "formControls.lastName",
            state: "lastName",
            rules: rules.required,
          },
        ],
      ],
    },
    {
      parentKey: "addresses",
      items: [
        [
          {
            label: "formControls.name",
            state: "name",
          },
        ],
        [
          {
            label: "formControls.street",
            state: "street",
          },
          {
            label: "formControls.number",
            state: "number",
          },
        ],
        [
          {
            label: "formControls.postalCode",
            state: "postalCode",
          },
          {
            label: "formControls.city",
            state: "city",
          },
        ],
        [
          {
            Element: Select,
            label: "formControls.country",
            state: "country",
            items: countries,
          },
          {
            Element: Select,
            label: "formControls.state",
            state: "state",
            items: countries.find((e) => e.id === data.addresses[0].country)
              ?.states,
          },
        ],
      ],
    },
  ];
  const formItems2 = [
    {
      parentKey: "personInfo",
      items: [
        [
          {
            label: "formControls.birthDate",
            state: "birthdate",
            type: "date",
          },
        ],
      ],
    },
    {
      parentKey: "personInfo",
      items: [
        [
          {
            Element: Select,
            label: "formControls.gender",
            state: "gender",
            items: types.gender,
          },
        ],
        [
          {
            Element: Select,
            label: "formControls.title",
            state: "title",
            items: types.title,
          },
        ],
      ],
    },
    {
      parentKey: "personInfo",
      items: [
        [
          {
            label: "formControls.email",
            state: "emailAddress",
            rules: rules.email,
          },
        ],
        [
          {
            label: "formControls.countryCode",
            state: "countryCode",
            rules: rules.required,
          },
          {
            flex: 2,
            label: "formControls.mobileNumber",
            state: "mobileNumber",
            rules: rules.required,
          },
        ],
        [
          {
            label: "formControls.phoneNumber",
            state: "phoneNumber",
          },
        ],
      ],
    },
  ];

  return (
    <Fragment>
      <Form onSubmit={submitChange} className="space-y-4">
        <div className="flex items-center justify-between">
          <h6>
            <Text>global.submit</Text>
          </h6>
          <Button type="submit" disabled={!isChanged} loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-4 items-start">
          {[formItems1, formItems2].map((formItems, index) => (
            <div key={index} className="space-y-4">
              {formItems.map((e, index) => (
                <Wrapper key={index}>
                  <Wrapper.Body className="space-y-4">
                    {e.items.map((formControls, index) => (
                      <Fragment key={index}>
                        {e.parentKey === "addresses" && index === 0 && (
                          <Fragment>
                            <SearchAddress
                              label="formControls.searchAddress"
                              setValue={(address) =>
                                setData((p: any) => {
                                  const data = cloneDeep(p);
                                  const addressData = {
                                    ...(p.addresses?.at(0) ?? {}),
                                    ...address,
                                    type: 2,
                                  };
                                  data.addresses = [addressData];
                                  return data;
                                })
                              }
                            />
                            <span className="block text-secondary text-center">
                              <Text>global.orEnterManual</Text>
                            </span>
                          </Fragment>
                        )}
                        <section className="flex-center gap-4">
                          {formControls.map(
                            ({
                              Element = InputGroup,
                              state,
                              flex = 1,
                              ...props
                            }: any) => (
                              <div key={state} style={{ flex }}>
                                <Element
                                  value={handleValue(state, e.parentKey)}
                                  setValue={handleSetData(state, e.parentKey)}
                                  {...props}
                                />
                              </div>
                            )
                          )}
                        </section>
                      </Fragment>
                    ))}
                  </Wrapper.Body>
                </Wrapper>
              ))}
            </div>
          ))}
          <h6 className="col-span-full">
            <Text>page.customerDetails.overview.removeCustomer.title</Text>
          </h6>
          <Wrapper className="col-span-full">
            <Wrapper.Body className="space-y-4">
              <div className="flex gap-4 border border-dashed border-warning rounded p-4 bg-warning/5">
                <Icon
                  name="InfoCircle"
                  // variant="Bulk"
                  size={28}
                  className="text-warning"
                />
                <div className="flex-1 space-y-2">
                  <h6>
                    <Text>alertTitles.deactivateAccount</Text>
                  </h6>
                  <p>
                    <Text>alertDescriptions.deactivateAccount</Text>
                  </p>
                </div>
              </div>
              <CheckBox
                value={activeConfirm}
                setValue={toggleConfirm}
                label="formControls.deActiveCustomer"
              />
            </Wrapper.Body>
            <Wrapper.Footer>
              <Button
                className="block w-fit ml-auto"
                variant="danger"
                onClick={removeCustomer}
                disabled={!activeConfirm}
                loading={loading.delete}
              >
                <Text>button.deactivateAccount</Text>
              </Button>
            </Wrapper.Footer>
          </Wrapper>
        </div>
      </Form>
    </Fragment>
  );
}
