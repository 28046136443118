import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { OfferContext } from "../..";
import { Badge, Button, Drawer, Icon, Text } from "../../../../../components";
import { basketItemType, togglePropsType } from "../../../../../types";
import BasketDetails from "./BasketDetails";
import BasketList from "./BasketList";
type subLineItemProps =
  | {
      isSubLineItem: boolean;
      lineItemId: string;
    }
  | {
      isSubLineItem?: never;
      lineItemId?: never;
    };
type activeBasketType = basketItemType | null;
type importBasketMenuProps = togglePropsType & subLineItemProps;
type contextType = {
  activeBasket: activeBasketType;
  setActiveBasket: Dispatch<SetStateAction<activeBasketType>>;
  clearActiveBasketTag: () => void;
  isSubLineItem: boolean;
  lineItemId: string;
  toggle: () => void;
};
export const ImportBasketContext = createContext({} as contextType);
export default function ImportBasketMenu({
  isOpen,
  toggle,
  isSubLineItem = false,
  lineItemId = "",
}: importBasketMenuProps) {
  const { offerData } = useContext(OfferContext);
  const [activeBasket, setActiveBasket] = useState<activeBasketType>(null);
  const hasBasket = !!activeBasket;
  const clearActiveBasketTag = () => {
    setActiveBasket(null);
  };
  const handleToggle = () => {
    clearActiveBasketTag();
    toggle();
  };
  const ActiveTab = useMemo(() => {
    if (hasBasket) return BasketDetails;
    return BasketList;
  }, [hasBasket]);

  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} size="lg">
      <Drawer.Menu className="relative overflow-hidden">
        <Drawer.Header className="flex items-center text-end">
          <div className="flex-1 flex">
            {hasBasket && (
              <Button
                variant="primary"
                light
                className="btn-sm"
                onClick={clearActiveBasketTag}
              >
                <Icon name="ArrowLeft" variant="Linear" size={18} />{" "}
                <Text>button.backToBasketList</Text>
              </Button>
            )}
          </div>
          <h6 className="flex-1 text-center">
            <Text>drawerTitles.importBasket</Text>
          </h6>
          <div className="flex-1 flex justify-end">
            {!hasBasket && (
              <Badge
                variant="danger"
                className="bg-gray-50 bg-opacity-100 text-danger"
              >
                <Icon name="Location" variant="Bold" size={20} />{" "}
                {offerData.storeInfo?.title}
              </Badge>
            )}
          </div>
        </Drawer.Header>
        <ImportBasketContext.Provider
          value={{
            activeBasket,
            setActiveBasket,
            clearActiveBasketTag,
            isSubLineItem,
            lineItemId,
            toggle: handleToggle,
          }}
        >
          {isOpen && <ActiveTab />}
        </ImportBasketContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
