import currentLanguage from "./_currentLanguage";

export default function replaceNonDigits(str: string): string {
  if (!str) return "";
  const isDe = currentLanguage() === "de";
  const regex = isDe ? /[^\d,-]/g : /[^\d.-]/g;
  const [int, decimal = null] = str.replace(regex, "").split(isDe ? "," : ".");
  const hasDecimal = decimal !== null;
  if (hasDecimal) {
    return [int, decimal.slice(0, 2)].join(".");
  }
  return int;
}
