import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Toggler,
} from "../../components";
import { rules } from "../../constants";
import { togglePropsType } from "../../types";
export default function AddNewTodoMenu({ isOpen, toggle }: togglePropsType) {
  const timeItems = [
    {
      name: "10",
      id: "10",
    },
    {
      name: "10:30",
      id: "10.5",
    },
    {
      name: "11",
      id: "11",
    },
    {
      name: "11:30",
      id: "11.5",
    },
    {
      name: "12",
      id: "12",
    },
  ];
  const ranges = [
    {
      name: "30 min",
      id: "30",
    },
    {
      name: "60 min",
      id: "60",
    },
    {
      name: "90 min",
      id: "90",
    },
  ];
  const submit = () => {};
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.addNewTodo</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <h6>
            <Icon name="CalendarAdd" variant="Bold" className="text-primary" />{" "}
            Saturday 13 Aug 2023
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-3">
          <section className="grid grid-cols-2 gap-y-2 gap-x-4">
            <h6 className="col-span-full">
              <Text>formControls.calendarTaskTime</Text>
            </h6>
            <Select
              value=""
              items={timeItems}
              rules={rules.required}
              placeholder="placeholders.calendarTaskTime"
            />
            <Select
              value=""
              items={timeItems}
              rules={rules.required}
              placeholder="placeholders.calendarTaskTime"
            />
          </section>
          <div className="flex items-center gap-1">
            {ranges.map((e) => (
              <Button
                key={e.id}
                type="button"
                variant="white"
                className="btn-sm"
              >
                <Text>{e.name}</Text>
              </Button>
            ))}
          </div>
          <Toggler
            label="togglers.calendarTaskAllDay"
            value={false}
            setValue={() => {}}
          />
          <InputGroup
            label="formControls.calendarTaskTitle"
            value=""
            rules={rules.required}
          />
          <InputGroup
            as="textarea"
            label="formControls.calendarTaskDescription"
            value=""
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit">
            <Text>button.submitTodo</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
