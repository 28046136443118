import { useContext, useMemo } from "react";
import { OrderContext } from "..";
import { Icon, Text, Wrapper } from "../../../../components";
import { addressType } from "../../../../types";
type addressProps = {
  type: "billTo" | "shipTo";
};
export default function Address({ type }: addressProps) {
  const isShipTo = type === "shipTo";
  const isBillTo = type === "billTo";
  const { orderData } = useContext(OrderContext);
  const label = useMemo(() => {
    if (isShipTo) return "drawerTitles.shipTo";
    if (isBillTo) return "drawerTitles.billTo";
    return "";
  }, [isShipTo, isBillTo]);
  const addressData = useMemo(() => {
    const initData = {} as addressType;
    if (isBillTo) return orderData.billTo || initData;
    if (isShipTo) return orderData.shipTo || initData;
    return initData;
  }, [isShipTo, isBillTo, orderData.billTo, orderData.shipTo]);
  return (
    <section className="space-y-3">
      <div className="w-full flex items-center justify-between">
        <span>
          <Text>{label}</Text>
        </span>
      </div>
      {isShipTo && (
        <hr className="w-full block bg-[#FFC700] h-1 rounded-full" />
      )}
      {isBillTo && <hr className="w-full block bg-success h-1 rounded-full" />}
      <Wrapper>
        <Wrapper.Body className="h-40 flex flex-col flex-center">
          <Icon
            name="Location"
            variant="Bulk"
            className="block text-primary mb-4"
            size={32}
          />
          <p className="flex-1 text-center text-secondary leading-6">
            <span>
              {addressData?.name} {addressData?.street} {addressData?.number}
            </span>
            <br />
            <span>
              {addressData?.postalCode} {addressData?.city}
            </span>
            <br />
            <span>
              {addressData?.state} {addressData?.country}
            </span>
          </p>
        </Wrapper.Body>
      </Wrapper>
    </section>
  );
}
