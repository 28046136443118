import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { OfferContext } from "..";
import { Button, Form, InputGroup, Modal, Text } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
export default function EditModal({ isOpen, toggle }: togglePropsType) {
  const { axios, loading } = useAxios();
  const { offerData, setOfferData } = useContext(OfferContext);
  const initValue = String(offerData.downPaymentAmount ?? "");
  const [downPayment, setDownPayment] = useState<string>(initValue);
  const handleSetDownPayment = (val: string) => {
    setDownPayment(val);
  };
  const submit = () => {
    const url = `productservice/api/offers/${offerData.offerId}/downpaymentamount`;
    const body = { downPaymentAmount: downPayment };
    axios.patch(url, body).then(() => {
      toast.success("toast.success.setDownPayment");
      setOfferData((p) => {
        const data = cloneDeep(p);
        data.downPaymentAmount = Number(body.downPaymentAmount);
        return data;
      });
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Modal.Header>
          <h6 className="text-center">
            <Text>modalTitles.downPayment</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="space-y-10">
          <InputGroup
            label="formControls.downPayment"
            value={downPayment}
            setValue={handleSetDownPayment}
            type="price"
            rules={[
              ...rules.required,
              (val = "") =>
                (+val > 0 && +val <= offerData.totalAmount) ||
                `value should be between 0 and ${offerData.totalAmount}`,
            ]}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
