import { ComponentProps, ElementType, ReactNode } from "react";
import { asProps } from "../types";
type layoutProps = {
  children: ReactNode;
  className?: string;
};
function Layout<E extends ElementType = "div">({
  as,
  className = "",
  children,
  ...props
}: layoutProps & asProps<E> & ComponentProps<E>) {
  const Component = as || "div";
  return (
    <Component className={`min-h-screen ${className}`} {...props}>
      {children}
    </Component>
  );
}
function LayoutHeader({ className = "", children }: layoutProps) {
  return (
    <header
      className={`sticky top-0 right-0 bg-white shadow w-full z-10 ${className}`}
    >
      <div className="h-16 flex items-center gap-2 px-8 py-3 w-full max-w-screen-2xl mx-auto">
        {children}
      </div>
    </header>
  );
}
function LayoutBody({ className = "", children }: layoutProps) {
  return (
    <div className={`px-8 py-3 w-full max-w-screen-2xl mx-auto ${className}`}>
      {children}
    </div>
  );
}
Layout.Header = LayoutHeader;
Layout.Body = LayoutBody;
export default Layout;
