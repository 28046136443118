import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { OfferContext } from ".";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../../../components";
import { rules } from "../../../constants";
import { useAxios, useSelector } from "../../../hooks";
import { togglePropsType } from "../../../types";
type dataType = {
  orderTitle: string;
  orderNote: string;
};
export default function AcceptMenu({ isOpen, toggle }: togglePropsType) {
  const navigate = useNavigate();
  const profile = useSelector((s) => s.profile);
  const { axios, loading } = useAxios();
  const { offerData } = useContext(OfferContext);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [data, setData] = useState<dataType>({
    orderNote: "",
    orderTitle: "",
  });
  const alert = useMemo(() => {
    const lineItems = offerData.lineItems ?? [];
    const subLineItems =
      offerData.lineItems?.map((e) => e.subLineItems).flat(1) ?? [];

    const hasLineItemSupplier = lineItems
      .map((e) => e.supplier?.id)
      .every(Boolean);
    const hasSubLineItemSupplier = subLineItems
      .map((e) => e.supplier?.id)
      .every(Boolean);
    const hasLineItemProgram = lineItems
      .map((e) => e.programInfo?.programId)
      .every(Boolean);
    const hasSubLineItemProgram = subLineItems
      .map((e) => e.programInfo?.programId)
      .every(Boolean);
    const hasDocument =
      !offerData.documents?.length ||
      offerData.documents?.every((e) => !!e.sign);
    const hasAddress = !!offerData.billTo && !!offerData.shipTo;
    const hasPayment = !!offerData.downPaymentAmount;

    if (!hasLineItemSupplier)
      return {
        title: "alertTitles.supplierLineItemRequired",
        desc: "alertDescriptions.supplierLineItemRequired",
      };
    if (!hasSubLineItemSupplier)
      return {
        title: "alertTitles.supplierSubLineItemRequired",
        desc: "alertDescriptions.supplierSubLineItemRequired",
      };
    if (!hasLineItemProgram)
      return {
        title: "alertTitles.programLineItemRequired",
        desc: "alertDescriptions.programLineItemRequired",
      };
    if (!hasSubLineItemProgram)
      return {
        title: "alertTitles.programSubLineItemRequired",
        desc: "alertDescriptions.programSubLineItemRequired",
      };
    if (!hasAddress)
      return {
        title: "alertTitles.addressRequired",
        desc: "alertDescriptions.addressRequired",
      };
    if (!hasDocument)
      return {
        title: "alertTitles.documentRequired",
        desc: "alertDescriptions.documentRequired",
      };
    if (!hasPayment)
      return {
        title: "alertTitles.paymentConditionRequired",
        desc: "alertDescriptions.paymentConditionRequired",
      };
    return null;
  }, [offerData]);
  const resetData = () => {
    if (isOpen) return;
    setConfirm(false);
  };
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const url = "/productservice/api/orders";
    const body = {
      offerId: offerData.offerId,
      orderTitle: data.orderTitle,
      orderNote: data.orderNote,
      paymentDueDate: null,
      companyPrefixOrderNumber: "1000",
      firstName: profile.firstName,
      lastName: profile.lastName,
      personalNumber: profile.personalNumber,
      gender: profile.gender,
    };
    axios.post(url, body).then(({ data: orderId }) => {
      navigate(`/orders/${orderId}`);
      toast.success("toast.success.acceptOffer");
    });
  };
  useEffect(resetData, [isOpen]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Icon
              name="TickCircle"
              variant="Bold"
              size={16}
              className="text-success"
            />{" "}
            <Text>drawerTitles.acceptOffer</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="space-y-1">
          <Icon.Wrapper className="mx-auto">
            <Icon name="DiscountShape" variant="Bold" />
          </Icon.Wrapper>
          <h6>{offerData.title}</h6>
          <p className="text-secondary">#{offerData.offerNumber}</p>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="formControls.orderTitle"
            value={data.orderTitle}
            setValue={handleSetValue("orderTitle")}
            rules={rules.required}
          />
          <InputGroup
            as="textarea"
            label="formControls.orderNote"
            value={data.orderNote}
            setValue={handleSetValue("orderNote")}
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="space-y-4">
          {alert ? (
            <div className="flex-center gap-4 bg-warning/5 border border-dashed border-warning rounded p-3">
              <Icon
                name="InfoCircle"
                // variant="Bulk"
                size={28}
                className="text-warning"
              />
              <div className="flex-1 space-y-1">
                <h6>
                  <Text>{alert.title}</Text>
                </h6>
                <p className="text-secondary leading-5">
                  <Text>{alert.desc}</Text>
                </p>
              </div>
            </div>
          ) : (
            <CheckBox
              value={confirm}
              setValue={setConfirm}
              label="confirms.acceptOffer"
            />
          )}
          <div className="flex items-center justify-end gap-4">
            <Button variant="danger" onClick={toggle}>
              <Text>button.cancelForNow</Text>
            </Button>
            <Button
              variant="success"
              type="submit"
              disabled={!confirm}
              loading={loading.post}
            >
              <Text>button.submitOffer</Text>
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
