import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { twMerge } from "tailwind-merge";
import { ProfileProvider } from "../providers";
import Menu from "./Menu";

export default function MainLayout() {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive((p) => !p);
  };

  useEffect(() => {
    setIsActive(false);
  }, [location.pathname]);
  return (
    <Fragment>
      <Menu isOpen={isActive} toggle={toggleActive} />
      <main
        className={twMerge(
          "w-full min-h-screen pl-20 transition-[padding-left]",
          isActive && "xl:pl-56"
        )}
      >
        <ProfileProvider>
          <Outlet />
        </ProfileProvider>
      </main>
    </Fragment>
  );
}
