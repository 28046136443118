import { Fragment, useContext, useMemo } from "react";
import { OfferContext } from "..";
import { Icon, Table, Text, Wrapper } from "../../../../components";
import { useConverters, useToggleState } from "../../../../hooks";
import EditModal from "./EditModal";

export default function PaymentConditions() {
  const { convertAmount } = useConverters();
  const { offerData, isEnabled } = useContext(OfferContext);
  const [showEditModal, toggleEditModal] = useToggleState(false);
  const quantity = useMemo(() => {
    const itemsQuantity =
      offerData.lineItems?.map((e) => e.quantity)?.reduce((a, b) => a + b, 0) ??
      0;
    const subItemsQuantity =
      offerData.lineItems
        ?.map((e) => e.subLineItems)
        ?.flat()
        ?.map((e) => e.quantity)
        ?.reduce((a, b) => a + b, 0) ?? 0;
    return itemsQuantity + subItemsQuantity;
  }, [offerData.lineItems]);
  return (
    <Fragment>
      <h6>
        <Text>offerPaymentDetails.text</Text>
      </h6>
      <Wrapper>
        <Wrapper.Body>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text>offerPaymentDetails.charges</Text>
                </th>
                <th>
                  <Text>offerPaymentDetails.quantity</Text>
                </th>
                <th>
                  <Text>offerPaymentDetails.total</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text>offerPaymentDetails.lineItems</Text>
                </td>
                <td>{quantity}</td>
                <td>{convertAmount(offerData.totalAmount)}</td>
              </tr>
            </tbody>
          </Table>
        </Wrapper.Body>
      </Wrapper>
      {/* <Wrapper className="w-1/2 ml-auto">
        <Wrapper.Body>
          <Table>
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td className="text-end">€5,500.00</td>
              </tr>
              <tr>
                <td>Tax</td>
                <td className="text-end">€51.75</td>
              </tr>
              <tr>
                <td>Total</td>
                <td className="text-end">€5,551.75</td>
              </tr>
            </tbody>
          </Table>
        </Wrapper.Body>
      </Wrapper> */}
      <Wrapper className="w-1/2 ml-auto">
        <Wrapper.Body className="flex items-center">
          <span className="mr-auto">
            <Text>offerPaymentDetails.downPayment</Text>{" "}
            {isEnabled && (
              <button
                type="button"
                className="text-primary"
                onClick={toggleEditModal}
              >
                <Icon name="Edit2" size={20} />
              </button>
            )}
          </span>
          {!offerData.downPaymentAmount ? (
            <button
              type="button"
              disabled={!isEnabled}
              className="text-warning"
              onClick={toggleEditModal}
            >
              <Icon name="InfoCircle" size={18} /> <Text>button.notSet</Text>
            </button>
          ) : (
            <div>{convertAmount(offerData.downPaymentAmount)}</div>
          )}
        </Wrapper.Body>
      </Wrapper>
      {isEnabled && (
        <EditModal isOpen={showEditModal} toggle={toggleEditModal} />
      )}
    </Fragment>
  );
}
