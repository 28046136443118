import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { sizeType, togglePropsType } from "../types";
import Portal from "./_Portal";
type modalProps = togglePropsType & {
  children: ReactNode;
  size?: sizeType;
  className?: string;
  modalClassName?: string;
};
type modalElementProps = {
  className?: string;
  children: ReactNode;
};
function Modal({
  isOpen,
  toggle = () => {},
  size = "md",
  className = "",
  modalClassName = "",
  children,
}: modalProps) {
  return (
    <Portal>
      <div
        className={twMerge(
          "modal group fixed inset-0 flex flex-col z-30 bg-black/20 opacity-0 pointer-events-none transition-opacity [&.active]:opacity-100 [&.active]:pointer-events-auto",
          isOpen && "active",
          modalClassName
        )}
      >
        <button
          type="button"
          onClick={() => toggle()}
          className="absolute inset-0 cursor-default opacity-0"
        />
        <div
          className={`modal-dialog modal-${size} relative flex flex-col bg-white m-auto w-full rounded shadow px-6 transition-transform max-w-[97.5%] max-h-[97.5%] translate-y-[25vh] group-[.modal.active]:translate-y-0 min-[576px]:max-w-[500px] min-[576px]:[&.modal-sm]:max-w-[300px] min-[992px]:[&.modal-lg]:max-w-[800px] min-[992px]:[&.modal-xl]:max-w-[800px] min-[1200px]:[&.modal-xl]:max-w-[1140px] ${className}`}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
}
function ModalHeader({ className = "", children = null }: modalElementProps) {
  return (
    <div
      className={`w-full text-center py-4 border-b border-gray ${className}`}
    >
      {children}
    </div>
  );
}
function ModalBody({ className = "", children = null }: modalElementProps) {
  return (
    <div className={`flex-1 py-4 overflow-auto ${className}`}>{children}</div>
  );
}
function ModalFooter({ className = "", children = null }: modalElementProps) {
  return (
    <div className={`w-full py-4 border-t border-gray ${className}`}>
      {children}
    </div>
  );
}
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;
