import { useContext } from "react";
import { AddCustomerDataContext } from ".";
import { InputGroup, Text, Wrapper } from "../../../components";
import { rules } from "../../../constants";

export default function ContactInfo() {
  const { data, setData } = useContext(AddCustomerDataContext);
  const formControls = [
    {
      label: "formControls.email",
      items: [
        {
          state: "emailAddress",
          placeholder: "placeholders.email",
          type: "email",
          rules: rules.email,
        },
      ],
    },
    {
      label: "formControls.mobileNumber",
      items: [
        {
          state: "countryCode",
          placeholder: "+49",
          type: "tel",
          rules: rules.required,
        },
        {
          state: "mobileNumber",
          placeholder: "placeholders.mobileNumber",
          flex: 4,
          type: "tel",
          rules: rules.required,
        },
      ],
    },
    {
      label: "formControls.phoneNumber",
      items: [
        {
          state: "phoneNumber",
          placeholder: "placeholders.phoneNumber",
          flex: 4,
          type: "tel",
        },
      ],
    },
  ];
  const handleSetData = (key: string) => {
    return (val: any) =>
      setData((p: any) => ({
        ...p,
        personInfo: { ...p.personInfo, [key]: val },
      }));
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6">
        {/* <p className="text-secondary">
          <Text>If you need more info, please check out</Text>{" "}
          <Link to="/" className="text-primary">
            <Text>FAQ Page</Text>
          </Link>
        </p> */}
        {formControls.map((e) => (
          <div key={e.label} className="flex w-full gap-2">
            <span className="flex-[2] leading-11 truncate">
              <Text>{e.label}</Text>
            </span>
            <div className="flex-[5] flex gap-4">
              {e.items.map(
                ({ Element = InputGroup, state, flex, ...e }: any) => (
                  <div key={state} style={{ flex: flex || 1 }}>
                    <Element
                      value={data.personInfo[state]}
                      setValue={handleSetData(state)}
                      {...e}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
}
