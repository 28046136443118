import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
type wrapperProps = {
  className?: string;
  children: ReactNode;
};
function Wrapper({ className = "", children }: wrapperProps) {
  return (
    <div
      className={twMerge(`w-full bg-white rounded shadow px-4 ${className}`)}
    >
      {children}
    </div>
  );
}
function WrapperHeader({ className = "", children }: wrapperProps) {
  return (
    <div className={twMerge(`w-full border-b border-gray py-4 ${className}`)}>
      {children}
    </div>
  );
}
function WrapperBody({ className = "", children }: wrapperProps) {
  return <div className={twMerge(`w-full py-4 ${className}`)}>{children}</div>;
}
function WrapperFooter({ className = "", children }: wrapperProps) {
  return (
    <div className={twMerge(`w-full border-t border-gray py-4 ${className}`)}>
      {children}
    </div>
  );
}
// function WrapperSection({ className = "", children }: wrapperProps) {
//   return (
//     <div
//       className={`w-full border border-gray border-dashed rounded py-4 ${className}`}
//     >
//       {children}
//     </div>
//   );
// }
Wrapper.Header = WrapperHeader;
Wrapper.Body = WrapperBody;
Wrapper.Footer = WrapperFooter;
// Wrapper.Section = WrapperSection;
export default Wrapper;
