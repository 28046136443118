import Address from "./Address";

export default function Addresses() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <Address type="shipTo" />
      <Address type="billTo" />
    </div>
  );
}
