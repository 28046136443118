import { Fragment, useEffect, useState } from "react";
import noEventIcon from "../../assets/icons/no event.svg";
import {
  Badge,
  Button,
  Icon,
  Layout,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../components";
import { useToggleState } from "../../hooks";
import ActionModal from "./ActionModal";
import AddNewAppointmentMenu from "./AddNewAppointmentMenu";
import AddNewTask from "./AddNewTask";
import AddNewTodoMenu from "./AddNewTodoMenu";
import DatePicker from "./DatePicker";
import InfoModal from "./InfoModal";
export default function Calendar() {
  const ranges = [
    {
      name: "Today",
      id: "1",
    },
    {
      name: "Next 7 days EN",
      id: "2",
    },
    {
      name: "This month EN",
      id: "3",
    },
  ];
  const [activeInfoId, setActiveInfoId] = useState("");
  const [activeActionId, setActiveActionId] = useState("");
  const [showCalendar, toggleCalendar] = useToggleState(true);
  const [showAddNewTaskModal, toggleAddNewTaskModal] = useToggleState(false);
  const [showAddNewAppointmentMenu, toggleAddNewAppointmentMenu] =
    useToggleState(false);
  const [showAddNewTodoMenu, toggleAddNewTodoMenu] = useToggleState(false);
  const clearActiveActionId = () => {
    setActiveActionId("");
  };
  const clearActiveInfoId = () => {
    setActiveInfoId("");
  };
  const getColors = (i: number) => {
    const colors = ["#4159D3", "#40D246", "#FF6767", "#FFD130", "#47DDDD"];
    const length = colors.length;
    const index = i % length;
    return colors[index];
  };
  const getData = () => {};
  useEffect(getData, []);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <div className="flex-1">
            <SearchBox
              onSubmit={() => {}}
              placeholder="global.searchCalendarItem"
            />
          </div>
          <Button
            type="button"
            variant="primary"
            light
            onClick={toggleCalendar}
          >
            <Icon name="Calendar" variant="Bold" />{" "}
            <Text>
              {showCalendar ? "button.hideCalendar" : "button.showCalendar"}
            </Text>
          </Button>
        </Layout.Header>
        <Layout.Body className="flex flex-col lg:flex-row lg:items-start gap-5">
          <section className="space-y-5 lg:flex-1">
            <div className="flex items-center gap-1">
              {ranges.map((e) => (
                <Button
                  key={e.id}
                  type="button"
                  variant="white"
                  className="btn-sm"
                >
                  <Text>{e.name}</Text>
                </Button>
              ))}
            </div>
            <div className="flex items-center gap-2">
              <h6>Aug 13, 2023</h6>
              <Button
                type="button"
                variant="primary"
                className="py-1"
                onClick={toggleAddNewTaskModal}
              >
                <Icon name="Add" />
              </Button>
            </div>
            <Wrapper>
              <Wrapper.Body className="p-0">
                <Table.Wrapper>
                  <Table>
                    <Table.Body>
                      {[...Array(6).keys()].map(String).map((e) => (
                        <Fragment key={e}>
                          <tr>
                            <td>
                              <div className="flex items-stretch gap-3">
                                <span
                                  style={{
                                    backgroundColor: getColors(+e),
                                  }}
                                  className="w-1 rounded-full"
                                ></span>
                                <div className="flex-1 space-y-8">
                                  <p className="text-sm text-gray-900">
                                    15:00 - 15:30
                                  </p>
                                  <p className="w-fit text-sm rounded-md bg-[#F5F8FA] text-gray-900 p-1">
                                    30 min
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="space-y-3">
                              <Badge
                                variant="primary"
                                className="block w-fit text-sm p-1"
                              >
                                <Text>With customer EN</Text>
                              </Badge>
                              <button
                                type="button"
                                className="flex items-center gap-3 w-fit text-start cursor-pointer"
                                onClick={() => setActiveInfoId(e)}
                              >
                                <Icon.Wrapper
                                  className="bg-[#EEF6FF] text-[#C1CFD9] bg-opacity-100 p-1"
                                  circle
                                >
                                  <Icon name="User" variant="Bold" />
                                </Icon.Wrapper>
                                <div>
                                  <h6 className="block">
                                    Brad Dennis{" "}
                                    <Icon
                                      name="InfoCircle"
                                      className="text-primary"
                                      size="1.25em"
                                    />
                                  </h6>
                                  <span className="block text-secondary text-sm">
                                    #8473286089{" "}
                                  </span>
                                </div>
                              </button>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                light
                                className="border-0 bg-white btn-sm"
                                onClick={() => setActiveActionId(e)}
                              >
                                <Icon name="More" className="rotate-90" />
                              </Button>
                            </td>
                          </tr>
                          <InfoModal
                            isOpen={e === activeInfoId}
                            toggle={clearActiveInfoId}
                          />
                          <ActionModal
                            isOpen={e === activeActionId}
                            toggle={clearActiveActionId}
                          />
                        </Fragment>
                      ))}
                      <tr>
                        <td colSpan={3}>
                          <div className="flex-center text-center flex-col gap-3">
                            <img
                              className="w-40 max-w-full"
                              src={noEventIcon}
                              alt="no event"
                            />
                            <h4 className="text-placeholder">
                              <Text>page.calendar.noItemTitle</Text>
                            </h4>
                            <p className="text-placeholder">
                              <Text>page.calendar.noItemDescription</Text>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </Table.Body>
                  </Table>
                </Table.Wrapper>
              </Wrapper.Body>
            </Wrapper>
          </section>
          <section
            data-active={showCalendar}
            className="w-0 data-active:w-80 h-[calc(100vh-4rem-1.5rem)] sticky top-[4.75rem] overflow-hidden data-active:overflow-auto transition-[width]"
          >
            <DatePicker />
          </section>
        </Layout.Body>
      </Layout>
      <AddNewTask
        isOpen={showAddNewTaskModal}
        toggle={toggleAddNewTaskModal}
        onSelectAppointment={toggleAddNewAppointmentMenu}
        onSelectTodo={toggleAddNewTodoMenu}
      />
      <AddNewAppointmentMenu
        isOpen={showAddNewAppointmentMenu}
        toggle={toggleAddNewAppointmentMenu}
      />
      <AddNewTodoMenu
        isOpen={showAddNewTodoMenu}
        toggle={toggleAddNewTodoMenu}
      />
    </Fragment>
  );
}
