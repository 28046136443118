import { useContext } from "react";
import { OrderContext } from ".";
import { Button, Icon, Modal, Text } from "../../../components";
import { dateFormats } from "../../../constants";
import { togglePropsType } from "../../../types";
import { useConverters } from "../../../hooks";
export default function UpdateInfo({ isOpen, toggle }: togglePropsType) {
  const { orderData } = useContext(OrderContext);
  const { convertDate } = useConverters();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>modalTitles.updateInfo</Text>
      </Modal.Header>
      <Modal.Body className="space-y-6">
        <div className="flex-center flex-col space-y-2">
          <Icon name="User" size={30} className="text-primary" />
          <h6>
            {orderData?.customerPersonInfo?.firstName}{" "}
            {orderData?.customerPersonInfo?.lastName}
          </h6>
          <span>CIM: {orderData?.customerCIMNumber}</span>
          <span className="text-secondary">
            {orderData?.customerPersonInfo?.emailAddress} |{" "}
            {orderData?.customerPersonInfo?.mobileNumber}
          </span>
        </div>
        <div className="flex-center flex-col space-y-2">
          <Icon name="InfoCircle" size={30} className="text-primary" />
          <span>
            <Text>global.updatedAt</Text>:
          </span>
          <span className="text-secondary">
            {convertDate(
              orderData.updatedAt || orderData.creationAt,
              dateFormats.fullDate
            )}
          </span>
          <span className="text-secondary">
            <Text>global.by</Text>:{" "}
            {orderData.editorName || orderData.creatorName}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button light className="block mx-auto" onClick={toggle}>
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
