import { enums, status } from "../constants";
import Badge from "./_Badge";
import Icon from "./_Icon";
import Text from "./_Text";
type statusProps = {
  id: number;
};
function Customer({ id }: statusProps) {
  const activeStatus = status.customer.find((e) => e.id === id);
  return (
    <Badge variant={activeStatus?.variant || "warning"}>
      <Text>{activeStatus?.name || "status.customer.notRegistered"}</Text>
    </Badge>
  );
}
function Offer({ id }: statusProps) {
  const activeStatus = status.offer.find((e) => e.id === id);
  const isAccepted = id === enums.offerStatus.accepted;
  const isRejected = id === enums.offerStatus.rejected;
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      outline={isAccepted || isRejected}
    >
      {isAccepted && (
        <Icon name="TickCircle" variant="Bold" size={18} className="mr-1" />
      )}
      {isRejected && (
        <Icon name="CloseCircle" variant="Bold" size={18} className="mr-1" />
      )}
      <Text>{activeStatus?.name || ""}</Text>
    </Badge>
  );
}
function Order({ id }: statusProps) {
  const activeStatus = status.order.find((e) => e.id === id);
  const isSubmitted = id === enums.orderStatus.submitted;
  const isPaid = id === enums.orderStatus.paid;
  return (
    <Badge variant={activeStatus?.variant || "dark"} outline={isSubmitted}>
      {isPaid && (
        <Icon name="TickCircle" variant="Bold" size={18} className="mr-1" />
      )}
      <Text>{activeStatus?.name || ""}</Text>
    </Badge>
  );
}
const Status = { Customer, Offer, Order };
export default Status;
