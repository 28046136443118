import { useContext } from "react";
import { AddCustomerDataContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { rules, types } from "../../../constants";

export default function BasicInfo() {
  const { data, setData } = useContext(AddCustomerDataContext);
  const formControls = [
    {
      label: "formControls.firstName",
      items: [
        {
          state: "firstName",
          placeholder: "placeholders.firstName",
          rules: rules.required,
        },
      ],
    },
    {
      label: "formControls.lastName",
      items: [
        {
          state: "lastName",
          placeholder: "placeholders.lastName",
          rules: rules.required,
        },
      ],
    },
    {
      label: "formControls.gender",
      items: [
        {
          Element: Select,
          state: "gender",
          placeholder: "placeholders.gender",
          items: types.gender,
        },
      ],
    },
    {
      label: "formControls.title",
      items: [
        {
          Element: Select,
          state: "title",
          placeholder: "placeholders.title",
          items: types.title,
        },
      ],
    },
    {
      label: "formControls.birthDate",
      items: [
        {
          state: "birthdate",
          placeholder: "placeholders.birthDate",
          type: "date",
        },
      ],
    },
  ];
  const handleSetData = (key: string) => {
    return (val: any) =>
      setData((p: any) => ({
        ...p,
        personInfo: { ...p.personInfo, [key]: val },
      }));
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6">
        {/* <p className="text-secondary">
          <Text>If you need more info, please check out</Text>{" "}
          <Link to="/" className="text-primary">
            <Text>FAQ Page</Text>
          </Link>
        </p> */}
        {formControls.map((e) => (
          <div key={e.label} className="flex w-full gap-2">
            <span className="flex-[2] leading-11 truncate">
              <Text>{e.label}</Text>
            </span>
            <div className="flex-[5] flex">
              {e.items.map(
                ({ Element = InputGroup, state, flex, ...e }: any) => (
                  <div key={state} style={{ flex: flex || 1 }}>
                    <Element
                      value={data.personInfo[state]}
                      setValue={handleSetData(state)}
                      {...e}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
}
