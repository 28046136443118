import { useState } from "react";
import { Badge, Button, Image, Modal, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
import { favoriteProductDetails } from "../../../../types/favoriteProduct";
type productDetailsProps = {
  details: favoriteProductDetails;
} & togglePropsType;
export default function ProductDetails({
  isOpen,
  toggle,
  details,
}: productDetailsProps) {
  const { convertAmount } = useConverters();
  const [activeIndex, setActiveIndex] = useState(0);
  const activeImage = details.mediaList?.at(activeIndex);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <Modal.Header>
        <h6>
          <Text>modalTitles.favoriteProductDetails</Text>
        </h6>
        <p className="text-sm text-secondary">#{details.articleNumber}</p>
      </Modal.Header>
      <Modal.Body className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        <section className="lg:col-span-2">
          <Image
            src={activeImage?.url}
            alt={activeImage?.type}
            className="block w-full aspect-image rounded object-cover shadow"
          />
          <div className="w-full overflow-auto">
            <div className="w-fit flex gap-3 items-center py-3">
              {details.mediaList?.map((e, i) => (
                <button
                  key={e.id}
                  type="button"
                  className="relative w-40 aspect-image rounded shadow overflow-hidden"
                  onClick={() => setActiveIndex(i)}
                >
                  <Image
                    src={e.thumbnailUrl}
                    alt={e.type}
                    className="absolute w-full h-full inset-0 object-cover"
                  />
                </button>
              ))}
            </div>
          </div>
        </section>
        <section className="lg:col-span-1 space-y-4">
          <div className="flex items-center justify-between">
            <Badge>{convertAmount(details.price?.amount)}</Badge>
          </div>
          <div className="bg-gray-50 rounded py-5 px-4 space-y-5">
            <div className="space-y-2">
              <h6>{details.title}</h6>
              <p className="text-sm text-gray-900">{details.description}</p>
            </div>
          </div>
          <div className="flex flex-wrap gap-1">
            {details.productCategories?.map((e, i) => (
              <Badge
                key={`${e.code}-${i}`}
                variant="secondary"
                className="text-xs"
              >
                {e.translates?.at(0)?.name || e.code}
              </Badge>
            ))}
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button
          light
          type="button"
          variant="primary"
          className="w-fit block mx-auto"
          onClick={toggle}
        >
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
