import { ChangeEvent, useMemo } from "react";
import { useTranslate } from "../hooks";
import { debounce } from "../methods";
import Icon from "./_Icon";
type searchBoxProps = {
  placeholder?: string;
  variant?: "white" | "gray";
  onSubmit: (val: string) => void;
};
export default function SearchBox({
  placeholder = "global.searchBox",
  onSubmit,
  variant = "white",
}: searchBoxProps) {
  const translate = useTranslate();
  const activeVariant = useMemo(() => {
    const variants = {
      white: "bg-white",
      gray: "bg-gray-50",
    };
    return variants[variant];
  }, [variant]);
  // const [submitted, setSubmitted] = useState<boolean>(false);
  // const [value, setValue] = useState<string>("");
  // const clearValue = () => {
  //   setValue("");
  //   onSubmit(null);
  //   setSubmitted(false);
  // };
  // const submit = (e: FormEvent) => {
  //   e.preventDefault();
  //   onSubmit(value);
  //   setSubmitted(true);
  // };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || "";
    debounce(() => onSubmit(value));
  };
  return (
    <div
      // onSubmit={submit}
      className={`w-full flex-center gap-2 border border-transparent rounded px-2 py-1 focus-within:border-primary transition-colors ${activeVariant}`}
    >
      <button type="button">
        <Icon
          name="SearchNormal1"
          variant="Outline"
          size={24}
          className="text-[#A1A4AC]"
        />
      </button>
      <input
        // value={value}
        onChange={handleChange}
        placeholder={translate(placeholder) ?? ""}
        className="flex-1 h-8 px-2"
      />
    </div>
  );
}
