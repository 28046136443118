import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomerContext } from "..";
import {
  Button,
  Icon,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import { OfferFilter, RejectOfferMenu } from "../../../../containers";
import { useAxios, useConverters, useToggleState } from "../../../../hooks";
import { pagingResponseType } from "../../../../types";
import OfferActions from "./OfferActions";
import OfferForm from "./OfferForm";
type paramsType = {
  keyword?: string;
  pageNumber: number;
  pageSize?: number;
  offerStatuses?: number | null;
  cimNumber?: string;
  sortBy?: number | null;
};
export default function Offers() {
  const { customerData } = useContext(CustomerContext);
  const { convertAmount, convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as pagingResponseType);
  const [showFilterMenu, toggleFilterMenu] = useToggleState(false);
  const [activeActionId, setActiveActionId] = useState<string | null>(null);
  const [activeRejectId, setActiveRejectId] = useState<string | null>(null);
  const [activeEditId, setActiveEditId] = useState<string | null>(null);
  const [showAddOfferMenu, toggleAddOfferMenu] = useToggleState(false);
  const [params, setParams] = useState<paramsType>({
    pageNumber: 1,
    pageSize: config.pageSize,
    cimNumber: customerData.cimNumber,
  } as paramsType);
  const clearActiveActionId = () => {
    setActiveActionId(null);
  };
  const clearActiveRejectId = () => {
    setActiveRejectId(null);
  };
  const clearActiveEditId = () => {
    setActiveEditId(null);
  };
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "/productservice/api/offers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3">
          <SearchBox onSubmit={handleSetParams("keyword")} />
        </div>
        <Button light onClick={toggleFilterMenu}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button>
        <Button className="ml-auto" onClick={toggleAddOfferMenu}>
          <Icon name="Add" size={20} className="mr-1" />
          <Text>button.addOffer</Text>
        </Button>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => (
                  <tr key={e.offerId}>
                    <td>
                      <Link
                        to={`/offers/${e.offerId}`}
                        className="flex-center w-fit gap-4"
                      >
                        <Icon.Wrapper>
                          <Icon name="DiscountShape" variant="Bold" />
                        </Icon.Wrapper>
                        <div className="space-y-1">
                          <span>{e.title}</span>
                          <span className="block text-secondary">
                            #{e.offerNumber}
                          </span>
                          <span className="block">
                            <Icon
                              name="LocationTick"
                              className="text-danger"
                              size={18}
                            />{" "}
                            {e.storeInfo?.title}
                          </span>
                        </div>
                      </Link>
                    </td>
                    <td>{convertAmount(e.totalAmount)}</td>
                    <td className="space-y-1">
                      <span className="block">Vr {e.version}</span>
                      <span className="block text-secondary">
                        <Text>global.createdAt</Text> (
                        {convertDate(e.creationAt)})
                      </span>
                      {!!e.updatedAt && (
                        <span className="block text-secondary">
                          <Text>global.updatedAt</Text> (
                          {convertDate(e.updatedAt)})
                        </span>
                      )}
                    </td>
                    <td>
                      <Status.Offer id={e.statusInfo?.type} />
                    </td>
                    <td>
                      <Button
                        light
                        onClick={() => setActiveActionId(e.offerId)}
                      >
                        <Text>button.action</Text>
                        <Icon name="ArrowDown2" size={18} className="ml-1" />
                      </Button>
                      <OfferActions
                        data={e}
                        isOpen={activeActionId === e.offerId}
                        toggle={clearActiveActionId}
                        onEdit={setActiveEditId}
                        onReject={setActiveRejectId}
                      />
                      <OfferForm
                        data={e}
                        isOpen={activeEditId === e.offerId}
                        toggle={clearActiveEditId}
                        getOffers={getData}
                      />
                      <RejectOfferMenu
                        isOpen={activeRejectId === e.offerId}
                        toggle={clearActiveRejectId}
                        offerData={e}
                        onSubmitted={getData}
                      />
                    </td>
                  </tr>
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <Pagination
        pageNumber={params.pageNumber}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
      <OfferFilter
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
      <OfferForm
        data={null}
        isOpen={showAddOfferMenu}
        toggle={toggleAddOfferMenu}
        getOffers={getData}
      />
    </Fragment>
  );
}
