import { Fragment } from "react";
import { Icon, Types } from "../../../../components";
import { useConverters, useToggleState } from "../../../../hooks";
import { lineItemDetailsType } from "../../../../types";
import InfoMenu from "./InfoMenu";
type productRowProps = {
  data: lineItemDetailsType;
  showSubLineItems?: boolean;
  onToggleSubLineItems?: (id: string) => void;
  isSubLineItem?: boolean;
  lineItemId?: string;
};
export default function ProductRow({
  data,
  isSubLineItem = false,
  onToggleSubLineItems = () => {},
  showSubLineItems = false,
}: productRowProps) {
  const { convertAmount } = useConverters();
  const [showInfo, toggleInfo] = useToggleState(false);
  return (
    <Fragment>
      <tr>
        <td>
          <div className="flex items-center text-start gap-4">
            <button
              type="button"
              data-hidden={
                isSubLineItem || !data?.subLineItems?.length || undefined
              }
              data-active={showSubLineItems}
              className="text-primary data-[hidden]:opacity-0 data-[hidden]:pointer-events-none data-active:rotate-90 transition-transform"
              onClick={() => onToggleSubLineItems(data.id)}
            >
              <Icon name="ArrowRight2" variant="Bold" size={18} />
            </button>
            <button
              type="button"
              className="flex-center text-start gap-4"
              onClick={toggleInfo}
            >
              {isSubLineItem ? (
                <div className="flex-center w-12 h-12 rounded-full text-primary bg-white border border-primary">
                  <Icon name="Box1" variant="Linear" size={28} />
                </div>
              ) : (
                <div className="flex-center w-12 h-12 rounded-full text-primary bg-primary/10">
                  <Icon name="Box" variant="Bold" size={28} />
                </div>
              )}
              <div>
                <span className="block mb-1">
                  {data.translates?.at(0)?.displayName}{" "}
                  <Icon name="InfoCircle" size={18} className="text-primary" />
                </span>
                <span className="block mb-1 text-secondary">
                  #{data.articleNumber}
                </span>
                <Types.Product id={data.productType} />
              </div>
            </button>
          </div>
        </td>
        <td className="space-y-1">
          <span className="block">{convertAmount(data.unitPrice?.amount)}</span>
          {!!data.totalDiscountAmount && !!data.discount && (
            <span className="block line-through text-sm text-secondary">
              {convertAmount(data.discount?.productPrice)}
            </span>
          )}
        </td>
        <td>x{data.quantity}</td>
        <td>
          <span className="block">{convertAmount(data.totalSalePrice)}</span>
        </td>
      </tr>
      <InfoMenu isOpen={showInfo} toggle={toggleInfo} data={data} />
    </Fragment>
  );
}
