import { Fragment, useEffect, useRef } from "react";
import { Calendar } from "react-multi-date-picker";

export default function DatePicker() {
  const calendarRef = useRef<any>(null);
  const scrollToToday = () => {
    setTimeout(() => {
      const element = calendarRef.current;
      if (!element) return;
      const todayElement = document.body.querySelector(".rmdp-day.rmdp-today");
      todayElement?.scrollIntoView({
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
    }, 1000);
  };
  useEffect(scrollToToday, []);
  return (
    <Calendar
      ref={calendarRef}
      fullYear
      buttons={false}
      value={new Date()}
      shadow={false}
      // @ts-ignore: Unreachable code error
      mapDays={({ date, selectedDate, isSameDate, today }) => {
        const isLastDays = date.toDays() < today.toDays();
        // @ts-ignore: Unreachable code error
        const isSelectedDay = isSameDate(date, selectedDate);
        const hasTasks = [...Array(10).keys()]
          .map((e) => e - 5)
          .map(
            (index) =>
              index % 2 === 0 && date.toDays() === today.toDays() + index
          )
          .some(Boolean);
        const className = ["relative", isLastDays && "text-[#B3B3B3]"]
          .filter(Boolean)
          .join(" ");
        return {
          className,
          children: (
            <Fragment>
              {date.day}
              <i
                hidden={!hasTasks || isSelectedDay}
                className="absolute w-1 h-1 rounded-full bg-primary left-0 right-0 bottom-0 mx-auto"
              />
            </Fragment>
          ),
        };
      }}
      // minDate={new Date()}
      className="date-picker min-w-max !w-full [&_.rmdp-header]:!hidden [&_.rmdp-full-year]:!grid-cols-1 [&_.rmdp-full-year]:gap-5"
    />
  );
}
