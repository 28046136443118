import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CustomerContext } from "..";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios, useSelector } from "../../../../hooks";
import { toggleSelectStoreMenu } from "../../../../redux/selectStoreActions";
import { togglePropsType } from "../../../../types";
type dataType = {
  offerId?: string | null;
  title: string;
  customer: {
    personInfo: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      mobileNumber: string;
      phoneNumber: string | null;
    };
  };
};
type offerDataType = {
  offerId?: string | null;
  title: string;
  customerCIMNumber: string;
  customerId: string;
  companyPrefixOrderNumber: number;
  storeCode: null | string;
  customerPersonInfo: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber: string;
    phoneNumber: string | null;
  };
};
type createOfferProps = togglePropsType & {
  data: dataType | null;
  getOffers: () => void;
};
export default function OfferForm({
  isOpen,
  toggle,
  data: offerData,
  getOffers,
}: createOfferProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { axios, loading } = useAxios();
  const canGoToDetails = useRef<boolean>(false);
  const defaultStore = useSelector((s) => s.profile.storeDefaultFilter);
  const { customerData } = useContext(CustomerContext);
  const isNew = !offerData?.offerId;
  const { current: initData } = useRef<offerDataType>(
    !!offerData
      ? {
          offerId: offerData?.offerId ?? null,
          title: offerData?.title ?? "",
          customerCIMNumber: "",
          customerId: "",
          companyPrefixOrderNumber: 1000,
          storeCode: null,
          customerPersonInfo: {
            firstName: offerData?.customer?.personInfo?.firstName ?? "",
            lastName: offerData?.customer?.personInfo?.lastName ?? "",
            emailAddress: offerData?.customer?.personInfo?.emailAddress ?? "",
            mobileNumber: offerData?.customer?.personInfo?.mobileNumber ?? "",
            phoneNumber: offerData?.customer?.personInfo?.phoneNumber ?? "",
          },
        }
      : {
          offerId: null,
          title: "",
          customerCIMNumber: "",
          customerId: "",
          companyPrefixOrderNumber: 1000,
          storeCode: null,
          customerPersonInfo: {
            firstName: customerData.personInfo.firstName,
            lastName: customerData.personInfo.lastName,
            emailAddress: customerData.personInfo.emailAddress,
            mobileNumber: [
              customerData.personInfo.countryCode,
              customerData.personInfo.mobileNumber,
            ]
              .map((e) => e ?? "")
              .join(""),
            phoneNumber: customerData.personInfo?.phoneNumber,
          },
        }
  );
  const [data, setData] = useState<offerDataType>(initData);
  const [confirm, setConfirm] = useState<boolean>(false);
  const handleInitData = () => {
    if (!isOpen) return;
    setConfirm(false);
    setData(initData);
  };
  const handleSetCustomerValue = (
    key: keyof offerDataType["customerPersonInfo"]
  ) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data.customerPersonInfo[key] = value;
        return data;
      });
  };
  const toggleSelectStore = () => {
    dispatch(toggleSelectStoreMenu());
  };
  const createOffer = () => {
    const url = "/productservice/api/offers";
    const body = { ...data };
    body.customerCIMNumber = customerData.cimNumber;
    body.customerId = customerData.id;
    body.storeCode = defaultStore?.code ?? null;
    axios.post(url, body).then(({ data: offerNumber }) => {
      if (canGoToDetails.current) {
        navigate(`/offers/${offerNumber}/1`);
      } else {
        toast.success("toast.success.createOffer");
        getOffers();
        toggle();
      }
    });
  };
  const editOffer = () => {
    const url = `/productservice/api/offers/${data.offerId}`;
    const body = { ...data };
    body.customerCIMNumber = customerData.cimNumber;
    body.customerId = customerData.id;
    body.storeCode = defaultStore?.code ?? null;
    axios.put(url, body).then(() => {
      toast.success("toast.success.editOffer");
      getOffers();
      toggle();
    });
  };
  const setGoToDetails = (val: boolean) => {
    canGoToDetails.current = val;
  };
  useEffect(handleInitData, [isOpen]);
  return (
    <Drawer
      as={Form}
      onSubmit={isNew ? createOffer : editOffer}
      isOpen={isOpen}
      toggle={toggle}
    >
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            {isNew ? (
              <Text>drawerTitles.createOffer</Text>
            ) : (
              <Text>drawerTitles.editOffer</Text>
            )}
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Icon.Wrapper className="mx-auto">
            <Icon name="DiscountShape" variant="Bold" />
          </Icon.Wrapper>
          <InputGroup
            label="formControls.offerTitle"
            value={data.title}
            setValue={(title) => setData((p) => ({ ...p, title }))}
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerFirstName"
            value={data.customerPersonInfo?.firstName}
            setValue={handleSetCustomerValue("firstName")}
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerLastName"
            value={data.customerPersonInfo?.lastName}
            setValue={handleSetCustomerValue("lastName")}
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerEmailAddress"
            value={data.customerPersonInfo?.emailAddress}
            setValue={handleSetCustomerValue("emailAddress")}
            rules={rules.email}
          />
          <InputGroup
            label="formControls.mobileNumber"
            value={data.customerPersonInfo?.mobileNumber}
            setValue={handleSetCustomerValue("mobileNumber")}
            rules={rules.required}
          />
          <InputGroup
            label="formControls.phoneNumber"
            value={data.customerPersonInfo?.phoneNumber}
            setValue={handleSetCustomerValue("phoneNumber")}
            // rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="space-y-2">
          <div hidden={!defaultStore} className="flex items-center gap-1">
            <Icon
              name="LocationTick"
              variant="Bold"
              size={24}
              className="text-danger"
            />
            <span className="flex-1 truncate">{defaultStore?.title}</span>
            <button
              type="button"
              className="text-warning text-sm"
              hidden={!isNew}
              onClick={toggleSelectStore}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                size={25}
                className="text-warning"
              />
              <Text>button.change</Text>
            </button>
          </div>
          <div hidden={!isNew}>
            <CheckBox
              value={confirm}
              setValue={setConfirm}
              label="confirms.addOffer"
            />
          </div>
          <div className="flex items-center justify-end gap-4">
            {isNew ? (
              <Fragment>
                <Button
                  light
                  type="submit"
                  disabled={!confirm}
                  onClick={() => setGoToDetails(false)}
                  loading={loading.post}
                >
                  <Text>button.saveAndCloseOffer</Text>
                </Button>
                <Button
                  type="submit"
                  disabled={!confirm}
                  onClick={() => setGoToDetails(true)}
                  loading={loading.post}
                >
                  <Text>button.nextAndCompleteOffer</Text>
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button variant="danger" onClick={toggle}>
                  <Text>button.cancel</Text>
                </Button>
                <Button type="submit" loading={loading.update}>
                  <Text>button.submitChanges</Text>
                </Button>
              </Fragment>
            )}
          </div>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
