import { useContext } from "react";
import { OfferContext } from ".";
import { Button, Icon, Modal, Text } from "../../../components";
import { dateFormats } from "../../../constants";
import { togglePropsType } from "../../../types";
import { useConverters } from "../../../hooks";
export default function AcceptInfo({ isOpen, toggle }: togglePropsType) {
  const { offerData, orderData } = useContext(OfferContext);
  const { convertDate } = useConverters();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.acceptOfferInfo</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-5">
        <div className="flex flex-col flex-center space-y-2">
          <Icon name="TickCircle" className="text-success" size={25} />
          <h6 className="text-success">
            <Text>status.offer.accepted</Text>
          </h6>
          <span>
            {convertDate(offerData.statusInfo.updatedAt, dateFormats.fullDate)}
          </span>
          <span className="text-secondary">
            <Text>global.by</Text>: {offerData.statusInfo.editorName}
          </span>
        </div>
        <p className="bg-gray-50 text-secondary py-2 px-4 min-h-[10rem] rounded leading-6">
          {orderData.orderNote}
        </p>
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
