import { rulesType } from "../types";
const required: rulesType = [
  (val) => !!String(val ?? "").length || "rules.required",
];
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const email: rulesType = [
  ...required,
  (val) => emailRegex.test(val) || "rules.email",
];
const postalCode: rulesType = [
  ...required,
  (val) => String(val ?? "").length === 5 || "rules.postalCode",
];
const integer: rulesType = [
  ...required,
  (val: string) => +val === Math.floor(+val) || "rules.integer",
];
const rules = {
  required,
  email,
  postalCode,
  integer,
};
export default rules;
