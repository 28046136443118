import { Link } from "react-router-dom";
import { Button, Icon, Modal, Text } from "../../../components";
import { togglePropsType } from "../../../types";
import { useContext } from "react";
import { OfferContext } from ".";

export default function OrderInfo({ isOpen, toggle }: togglePropsType) {
  const { orderData } = useContext(OfferContext);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.assignedOrder</Text>
        </h6>
      </Modal.Header>
      <Modal.Body>
        <Link
          to={`/orders/${orderData.orderId}`}
          className="flex items-center gap-4 py-8"
        >
          <section className="flex-1 flex items-center gap-4">
            <Icon.Wrapper>
              <Icon name="ReceiptText" variant="Bold" />
            </Icon.Wrapper>
            <div className="space-y-1">
              <h6>{orderData.title}</h6>
              <span className="block text-secondary">
                #{orderData.orderNumber}
              </span>
              <span className="block">
                <Icon name="LocationTick" size={18} className="text-danger" />{" "}
                {orderData.storeInfo?.title}
              </span>
            </div>
          </section>
          <Button as="span" light>
            <Text>button.viewOrderDetails</Text>
          </Button>
        </Link>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="block w-fit mx-auto"
          light
          onClick={toggle}
        >
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
