import { Navigate } from "react-router";
import AddNewCustomer from "../pages/Customers/AddNew";
import CustomerDetails from "../pages/Customers/Details";
import CustomersList from "../pages/Customers/List";

const customer = [
  {
    path: "",
    element: <Navigate to="/customers" replace />,
    // element: <Dashboard/>,
  },
  {
    path: "/customers",
    element: <CustomersList />,
  },
  {
    path: "/customers/new",
    element: <AddNewCustomer />,
  },
  {
    path: "/customers/:customerId",
    element: <CustomerDetails />,
  },
];
export default customer;
