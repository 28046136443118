import { FormEvent, ForwardedRef, ReactNode, forwardRef } from "react";
import { toast } from "react-hot-toast";

type formProps = {
  children: ReactNode;
  onSubmit: () => void;
  className?: string;
};
function Form(
  { onSubmit, className, children }: formProps,
  ref: ForwardedRef<HTMLFormElement>
) {
  const submit = (e: FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget;
    const inputGroups = form.querySelectorAll(".input-group");
    const formControls = [...inputGroups].map((e) => ({
      inputGroup: e,
      // @ts-ignore: Unreachable code error
      valid: !!e.querySelector(".form-control")?.onValid?.(),
    }));
    const everyValid = formControls.every((e) => !!e.valid);
    if (everyValid) return onSubmit();
    const inputGroup = formControls.find((e) => !e.valid)?.inputGroup;
    inputGroup?.scrollIntoView({ block: "center", behavior: "smooth" });
    toast.error(`toast.error.form`);
  };
  return (
    <form ref={ref} className={className} onSubmit={submit}>
      {children}
    </form>
  );
}
export default forwardRef<HTMLFormElement, formProps>(Form);
