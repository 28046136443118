import { Fragment, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

type settingsProviderProps = { children: ReactNode };
export default function SettingsProvider({ children }: settingsProviderProps) {
  const { i18n } = useTranslation();
  const setSettings = () => {
    const settings = localStorage.getItem("settings");
    if (!settings) return;
    const { language } = JSON.parse(settings);
    i18n.changeLanguage(language);
  };
  useEffect(setSettings, []);
  return <Fragment>{children}</Fragment>;
}
