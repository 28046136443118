import { Fragment, useContext, useMemo, useState } from "react";
import { OfferContext } from "../";
import {
  Button,
  Icon,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { useConverters, useToggleState } from "../../../../hooks";
import SelectProductModal from "./SelectProductModal";
import SearchProductMenu from "./SearchProductMenu";
import noProductIcon from "../../../../assets/icons/no product.svg";
import ProductRow from "./ProductRow";
import ImportBasketMenu from "./ImportBasketMenu";
export default function LineItems() {
  const { offerData, isEnabled } = useContext(OfferContext);
  const { convertAmount } = useConverters();
  const [keyword, setKeyword] = useState<null | string>("");
  const [showSelectProduct, toggleSelectProduct] = useToggleState(false);
  const [showProductMenu, toggleProductMenu] = useToggleState(false);
  const [showImportBasketMenu, toggleImportBasketMenu] = useToggleState(false);
  const lineItems = useMemo(() => {
    if (!keyword) return offerData.lineItems;
    return offerData.lineItems.filter((e) =>
      [e.translates[0].displayName, e.articleNumber].some((str) =>
        str.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  }, [keyword, offerData.lineItems]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3 mr-auto">
          <SearchBox onSubmit={setKeyword} />
        </div>
        {isEnabled && (
          <Button onClick={toggleSelectProduct}>
            <Icon name="Add" size={20} className="mr-1" />
            <Text>button.addProduct</Text>
          </Button>
        )}
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <thead>
                <tr>
                  <td className="!pl-[3.5rem]">
                    <Text>tableTitles.lineItemName</Text>
                  </td>
                  {isEnabled && (
                    <td>
                      <Text>tableTitles.lineItemAction</Text>
                    </td>
                  )}
                  <td>
                    <Text>tableTitles.lineItemUnitPrice</Text>
                  </td>
                  <td>
                    <Text>tableTitles.lineItemQuantity</Text>
                  </td>
                  <td>
                    <Text>tableTitles.lineItemPrice</Text>
                  </td>
                </tr>
              </thead>
              <tbody>
                {!lineItems.length ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="w-2/5 mx-auto">
                        <img
                          className="block w-full mb-8"
                          src={noProductIcon}
                          alt="no product"
                        />
                        {isEnabled && (
                          <Button
                            className="block mx-auto"
                            onClick={toggleSelectProduct}
                          >
                            <Icon name="Add" size={20} className="mr-1" />
                            <Text>button.addProduct</Text>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    {lineItems.map((lineItem) => (
                      <Fragment key={lineItem.id}>
                        <ProductRow data={lineItem} />
                      </Fragment>
                    ))}
                  </Fragment>
                )}
              </tbody>
            </Table>
          </Table.Wrapper>
          {!!lineItems.length && (
            <div className="flex items-center bg-gray-50 px-4 py-2 gap-4 rounded-md">
              <Icon
                name="DollarSquare"
                variant="Bold"
                size={24}
                className="text-dark"
              />
              <span className="mr-auto">
                <Text>global.totalPrice</Text>
              </span>
              <span>{convertAmount(offerData.totalAmount)}</span>
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
      <SelectProductModal
        isOpen={showSelectProduct}
        toggle={toggleSelectProduct}
        onSelectProducts={toggleProductMenu}
        onSelectImportBasket={toggleImportBasketMenu}
      />
      <SearchProductMenu isOpen={showProductMenu} toggle={toggleProductMenu} />
      <ImportBasketMenu
        isOpen={showImportBasketMenu}
        toggle={toggleImportBasketMenu}
      />
    </Fragment>
  );
}
