import { useContext } from "react";
import { OrderContext } from "..";
import { Button, Modal, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
type qrCodeModalProps = togglePropsType & { qrCode: string };
export default function QrCodeModal({
  isOpen,
  toggle,
  qrCode,
}: qrCodeModalProps) {
  const { orderData } = useContext(OrderContext);
  const { convertAmount } = useConverters();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>modalTitles.shareQrCodeWithCustomer</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <div className="flex items-center justify-between">
          <span>
            <Text>modalTitles.downPayment</Text>
          </span>
          <span>{convertAmount(orderData.downPaymentAmount)}</span>
        </div>
        <div
          className="flex-center bg-gray-50 rounded mx-auto w-full py-4 [&>svg]:w-48"
          dangerouslySetInnerHTML={{ __html: qrCode }}
        ></div>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle}>
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
