type indexType = number | null;
export default function getRandomColor(index: indexType = null): string {
  const colors = [
    "#C6B08C",
    "#8DC68C",
    "#8CB1C6",
    "#43BBD6",
    "#A68CC6",
    "#BEC68C",
    "#C68C8C",
    "#D8B743",
    "#8C5858",
    "#598C58",
    "#58798C",
  ];
  const hasIndex = index !== null;
  const colorsLength = colors.length;
  const colorIndex = hasIndex
    ? index % colorsLength
    : Math.floor(Math.random() * colors.length);
  const color = colors[colorIndex];
  // var letters = "0123456789ABCDEF";
  // var color = "#";
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  return color;
}
