import { Fragment, useContext, useEffect, useState } from "react";
import { ProductContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  Image,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { useAxios, useConverters } from "../../../../../hooks";
import {
  languageType,
  lineItemType,
  pagingDataType,
  priceType,
} from "../../../../../types";
type pimProductItemType = {
  id: string;
  pimProductId: string;
  articleNumber: string;
  headers: {
    description: string;
    shortDescription: string;
    title: string;
    language: languageType;
  }[];
  price: priceType;
  supplierInfo: {
    address: {
      city: string;
      country: string;
      name: string;
      number: string;
      postalCode: string;
      state: string;
      street: string;
    };
    articleNumber: string;
    code: string;
    description: string;
    email: string;
    logoUrl: string;
    name: string;
    phoneNumber: string;
  };
  mediaList: {
    url: string;
    type: string;
    mediaType: number;
    thumbnailUrl: string;
    createdAt: Date;
    mediaFields: {
      placeHolder: string;
      description: string;
      language: string;
      channelCode: string;
    }[];
    tags: string[];
    mediaMetadata: string;
  }[];
  productKey: string | null;
};
type pimProductsType = pagingDataType<pimProductItemType>;
type paramsType = {
  keyword: string | null;
  channelCode: "salestoolschannel";
  supplierCode: string | null;
  programCode: string | null;
  pageNumber: number;
  pageSize: number;
};
export default function PimProductsList() {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const { supplier, program, setProduct } = useContext(ProductContext);
  const [params, setParams] = useState<paramsType>({
    keyword: "",
    channelCode: "salestoolschannel",
    supplierCode: supplier?.code ?? null,
    programCode: program?.code ?? null,
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const [pimProducts, setPimProducts] = useState({} as pimProductsType);
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getPimProducts = () => {
    const url =
      "/productservice/api/channelproducts/get-channelproduct-by-channelcode";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setPimProducts(data);
    });
  };
  const handleToggleManualForm = () => {
    setProduct({ productType: 2, quantity: 1 } as lineItemType);
  };
  const handleChoose = (e: pimProductItemType) => {
    return () => {
      setProduct(() => {
        const data = {} as lineItemType;
        data.supplierArticleNumber = e.productKey ?? "";
        data.articleNumber = e.articleNumber;
        data.translates = e.headers.map((e) => ({
          displayName: e.title,
          language: e.language,
        }));
        data.unitPrice = {
          amount: e.price?.amount,
          currency: "EUR",
          currencySymbol: "€",
        };
        data.quantity = 1;
        data.productType = 1;
        return data;
      });
    };
  };
  useEffect(getPimProducts, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0 space-y-6">
        <SearchBox
          onSubmit={handleSetParams("keyword")}
          placeholder="placeholders.searchPimProduct"
          variant="gray"
        />
        <Button
          type="button"
          light
          className="block w-fit btn-sm"
          onClick={handleToggleManualForm}
        >
          <Icon name="Add" size={20} /> <Text>button.addProduct</Text>
        </Button>
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {pimProducts.items?.map((e) => {
            const thumbnail = e.mediaList.at(0)?.thumbnailUrl;
            const hasThumbnail = !!thumbnail;
            const title = e.headers[0]?.title;
            return (
              <List.Item
                key={e.id}
                onClick={handleChoose(e)}
                className="cursor-pointer"
              >
                {hasThumbnail ? (
                  <Image
                    src={thumbnail}
                    alt={title}
                    className="w-10 h-10 object-cover rounded-full"
                  />
                ) : (
                  <Icon.Wrapper>
                    <Icon name="Box" variant="Bold" />
                  </Icon.Wrapper>
                )}
                <div className="flex-1">
                  <h6 className="block w-full truncate">{title}</h6>
                  <span className="block truncate text-secondary">
                    #{e.articleNumber}
                  </span>
                </div>
                <span>{convertAmount(e.price?.amount)}</span>
                <Button as="span" light>
                  <Text>button.choose</Text>
                </Button>
              </List.Item>
            );
          })}
        </List>
        {!pimProducts.items?.length && !loading.get && (
          <div className="flex-center flex-col text-center space-y-1 text-placeholder py-16">
            <Icon
              name="BoxRemove"
              variant="Bold"
              size={70}
              className="text-inherit"
            />
            <h6>
              <Text>global.noPimProduct</Text>
            </h6>
            <p>
              <Text>global.addProductManually</Text>
            </p>
          </div>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          pageNumber={+params.pageNumber}
          pageSize={params.pageSize}
          setActivePage={handleSetParams("pageNumber")}
          totalItems={pimProducts.totalItems}
          totalPages={pimProducts.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
