export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  tenant: process.env.REACT_APP_TENANT,
  b2cTenant: process.env.REACT_APP_B2C_TENANT,
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  scope: process.env.REACT_APP_SCOPE,
  subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY,
  lang: process.env.REACT_APP_DEFAULT_LANG,
  isDevelopment:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  pageSize: 10,
};
