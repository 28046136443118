import { variantType } from "../types";
import enums from "./_enums";

type statusItemType = { name: string; id: number; variant: variantType };
const customer: statusItemType[] = [
  {
    name: "status.customer.onboarding",
    id: 0,
    variant: "primary",
  },
  {
    name: "status.customer.registered",
    id: 3,
    variant: "success",
  },
];
const offer: statusItemType[] = [
  {
    id: enums.offerStatus.none,
    name: "status.offer.none",
    variant: "dark",
  },
  {
    id: enums.offerStatus.active,
    name: "status.offer.active",
    variant: "success",
  },
  {
    id: enums.offerStatus.accepted,
    name: "status.offer.accepted",
    variant: "success",
  },
  {
    id: enums.offerStatus.rejected,
    name: "status.offer.rejected",
    variant: "danger",
  },
];
const order: statusItemType[] = [
  {
    id: enums.orderStatus.none,
    name: "status.order.none",
    variant: "dark",
  },
  {
    id: enums.orderStatus.active,
    name: "status.order.active",
    variant: "success",
  },
  {
    id: enums.orderStatus.submitted,
    name: "status.order.submitted",
    variant: "success",
  },
  {
    id: enums.orderStatus.pendingPayment,
    name: "status.order.pendingPayment",
    variant: "warning",
  },
  {
    id: enums.orderStatus.paid,
    name: "status.order.paid",
    variant: "success",
  },
  {
    id: enums.orderStatus.cancel,
    name: "status.order.cancel",
    variant: "danger",
  },
];

const status = { customer, offer, order };
export default status;
