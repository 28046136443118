import isEmpty from "lodash/isEmpty";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import emailIcon from "../../../assets/icons/email.svg";
import locationIcon from "../../../assets/icons/location.svg";
import telephoneIcon from "../../../assets/icons/telephone.svg";
import {
  BackButton,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { useAxios, useToggleState } from "../../../hooks";
import { copyText } from "../../../methods";
import { customerDetailsType } from "../../../types";
import AccountInfo from "./AccountInfo";
import Communication from "./Communication";
import Favorites from "./Favorites";
import Offers from "./Offers";
import Orders from "./Orders";
import Overview from "./Overview";
type customerContextType = {
  customerData: customerDetailsType;
  setCustomerData: Dispatch<SetStateAction<customerDetailsType>>;
};
export const CustomerContext = createContext({} as customerContextType);
export default function CustomerDetails() {
  const { customerId } = useParams();
  const { axios, loading } = useAxios();
  const tabs = [
    {
      label: "tabs.overview",
      Component: Overview,
    },
    {
      label: "tabs.communication",
      Component: Communication,
    },
    {
      label: "tabs.offers",
      Component: Offers,
    },
    {
      label: "tabs.orders",
      Component: Orders,
    },
    {
      label: "tabs.favoritesProducts",
      Component: Favorites,
    },
  ];
  const [data, setData] = useState({} as customerDetailsType);
  const [showAccountInfo, toggleAccountInfo] = useToggleState(false);
  const hasAccount = !!data.signInInfo;
  const signInItems = [
    {
      icon: locationIcon,
      value: data.addresses?.at(0)?.city,
    },
    {
      icon: emailIcon,
      value: data.signInInfo?.email,
    },
    {
      icon: telephoneIcon,
      value: data.signInInfo?.fullMobileNumber,
    },
  ];
  const copyCimNumber = () => {
    copyText(data.cimNumber).then(() => {
      toast.success("toast.success.copyCimNumber");
    });
  };
  const getData = () => {
    const url = `/accountservice/api/customerregistrations/${customerId}`;
    axios.get(url).then(({ data }) => {
      const result = { ...data };
      const hasAddress = !!result.addresses?.length;
      result.personInfo ||= {};
      result.personInfo.preferredCommunicationChannels ||= {};
      result.addresses = hasAddress ? result.addresses : [{ type: 2 }];
      setData(result);
    });
  };
  useEffect(getData, [customerId]);
  return (
    <Layout>
      <Tabs activeKey={tabs[0].label}>
        <Layout.Header>
          <BackButton />
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {loading.get ? (
            <Loading.Header />
          ) : (
            <Wrapper>
              <Wrapper.Body className="flex items-start gap-4">
                <section className="rounded text-success bg-light overflow-hidden">
                  <Icon name="User" variant="Bold" size={96} />
                </section>
                <section className="flex-1 space-y-3">
                  <div className="flex gap-4 items-center">
                    <h6 className="mr-auto">
                      {data.personInfo?.firstName} {data.personInfo?.lastName}
                    </h6>
                    <button
                      type="button"
                      data-has-account={hasAccount}
                      className="w-fit flex-center border border-gray-200 rounded-md p-1 gap-1 filter data-[has-account='false']:grayscale"
                      onClick={toggleAccountInfo}
                    >
                      <Icon
                        name="Mobile"
                        variant="Bold"
                        size={20}
                        className="text-danger"
                      />
                      {hasAccount ? (
                        <Text>button.mobileAccount</Text>
                      ) : (
                        <Text>button.haveNotMobileAccount</Text>
                      )}
                    </button>
                    <Status.Customer id={data.signInInfo?.registrationStatus} />
                  </div>
                  <span
                    className="w-fit block text-secondary"
                    onClick={copyCimNumber}
                  >
                    #{data.cimNumber}
                  </span>
                  <div className="text-secondary flex items-center gap-2 [&_img]:inline-block [&_img]:w-4 [&_img]:mr-1">
                    {signInItems.map((e) => (
                      <span key={e.icon} hidden={!e.value}>
                        <img src={e.icon} alt={e.value} />
                        {e.value}
                      </span>
                    ))}
                  </div>
                </section>
              </Wrapper.Body>
              <Wrapper.Footer className="py-0">
                <Tabs.ButtonGroup>
                  {tabs.map((e) => (
                    <Tabs.Button key={e.label} eventKey={e.label}>
                      <Text>{e.label}</Text>
                    </Tabs.Button>
                  ))}
                </Tabs.ButtonGroup>
              </Wrapper.Footer>
            </Wrapper>
          )}
          <CustomerContext.Provider
            value={{ customerData: data, setCustomerData: setData }}
          >
            {tabs.map(({ Component, ...e }) => (
              <Tabs.Item key={e.label} eventKey={e.label}>
                {!isEmpty(data) && <Component />}
              </Tabs.Item>
            ))}
            <AccountInfo isOpen={showAccountInfo} toggle={toggleAccountInfo} />
          </CustomerContext.Provider>
        </Layout.Body>
      </Tabs>
    </Layout>
  );
}
