import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { Button, Drawer, Icon, List, SearchBox, Text } from "../components";
import { useSelector } from "../hooks";
import { setProfile } from "../redux/profile";
import { toggleSelectStoreMenu } from "../redux/selectStoreActions";
import { storeAddressType, storeType } from "../types";

export default function SelectStoreMenu() {
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const isOpen = useSelector((s) => s.selectStoreActions.showSelectStoreMenu);
  const toggle = () => {
    dispatch(toggleSelectStoreMenu());
  };
  const selectedStoreCode = profile.storeDefaultFilter?.code;
  const handleSelectStore = (store: storeType) => {
    const data = cloneDeep(profile);
    const value = JSON.stringify(store);
    sessionStorage.setItem("default-store", value);
    data.storeDefaultFilter = {
      id: store.storeId,
      code: store.code,
      title: store.title,
      mainPhoto: store.mainPhoto,
      storeAddress: store.storeAddress,
    };
    dispatch(setProfile(data));
    toggle();
  };
  const showAddress = (data: storeAddressType | null) => {
    if (!data) return "";
    const keys: (keyof storeAddressType)[] = [
      "street",
      "houseNo",
      "postalCode",
      "city",
      "state",
      "country",
    ];
    return keys.map((key) => data[key]).join(", ");
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-50">
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-center mb-3">
            <Text>global.selectStore</Text>
          </h6>
          <SearchBox variant="gray" onSubmit={() => {}} />
        </Drawer.Header>
        <Drawer.Body>
          <List>
            {profile.stores?.map((e) => (
              <List.Item key={e.code} onClick={() => handleSelectStore(e)}>
                <Icon.Wrapper>
                  <Icon name="Building" variant="Bold" />
                </Icon.Wrapper>
                <div className="space-y-1 flex-1">
                  <h6 className="truncate">
                    {e.title}
                    <Icon
                      data-active={selectedStoreCode === e.code}
                      name="TickCircle"
                      variant="Bold"
                      className="text-success text-xs opacity-0 data-active:opacity-100 transition-opacity"
                    />
                  </h6>
                  <p className="truncate">
                    <Icon
                      name="Location"
                      variant="Outline"
                      className="text-danger"
                      size={16}
                    />{" "}
                    {showAddress(e.storeAddress)}
                  </p>
                </div>
                <Button as="span" light>
                  <Text>button.choose</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
