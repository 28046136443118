type iframeProps = {
  title: string;
  src: string;
  className?: string;
};

export default function Iframe({ src, title, className = "" }: iframeProps) {
  return (
    <iframe title={title} src={src} className={`${className} bg-gray-100`} />
  );
}
