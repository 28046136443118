import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { authDomain } from ".";
import { config } from "./_config";
const authConfig: Configuration = {
  auth: {
    clientId: config.clientId,
    authority: `https://${authDomain}/${config.b2cTenant}/${config.b2cSigningPolicy}`,
    knownAuthorities: [authDomain],
    redirectUri: config.redirectUrl,
    postLogoutRedirectUri: config.redirectUrl,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
const authInstance = new PublicClientApplication(authConfig);
export default authInstance;
