import { Button, Icon, Modal, Text } from "../../components";
import { togglePropsType } from "../../types";
type infoModalProps = {} & togglePropsType;
export default function InfoModal({ isOpen, toggle }: infoModalProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>To Do EN</Text>
        </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-center flex-col gap-1 text-center text-primary mb-3">
          <Icon name="TaskSquare" variant="Linear" />
          <h6>Check store</h6>
        </div>
        <p className="text-gray-900 text-center text-sm mb-5">
          <Icon
            name="CalendarTick"
            variant="Bold"
            className="text-placeholder"
          />{" "}
          12/08/22 (17:00 -18:30)
        </p>
        <p className="w-full bg-gray-50 text-gray-900 px-4 py-3 rounded">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis
          donec et odio pellentesque diam volutpat commodo sed egestas. Diam
          donec adipiscing tristique risus nec feugiat in fermentum. Lacus
          suspendisse faucibus interdum posuere lorem. Nunc sed id semper risus
          in.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="primary"
          light
          className="block w-fit mx-auto"
          onClick={toggle}
        >
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
