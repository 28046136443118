const now = new Date();
const thisWeek = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() - now.getDay()
);
const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
const last6Month = new Date(now.getFullYear(), now.getMonth() - 6, 1);
const lastYear = new Date(now.getFullYear() - 1, 1, 1);
const addedSinceItems = [
  {
    name: "global.thisWeek",
    id: thisWeek,
  },
  {
    name: "global.thisMonth",
    id: thisMonth,
  },
  {
    name: "global.last6Month",
    id: last6Month,
  },
  {
    name: `${now.getFullYear() - 1}`,
    id: lastYear,
  },
];
export default addedSinceItems;
