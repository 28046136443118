import { ComponentProps, Fragment } from "react";
import { useToggleState, useTranslate } from "../hooks";
import Button from "./_Button";
import Icon from "./_Icon";
import InputGroup from "./_InputGroup";
import Modal from "./_Modal";
import Text from "./_Text";
type itemType = { name: string; id: number | string };
type defaultSelectProps = {
  items: itemType[];
};
type selectProps = defaultSelectProps &
  Omit<
    ComponentProps<typeof InputGroup>,
    keyof "onClick" | "append" | "readOnly" | "type"
  >;
export default function Select({
  label,
  value,
  items = [],
  rules,
  prepend,
  placeholder,
  setValue = () => {},
}: selectProps) {
  const translate = useTranslate();
  const [isOpen, toggle] = useToggleState(false);
  const activeItemName = items.find((e) => `${e.id}` === `${value}`)?.name;
  const handleClick = (id: number | string) => {
    return () => {
      setValue(String(id));
      toggle();
    };
  };
  return (
    <Fragment>
      <InputGroup
        label={label}
        value={translate(activeItemName ?? "")}
        rules={rules}
        prepend={prepend}
        placeholder={placeholder}
        readOnly
        onClick={toggle}
        append={
          <span className="input-group-text text-primary">
            <Icon name="ArrowDown2" size={18} />
          </span>
        }
      />
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
        {!!label && (
          <Modal.Header>
            <Text>{label}</Text>
          </Modal.Header>
        )}
        <Modal.Body className="max-h-96 overflow-auto">
          {items.map((e) => (
            <div
              key={e.id}
              className="py-3 border-b border-gray-100 last:border-none"
            >
              <button
                type="button"
                onClick={handleClick(e.id)}
                className="w-full flex items-center gap-2"
              >
                <span
                  data-active={`${e.id}` === `${value}`}
                  className="relative w-5 h-5 rounded-full bg-gray-100 before:content('') before:absolute before:inset-1 before:bg-primary before:rounded-full before:opacity-0 data-active:before:opacity-100 before:transition-opacity"
                />
                <Text>{e.name}</Text>
              </button>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button onClick={toggle} light>
            <Text>Back</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
