import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { RejectOfferMenu } from "../../../containers";
import { useAxios, useConverters, useToggleState } from "../../../hooks";
import {
  customerDetailsType,
  documentType,
  offerDetailsType,
  orderDetailsType,
} from "../../../types";
import Addresses from "./Addresses";
import Info from "./Info";
import LineItems from "./LineItems";
import PaymentConditions from "./PaymentConditions";
// import UpdateInfo from "./UpdateInfo";
import emailIcon from "../../../assets/icons/email.svg";
import locationIcon from "../../../assets/icons/location.svg";
import telephoneIcon from "../../../assets/icons/telephone.svg";
import { enums } from "../../../constants";
import AcceptInfo from "./AcceptInfo";
import AcceptMenu from "./AcceptMenu";
import Documents from "./Documents";
import EditOffer from "./EditOffer";
import OrderInfo from "./OrderInfo";
import RejectInfo from "./RejectInfo";
type offerContextType = {
  offerData: offerDetailsType;
  customerData: customerDetailsType;
  orderData: orderDetailsType;
  setOfferData: Dispatch<SetStateAction<offerDetailsType>>;
  getOfferData: () => void;
  isEnabled: boolean;
};
export const OfferContext = createContext({} as offerContextType);
export default function OfferDetails() {
  const { axios, loading } = useAxios();
  const { offerId, offerNumber, version } = useParams();
  const { convertAmount } = useConverters();
  const tabs = [
    {
      label: "tabs.lineItems",
      Component: LineItems,
    },
    {
      label: "tabs.address",
      Component: Addresses,
    },
    {
      label: "tabs.salesDocuments",
      Component: Documents,
    },
    {
      label: "tabs.paymentConditions",
      Component: PaymentConditions,
    },
    {
      label: "tabs.offerInfo",
      Component: Info,
    },
  ];
  const [data, setData] = useState<offerDetailsType>({} as offerDetailsType);
  const [customerDetails, setCustomerDetails] = useState(
    {} as customerDetailsType
  );
  const [orderDetails, setOrderDetails] = useState({} as orderDetailsType);
  const [showEditDrawer, toggleEditDrawer] = useToggleState(false);
  const [showRejectDrawer, toggleRejectDrawer] = useToggleState(false);
  const [showAcceptDrawer, toggleAcceptDrawer] = useToggleState(false);
  // const [showUpdateInfoModal, toggleUpdateInfoModal] = useToggleState(false);
  const [showRejectInfoModal, toggleRejectInfoModal] = useToggleState(false);
  const [showAcceptInfoModal, toggleAcceptInfoModal] = useToggleState(false);
  const [showOrderInfoModal, toggleOrderInfoModal] = useToggleState(false);
  const isAccepted = data.statusInfo?.type === enums.offerStatus.accepted;
  const isRejected = data.statusInfo?.type === enums.offerStatus.rejected;
  const isEnabled = !isAccepted && !isRejected;
  const personInfoItems = [
    {
      icon: locationIcon,
      value: customerDetails.addresses?.at(0)?.city,
    },
    {
      icon: emailIcon,
      value: customerDetails.personInfo?.emailAddress,
    },
    {
      icon: telephoneIcon,
      value: customerDetails.personInfo?.mobileNumber,
    },
  ];
  const getData = () => {
    const url = offerId
      ? `/productservice/api/offers/${offerId}`
      : `/productservice/api/offers/by-offer-number/${offerNumber}/${version}`;
    axios.get(url).then(({ data }) => {
      const result = cloneDeep(data);
      const isAccepted = result.statusInfo?.type === enums.offerStatus.accepted;
      result.documents = result.documents?.map((e: documentType) => {
        let sign = e.sign;
        const isTemplate = !!e.templateCode;
        if (isTemplate && e.signMandatory) sign = e.sign;
        if (isTemplate && !e.signMandatory) sign = true;
        if (!isTemplate) sign = e.sign;
        return { ...e, sign };
      });
      setData(result);
      getCustomerDetails(result.customer?.id);
      isAccepted && getOrderDetails(result.offerNumber);
    });
  };
  const getCustomerDetails = (id: string) => {
    if (!isEmpty(customerDetails)) return;
    const url = `/accountservice/api/customerregistrations/${id}`;
    axios.get(url).then(({ data }) => {
      setCustomerDetails(data);
    });
  };
  const getOrderDetails = (offerNumber: string) => {
    const url = `/productservice/api/orders/by-offer-number/${offerNumber}`;
    axios.get(url).then(({ data }) => {
      setOrderDetails(data);
    });
  };
  useEffect(getData, [offerId, offerNumber, version]);
  return (
    <Fragment>
      <Layout>
        <Tabs activeKey={tabs[0].label}>
          <Layout.Header>
            <BackButton className="mr-auto" />
            {isEnabled && !loading.get && (
              <Fragment>
                <Button variant="danger" onClick={toggleRejectDrawer}>
                  <Text>button.rejectOffer</Text>
                </Button>
                <Button variant="success" onClick={toggleAcceptDrawer}>
                  <Text>button.acceptOffer</Text>
                </Button>
              </Fragment>
            )}
          </Layout.Header>
          <Layout.Body className="space-y-4">
            {loading.get ? (
              <Loading.Header />
            ) : (
              <Wrapper>
                <Wrapper.Body className="flex items-start gap-4">
                  <section className="flex-1 space-y-4">
                    <div className="flex items-center gap-2">
                      <Icon.Wrapper rounded>
                        <Icon name="DiscountShape" variant="Bold" />
                      </Icon.Wrapper>
                      <h6>{data.title}</h6>
                      {isEnabled && (
                        <button
                          className="text-primary"
                          onClick={toggleEditDrawer}
                        >
                          <Icon name="Edit2" size={20} />
                        </button>
                      )}
                    </div>
                    <Link
                      to={`/customers/${data.customer?.id}`}
                      className="flex items-start gap-2"
                    >
                      <Icon.Wrapper rounded>
                        <Icon name="User" variant="Bold" />
                      </Icon.Wrapper>
                      <div className="flex-1">
                        <h6 className="leading-8">
                          {customerDetails.personInfo?.firstName}{" "}
                          {customerDetails.personInfo?.lastName}
                        </h6>
                        <div className="text-secondary flex items-center gap-2 [&_img]:inline-block [&_img]:w-4 [&_img]:mr-1">
                          {personInfoItems.map((e) => (
                            <span key={e.icon} hidden={!e.value}>
                              <img src={e.icon} alt={e.value} />
                              {e.value}
                            </span>
                          ))}
                        </div>
                      </div>
                    </Link>
                  </section>
                  <section className="space-y-14 ml-auto flex flex-col items-end">
                    <div className="flex gap-4 items-center">
                      <span
                        hidden={!data.storeInfo}
                        className="block w-fit rounded-md border border-gray-300 py-1 px-2"
                      >
                        <Icon
                          name="LocationTick"
                          variant="Bold"
                          size={18}
                          className="text-danger"
                        />{" "}
                        {data.storeInfo?.title}
                      </span>
                      <span className="bg-gray-50 px-2 py-1 rounded-md">
                        <Icon
                          name="DollarSquare"
                          variant="Bold"
                          size={22}
                          className="text-dark"
                        />{" "}
                        {convertAmount(data.totalAmount)}
                      </span>
                      {isAccepted || isRejected ? (
                        <button
                          type="button"
                          onClick={
                            isAccepted
                              ? toggleAcceptInfoModal
                              : toggleRejectInfoModal
                          }
                        >
                          <Status.Offer id={data.statusInfo?.type} />
                        </button>
                      ) : (
                        <Status.Offer id={data.statusInfo?.type} />
                      )}
                    </div>
                    <div className="space-x-2 flex items-center">
                      {isAccepted && (
                        <button type="button" onClick={toggleOrderInfoModal}>
                          <Icon
                            name="Link1"
                            variant="Linear"
                            size={18}
                            className="text-primary"
                          />{" "}
                          <span className="btn-link">{orderDetails.title}</span>
                        </button>
                      )}
                      <p>
                        <span className="text-secondary">
                          <Text>global.version</Text> {data.version}
                        </span>{" "}
                        <button className="btn-link">
                          <Text>button.change</Text>
                        </button>
                      </p>
                    </div>
                    {/* <button
                    type="button"
                    className="text-secondary"
                    onClick={toggleUpdateInfoModal}
                  >
                    <Text>global.updatedAt</Text>{" "}
                    {convertDate(data.updatedAt || data.creationAt)}
                  </button> */}
                  </section>
                </Wrapper.Body>
                <Wrapper.Footer className="py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
            )}
            <OfferContext.Provider
              value={{
                offerData: data,
                customerData: customerDetails,
                orderData: orderDetails,
                setOfferData: setData,
                getOfferData: getData,
                isEnabled,
              }}
            >
              {!isEmpty(data) && !loading.get && (
                <Fragment>
                  {tabs.map(({ Component, ...e }) => (
                    <Tabs.Item key={e.label} eventKey={e.label}>
                      <Component />
                    </Tabs.Item>
                  ))}
                </Fragment>
              )}
              {/* <UpdateInfo
                isOpen={showUpdateInfoModal}
                toggle={toggleUpdateInfoModal}
              /> */}
              {isEnabled && (
                <Fragment>
                  <EditOffer
                    isOpen={showEditDrawer}
                    toggle={toggleEditDrawer}
                  />
                  <AcceptMenu
                    isOpen={showAcceptDrawer}
                    toggle={toggleAcceptDrawer}
                  />
                </Fragment>
              )}
              {isRejected && (
                <RejectInfo
                  isOpen={showRejectInfoModal}
                  toggle={toggleRejectInfoModal}
                />
              )}
              {isAccepted && (
                <AcceptInfo
                  isOpen={showAcceptInfoModal}
                  toggle={toggleAcceptInfoModal}
                />
              )}
              {isAccepted && (
                <OrderInfo
                  isOpen={showOrderInfoModal}
                  toggle={toggleOrderInfoModal}
                />
              )}
            </OfferContext.Provider>
          </Layout.Body>
        </Tabs>
      </Layout>
      <RejectOfferMenu
        isOpen={showRejectDrawer}
        toggle={toggleRejectDrawer}
        offerData={data}
        onSubmitted={getData}
      />
    </Fragment>
  );
}
