import { isEmpty } from "lodash";
import { Fragment, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Loading } from "../components";
import { useAxios, useSelector } from "../hooks";
import { setProfile } from "../redux/profile";
type props = { children: ReactNode };
export default function ProfileProvider({ children }: props) {
  const dispatch = useDispatch();
  const { axios, loading } = useAxios();
  const profile = useSelector((s) => s.profile);
  const hasProfile = !isEmpty(profile);
  const getProfile = () => {
    const url = "/accountservice/api/users/b2b-user-profile";
    axios.get(url).then(({ data }) => {
      const defaultStoreStorage = sessionStorage.getItem("default-store");
      const result = { ...data };
      if (defaultStoreStorage) {
        result.storeDefaultFilter = JSON.parse(defaultStoreStorage);
      }
      dispatch(setProfile(result));
    });
  };
  useEffect(getProfile, [dispatch]);
  return (
    <Fragment>
      {loading.get ? (
        <div className="mx-auto py-10">
          <Loading.Inline />
        </div>
      ) : (
        hasProfile && children
      )}
    </Fragment>
  );
}
