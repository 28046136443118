import { Fragment } from "react";
import { Button, Drawer, Icon, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { convertNumber } from "../../../../methods";
import { lineItemDetailsType, togglePropsType } from "../../../../types";
type infoMenuProps = togglePropsType & {
  data: lineItemDetailsType;
};
export default function InfoMenu({ isOpen, toggle, data }: infoMenuProps) {
  const { convertAmount } = useConverters();
  const hasDiscount = [data.totalDiscountAmount, data.discount].every(Boolean);
  const hasSupplier = !!data.supplier?.id;
  const hasProgram = !!data.programInfo?.programId;
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="mb-2">{data.translates?.at(0)?.displayName}</h6>
          <span className="block text-secondary">#{data.articleNumber}</span>
        </Drawer.Header>
        <Drawer.Body className="space-y-3 [&>hr]:h-2 [&>hr]:w-full [&>hr]:bg-gray-50">
          <div className="space-y-4">
            <div className="flex flex-center w-full gap-4">
              <Icon.Wrapper
                rounded
                variant={hasSupplier ? "primary" : "warning"}
              >
                <Icon name="Building" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                {hasSupplier ? (
                  <Fragment>
                    <h6>{data.supplier?.name}</h6>
                    <span className="text-secondary">
                      #{data.supplier?.iln}
                    </span>
                  </Fragment>
                ) : (
                  <h6 className="text-warning">
                    <Text>alertTitles.productSupplierNotSet</Text>{" "}
                    <Icon name="InfoCircle" size={18} />
                  </h6>
                )}
              </div>
            </div>
            <div className="flex flex-center w-full gap-4">
              <Icon.Wrapper
                rounded
                variant={hasProgram ? "primary" : "warning"}
              >
                <Icon name="DocumentText" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                {hasProgram ? (
                  <Fragment>
                    <h6>{data.programInfo?.title}</h6>
                    <span className="text-secondary">
                      #{data.programInfo?.code}
                    </span>
                  </Fragment>
                ) : (
                  <h6 className="text-warning">
                    <Text>alertTitles.productProgramNotSet</Text>{" "}
                    <Icon name="InfoCircle" size={18} />
                  </h6>
                )}
              </div>
            </div>
          </div>
          <hr />
          <p className="text-center text-gray-900 p-2 whitespace-pre-wrap">
            {data.shortDescription}
          </p>
          <hr />
          <div>
            <h6>
              <Text>global.price</Text> (€)
            </h6>
            <table className="w-full [&_td]:py-1 [&_td]:px-2 text-gray-900">
              <tbody>
                <tr className="border-b border-gray-50 [&_td]:py-2">
                  <td className="text-start">
                    <Text>global.unitPrice</Text>
                  </td>
                  <td></td>
                  <td className="text-end">
                    {convertAmount(
                      hasDiscount
                        ? data.discount?.productPrice
                        : data.unitPrice?.amount
                    )}
                  </td>
                </tr>
                {hasDiscount && (
                  <Fragment>
                    {data.discount?.discountDetails?.map((e) => (
                      <tr key={`${e.title}${e.percent}`} className="text-sm">
                        <td className="text-start">{e.title}</td>
                        <td className="text-start">
                          %{convertNumber(e.percent)}
                        </td>
                        <td className="text-end text-danger">
                          -{convertAmount(e.amount)}
                        </td>
                      </tr>
                    ))}
                    <tr className="border-t border-gray-50 [&_td]:py-2">
                      <td className="text-start">
                        <Text>global.finalPrice</Text> (€)
                      </td>
                      <td></td>
                      <td className="text-end">
                        {convertAmount(data.discount?.finalPrice)}
                      </td>
                    </tr>
                  </Fragment>
                )}
                <tr className="border-t border-gray-50 [&_td]:py-2">
                  <td className="text-start">
                    <Text>global.totalLineItemPrice</Text> (€)
                  </td>
                  <td></td>
                  <td className="text-end">
                    {convertAmount(data.totalSalePrice)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          {data.programAttributes?.map((e) => (
            <div key={e.code} className="space-y-1 border-b border-gray pb-4">
              <h6>{e.name}</h6>
              <span className="block text-secondary">{e.code}</span>
              <p className="border-l-4 border-primary pl-2">{e.value}</p>
            </div>
          ))}
        </Drawer.Body>
        <Drawer.Footer className="flex-center">
          <Button light onClick={toggle}>
            <Text>button.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
