import noDocumentIcon from "../../../../assets/icons/no document.svg";
import { Text, Wrapper } from "../../../../components";
export default function NoDocument() {
  return (
    <div className="col-span-full flex-center py-8">
      <Wrapper className="w-fit py-7">
        <Wrapper.Body className="flex-center flex-col text-center">
          <img src={noDocumentIcon} alt="no document" className="w-32 mb-7" />
          <h6 className="mb-1 text-primary">
            <Text>page.offers.documents.noDocumentTitle</Text>
          </h6>
          <p className="text-placeholder">
            <Text>page.offers.documents.noDocumentText</Text>
          </p>
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
