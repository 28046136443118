import { iconNameType, variantType } from "../types";
import enums from "./_enums";
type typesItemType = {
  name: string;
  id: number;
  variant?: variantType;
  [key: string]: any;
};
const gender = [
  { name: "types.gender.unknown", id: 0 },
  { name: "types.gender.male", id: 1 },
  { name: "types.gender.female", id: 2 },
  { name: "types.gender.diverse", id: 3 },
  { name: "types.gender.notDefined", id: 4 },
];
const title = [
  { name: "types.title.unknown", id: 0 },
  { name: "types.title.dr", id: 1 },
  { name: "types.title.drIng", id: 2 },
  { name: "types.title.drHC", id: 3 },
  { name: "types.title.prof", id: 4 },
];
const offerSortBy = [
  { name: "types.offerSortBy.none", id: 0 },
  { name: "types.offerSortBy.creationDate", id: 1 },
  { name: "types.offerSortBy.lastUpdate", id: 2 },
  { name: "types.offerSortBy.offerTitle", id: 3 },
];
const product: typesItemType[] = [
  { name: "types.product.pim", id: enums.productType.pim, variant: "warning" },
  {
    name: "types.product.programManual",
    id: enums.productType.programManual,
    variant: "warning",
  },
  {
    name: "types.product.programConfigurable",
    id: enums.productType.programConfigurable,
    variant: "success",
  },
  {
    name: "types.product.Iwofurn",
    id: enums.productType.Iwofurn,
    variant: "success",
  },
];
const program: typesItemType[] = [
  { name: "types.program.none", id: enums.programType.none, variant: "dark" },
  {
    name: "types.program.manual",
    id: enums.programType.manual,
    variant: "warning",
  },
  {
    name: "types.program.configurable",
    id: enums.programType.configurable,
    variant: "success",
  },
];
const customerModes: typesItemType[] = [
  {
    name: "&#128544;",
    id: 5,
  },
  {
    name: "&#128577;",
    id: 4,
  },
  {
    name: "&#128528;",
    id: 3,
  },
  {
    name: "&#128578;",
    id: 2,
  },
  {
    name: "&#128522;",
    id: 1,
  },
];
const incidentPriority: typesItemType[] = [
  {
    name: "types.incidentPriority.high",
    id: 1,
    variant: "success",
  },
  {
    name: "types.incidentPriority.normal",
    id: 2,
    variant: "warning",
  },
  {
    name: "types.incidentPriority.low",
    id: 3,
    variant: "danger",
  },
];
const incidentTopic: typesItemType[] = [
  { name: "types.incidentTopic.generalRequest", id: 202480000 },
  { name: "types.incidentTopic.productRequest", id: 202480001 },
  { name: "types.incidentTopic.deliveryDateAgreement", id: 202480002 },
  { name: "types.incidentTopic.reserveVan", id: 202480003 },
  { name: "types.incidentTopic.purchaseAgreementChange", id: 202480004 },
  { name: "types.incidentTopic.customerComplaint", id: 202480005 },
];
const incidentOrigin: (typesItemType & { icon: iconNameType })[] = [
  { name: "types.incidentOrigin.phone", id: 1, icon: "CallCalling" },
  { name: "types.incidentOrigin.email", id: 2, icon: "SmsNotification" },
  { name: "types.incidentOrigin.web", id: 3, icon: "Global" },
];
const type = {
  gender,
  title,
  offerSortBy,
  product,
  program,
  customerModes,
  incidentPriority,
  incidentTopic,
  incidentOrigin,
};
export default type;
