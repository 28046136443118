import { Button, Icon, Modal, Text } from "../../../../components";
import { iconNameType, togglePropsType } from "../../../../types";
type selectProductModalType = togglePropsType & {
  onSelectProducts: () => void;
  onSelectImportBasket: () => void;
};
type itemsType = {
  icon: iconNameType;
  label: string;
  desc: string;
  onClick: () => void;
};
export default function SelectProductModal({
  isOpen,
  toggle,
  onSelectProducts,
  onSelectImportBasket,
}: selectProductModalType) {
  const items: itemsType[] = [
    {
      icon: "SearchNormal1",
      label: "actionTitles.searchProduct",
      desc: "actionDescriptions.searchProduct",
      onClick: onSelectProducts,
    },
    {
      icon: "DocumentUpload",
      label: "actionTitles.importBasket",
      desc: "actionDescriptions.importBasket",
      onClick: onSelectImportBasket,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-center">
        <Text>modalTitles.selectProductList</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {items.map((e) => (
          <button
            key={e.label}
            type="button"
            className="w-full flex flex-center gap-4 p-6 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
            onClick={() => {
              e.onClick();
              toggle();
            }}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              size={40}
              className="text-primary"
            />
            <div className="text-start flex-1 space-y-1">
              <h6>
                <Text>{e.label}</Text>
              </h6>
              <p className="text-secondary">
                <Text>{e.desc}</Text>
              </p>
            </div>
          </button>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
