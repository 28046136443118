export default function arrayMatrix(
  arr: any[],
  elementsPerSubArray: number = 1
): any[][] {
  const matrix: any = [];
  let i: number;
  let k: number;
  for (i = 0, k = -1; i < arr.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(arr[i]);
  }
  return matrix;
}
