import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import Loading from "./_Loading";

type listProps = { loading?: boolean } & ComponentProps<"ul">;
type listItemProps = ComponentProps<"li">;
function List({ loading = false, className, children, ...props }: listProps) {
  return (
    <ul className={twMerge("", className)} {...props}>
      {loading ? <Loading.List /> : children}
    </ul>
  );
}
function ListItem({ className, children, ...props }: listItemProps) {
  const clickable = !!props.onClick;
  return (
    <li
      className={twMerge(
        "flex items-center w-full text-start gap-4 py-3 border-b border-dashed border-gray-100 last:border-none",
        clickable && "cursor-pointer",
        className
      )}
      {...props}
    >
      {children}
    </li>
  );
}
List.Item = ListItem;
export default List;
