import { isEmpty } from "lodash";
import { Fragment, ReactNode } from "react";
import Loading from "./_Loading";
import NoItems from "./_NoItems";
type tableWrapperProps = {
  children?: ReactNode;
};
type tableProps = {
  children?: ReactNode;
};
type tableTbodyProps = {
  loading?: boolean;
  children?: ReactNode;
};
function TableWrapper({ children }: tableWrapperProps) {
  return <div className={`w-full overflow-auto`}>{children}</div>;
}
function Table({ children }: tableProps) {
  return (
    <table className="table whitespace-nowrap text-left min-w-full">
      {children}
    </table>
  );
}
function TableBody({ loading = false, children }: tableTbodyProps) {
  const hasChildren = !isEmpty(children);
  return (
    <tbody>
      {loading ? (
        <Loading.Tr />
      ) : (
        <Fragment>
          {hasChildren ? (
            children
          ) : (
            <tr>
              <td colSpan={20}>
                <NoItems />
              </td>
            </tr>
          )}
        </Fragment>
      )}
    </tbody>
  );
}
Table.Wrapper = TableWrapper;
Table.Body = TableBody;
export default Table;
