import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Drawer, List, Text } from "../components";
import { languages } from "../constants";
import { languageType, togglePropsType } from "../types";
type settingsMenuProps = togglePropsType;
export default function SettingsMenu({ isOpen, toggle }: settingsMenuProps) {
  const { i18n } = useTranslation();
  const initSetting = useMemo(() => {
    const savedSettings = localStorage.getItem("settings");
    if (!savedSettings) return { language: i18n.language };
    const { language } = JSON.parse(savedSettings);
    return { language };
  }, []);
  const isActiveLanguage = (id: languageType) => {
    return i18n.language === id;
  };
  const handleSetLanguage = (id: languageType) => {
    return () => {
      const settings = cloneDeep(initSetting);
      settings.language = id;
      i18n.changeLanguage(id);
      localStorage.setItem("settings", JSON.stringify(settings));
    };
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.settings</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body>
          <h6 className="text-secondary mb-8">
            <Text>drawerTitles.languages</Text>:
          </h6>
          <List>
            {languages.map((e) => (
              <List.Item
                key={e.id}
                data-active={isActiveLanguage(e.id)}
                className="group"
                onClick={handleSetLanguage(e.id)}
              >
                <div className="w-5 h-5 flex-center rounded-full bg-gray border border-gray transition-colors group-data-active:border-primary">
                  <span className="bg-primary w-3 h-3 opacity-0 rounded-full transition-opacity group-data-active:opacity-100" />
                </div>
                <span className="flex-1">{e.name}</span>
                <img src={e.icon} alt={e.name} className="w-6" />
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" onClick={toggle}>
            <Text>button.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
