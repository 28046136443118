import { toast } from "react-hot-toast";

export default function handleErrors(err: any) {
  const detail = err?.response?.data?.detail || err?.response?.data?.title;
  const status = err?.response?.status;
  const isInvalid = status === 400;
  const isNotFount = status === 404;
  const isConflict = status === 409;
  const isServerError = status === 500;
  if (isConflict || isInvalid || isNotFount) {
    const errorMessage = `toast.error.${detail}`;
    return toast.error(errorMessage);
  }
  // if (isInvalid) return toast.error(err?.response?.data?.detail);
  // if (isInvalid) return toast.error(err?.response?.data?.detail);
  if (isServerError) return toast.error("toast.error.server");
  // if (status) return toast.error(`Error ${status}`);
  toast.error("toast.error.default");
}
