import { dateFormats } from "../constants";
import useCurrentLanguage from "./useCurrentLanguage";

export default function useConverters() {
  const locale = useCurrentLanguage();
  const convertAmount = (amount: number | null | undefined) => {
    return (
      amount?.toLocaleString(locale, {
        style: "currency",
        currency: "EUR",
      }) ?? ""
    );
  };
  const convertDate = (
    date: string | number | Date | null | undefined,
    format: Intl.DateTimeFormatOptions = dateFormats.onlyDate
  ) => {
    if (!date) return "";
    const value = new Date(date);
    return value.toLocaleString(locale, format);
  };
  return { convertAmount, convertDate };
}
