import {
  ComponentProps,
  ElementType,
  MouseEvent,
  ReactNode,
  useMemo,
} from "react";
import { twMerge } from "tailwind-merge";
import { asProps, variantType } from "../types";
import Loading from "./_Loading";
type variantLightType = { light?: boolean; outline?: never };
type variantOutlineType = { light?: never; outline?: boolean };
type variantMiddleType = variantLightType | variantOutlineType;
type buttonProps = {
  type?: "submit" | "button";
  variant?: variantType;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
} & variantMiddleType;
type variantsType = {
  [key in Exclude<variantType, null>]: {
    DEFAULT: string;
    outline: string;
    light: string;
  };
};
export default function Button<E extends ElementType = "button">({
  as,
  children,
  type = "button",
  variant = "primary",
  onClick = () => {},
  light,
  outline,
  className = "",
  loading = false,
  disabled = false,
  ...props
}: buttonProps &
  asProps<E> &
  Omit<ComponentProps<E>, keyof variantMiddleType>) {
  const Component = as || "button";
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const variants: variantsType = {
      primary: {
        DEFAULT: "bg-primary text-white border-primary",
        outline: "bg-transparent text-primary border-primary",
        light: "bg-primary text-primary border-primary",
      },
      secondary: {
        DEFAULT: "",
        light: "",
        outline: "",
      },
      success: {
        DEFAULT: "bg-success text-white border-success",
        outline: "bg-transparent text-success border-success",
        light: "bg-success text-success border-success",
      },
      danger: {
        DEFAULT: "bg-danger text-white border-danger",
        outline: "bg-transparent text-danger border-danger",
        light: "bg-danger text-danger border-danger",
      },
      warning: {
        DEFAULT: "",
        light: "",
        outline: "",
      },
      dark: {
        DEFAULT: "",
        light: "",
        outline: "",
      },
      light: {
        DEFAULT: "",
        light: "",
        outline: "",
      },
      white: {
        DEFAULT: "bg-white text-secondary border-gray",
        light: "",
        outline: "",
      },
    };
    const active = variants[variant];
    if (light) return `${active.light} bg-opacity-5 border-opacity-5`;
    if (outline) return `${active.outline}`;
    return active?.DEFAULT;
  }, [variant, light, outline]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget;
    const rect = el.getBoundingClientRect();
    const left = e.clientX - rect.left;
    const top = e.clientY - rect.top;
    const ripple = document.createElement("span");
    ripple.className = [
      "absolute",
      "w-5 h-5",
      "rounded-full",
      "pointer-events-none",
      "scale-100 opacity-20",
      "transition-[opacity,transform]",
      "duration-700",
      "bg-current",
    ].join(" ");
    ripple.style.setProperty("left", `calc(${left}px - 0.625rem)`);
    ripple.style.setProperty("top", `calc(${top}px - 0.625rem)`);
    el.appendChild(ripple);
    setTimeout(() => {
      ripple.classList.replace("scale-100", "scale-[35]");
      ripple.classList.replace("opacity-20", "opacity-0");
    }, 0);
    setTimeout(() => {
      el.removeChild(ripple);
    }, 900);
    onClick(e);
  };
  return (
    <Component
      disabled={disabled || loading}
      type={type}
      onClick={handleClick}
      className={twMerge(
        "inline-block font-semibold leading-6 no-underline align-middle text-[length:inherit] transition-[opacity,color,background-color,border-color,transform] py-2 px-3 text-center rounded border overflow-hidden relative",
        activeVariant,
        className
      )}
      {...props}
    >
      <span
        data-loading={loading}
        className="data-loading:opacity-0 data-loading:pointer-events-none"
      >
        {children}
      </span>
      {loading && <Loading.Button />}
    </Component>
  );
}
