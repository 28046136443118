import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { OfferContext } from "..";
import {
  Button,
  Drawer,
  Icon,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
type templateType = {
  id: string;
  code: string;
  creationAt: string;
  creationBy: string;
  creatorName: null | string;
  editorName: null | string;
  isErp: true;
  name: string;
  signMandatory: boolean;
  tenantId: string;
  type: string;
  uri: string;
};
type dataType = {
  items: templateType[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
type paramsType = {
  pageSize: number;
  pageNumber: number | string;
  keyword: string | null;
};
export default function ChooseDocumentMenu({
  isOpen,
  toggle,
}: togglePropsType) {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const { offerData } = useContext(OfferContext);
  const [data, setData] = useState<dataType>({} as dataType);
  const [params, setParams] = useState<paramsType>({
    pageSize: config.pageSize,
    pageNumber: 1,
    keyword: "",
  });
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getTemplate = () => {
    if (!isOpen) return;
    const url = "/productservice/api/documenttemplates";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const submitTemplate = (template: templateType) => {
    const url = `/productservice/api/offers/${offerData.offerId}/document`;
    const body = {
      isErp: template.isErp,
      signMandatory: template.signMandatory,
      templateCode: template.code,
      title: template.name,
    };
    axios.post(url, body).then(({ data: documentId }) => {
      navigate(`/offers/${offerData.offerId}/documents/${documentId}`);
    });
  };
  useEffect(getTemplate, [isOpen, params]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-4">
          <h6 className="block">
            <Text>drawerTitles.documentTemplate</Text>
          </h6>
          <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data.items?.map((e) => (
              <List.Item key={e.id}>
                <Icon.Wrapper rounded>
                  <Icon name="DocumentText" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <h6>{e.name}</h6>
                  <span className="text-secondary">{e.type}</span>
                </div>
                <Button
                  light
                  onClick={() => submitTemplate(e)}
                  loading={loading.post}
                >
                  <Text>button.choose</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            setActivePage={handleSetParams("pageNumber")}
            pageNumber={+params.pageNumber}
            totalPages={data.totalPages}
            totalItems={data.totalItems}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
