import { cloneDeep } from "lodash";
import { createContext, Fragment, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import checkIcon from "../../../assets/icons/check.svg";
import {
  Button,
  CheckBox,
  Form,
  Icon,
  Layout,
  Modal,
  Text,
  Wrapper,
} from "../../../components";
import { types } from "../../../constants";
import { useAxios, useToggleState } from "../../../hooks";
import BasicInfo from "./BasicInfo";
import Completed from "./Completed";
import ContactInfo from "./ContactInfo";
import Location from "./Location";
export const AddCustomerDataContext = createContext(
  {} as {
    data: any;
    setData: (val: any) => void;
    hasAddress: boolean;
  }
);
export default function AddNewCustomer() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [showTermModal, toggleTermModal] = useToggleState(false);
  const steps = [
    {
      title: "tabs.basicInfo",
      text: "tabsDescription.basicInfo",
      component: BasicInfo,
    },
    {
      title: "tabs.contactInfo",
      text: "tabsDescription.contactInfo",
      component: ContactInfo,
    },
    {
      title: "tabs.location",
      text: "tabsDescription.location",
      component: Location,
    },
    {
      title: "tabs.completed",
      text: "tabsDescription.completed",
      component: Completed,
    },
  ].map((e, id) => ({ ...e, id }));
  const [data, setData] = useState<any>({
    personInfo: {
      gender: types.gender[0].id,
      title: types.title[0].id,
      gdprAccepted: false,
      familyBonusApplied: false,
      newsletterSubscribed: false,
      countryCode: "+49",
      preferredCommunicationChannels: {
        byApplication: false,
        byEmail: false,
        byPhone: false,
        bySMS: false,
        byPost: false,
        bySocialMedia: false,
      },
    },
    addresses: [{ type: 0, country: "DE" }],
  });
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const ActiveStep = steps[activeStepIndex].component;
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;
  const hasAddress = useMemo(() => {
    const addressArr = [
      "street",
      "number",
      "postalCode",
      "city",
      "state",
      "country",
      "name",
    ]
      .map((key) => data.addresses[0][key])
      .some(Boolean);
    return addressArr;
  }, [data]);
  const toggleGDPR = () => {
    setData((p: any) => {
      const data = cloneDeep(p);
      data.personInfo.gdprAccepted = !data.personInfo.gdprAccepted;
      return data;
    });
  };
  const submitPrevStep = () => {
    setActiveStepIndex((p) => p - 1);
  };
  const submitNextStep = () => {
    setActiveStepIndex((p) => p + 1);
  };
  const submit = () => {
    const url = "/accountservice/api/customers";
    const body = { ...data };
    axios.post(url, body).then(({ data: id }) => {
      const message = "toast.success.addCustomer";
      toast.success(message);
      navigate(`/customers/${id}`, { replace: true });
    });
  };
  return (
    <Fragment>
      <Layout
        as={Form}
        onSubmit={isLastStep ? toggleTermModal : submitNextStep}
      >
        <Layout.Header>
          <h6 className="mr-auto">
            <Text>page.addNewCustomer.title</Text>
          </h6>
          {isFirstStep ? (
            <Link to="..">
              <Button variant="danger">
                <Text>button.cancel</Text>
              </Button>
            </Link>
          ) : (
            <Button light variant="primary" onClick={submitPrevStep}>
              <Text>button.back</Text>
            </Button>
          )}
          {isLastStep ? (
            <Button type="submit" variant="success">
              <Text>button.submitCustomer</Text>
            </Button>
          ) : (
            <Button type="submit">
              <Text>button.next</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body className="flex-center gap-4 py-10">
              {steps.map((e) => (
                <Fragment key={e.id}>
                  <div
                    data-active={e.id === activeStepIndex}
                    data-prev={e.id < activeStepIndex || undefined}
                    className="group flex-center gap-4"
                    onClick={() => {
                      e.id < activeStepIndex && setActiveStepIndex(e.id);
                      // setActiveStepIndex(e.id);
                    }}
                  >
                    <div className="flex-center text-xl bg-primary/10 text-primary w-10 h-10 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
                      <img
                        src={checkIcon}
                        alt={e.title}
                        className="hidden group-data-[prev]:inline-block w-4 h-4"
                      />
                      <span className="inline-flex group-data-[prev]:hidden">
                        {e.id + 1}
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="truncate">
                        <Text>{e.title}</Text>
                      </h6>
                      <span className="truncate block text-secondary text-xs">
                        <Text>{e.text}</Text>
                      </span>
                    </div>
                  </div>
                  <div
                    data-active={e.id <= activeStepIndex}
                    className="group w-7 h-7 [&>*]:w-full [&>*]:h-full [&>*]:object-contain last:hidden"
                  >
                    <Icon
                      name="ArrowRight"
                      variant="TwoTone"
                      className="text-placeholder group-data-active:text-primary"
                      size={28}
                    />
                  </div>
                </Fragment>
              ))}
            </Wrapper.Body>
          </Wrapper>
          <AddCustomerDataContext.Provider
            value={{ data, setData, hasAddress }}
          >
            <ActiveStep />
          </AddCustomerDataContext.Provider>
        </Layout.Body>
      </Layout>
      <Modal isOpen={showTermModal} toggle={toggleTermModal}>
        <Modal.Header>
          <Text>page.addNewCustomer.conditions.title</Text>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <div className="text-secondary">
            <Text>page.addNewCustomer.conditions.text</Text>
          </div>
          <CheckBox
            label="page.addNewCustomer.conditions.gdprAccepted"
            value={data.personInfo?.gdprAccepted}
            setValue={toggleGDPR}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggleTermModal}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            disabled={!data.personInfo?.gdprAccepted}
            variant="success"
            onClick={submit}
            loading={loading.post}
          >
            <Text>button.submit</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
