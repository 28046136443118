import { ReactNode, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { variantType } from "../types";
type badgeType =
  | { fill?: boolean; outline?: never }
  | { fill?: never; outline?: boolean };
type badgeProps = {
  children: ReactNode;
  className?: string;
  variant?: variantType;
} & badgeType;
type colorsType = {
  [V in Exclude<variantType, null>]: string;
};
export default function Badge({
  children,
  variant = "primary",
  className = "",
  fill = false,
  outline = false,
}: badgeProps) {
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const backgroundColors: colorsType = {
      primary: "bg-[#345EAC]",
      danger: "bg-danger",
      warning: "bg-warning",
      success: "bg-success",
      dark: "bg-dark",
      secondary: "bg-secondary",
      light: "",
      white: "",
    };
    const colors: colorsType = {
      primary: "text-[#345EAC]",
      danger: "text-danger",
      warning: "text-warning",
      success: "text-success",
      dark: "text-dark",
      secondary: "text-gray-900",
      light: "",
      white: "",
    };
    const borderColors: colorsType = {
      primary: "border-[#345EAC]",
      danger: "border-danger",
      warning: "border-warning",
      success: "border-success",
      dark: "border-dark",
      secondary: "border-gray-900",
      light: "",
      white: "",
    };
    if (fill)
      return [
        backgroundColors[variant],
        "text-white",
        "py-1 px-2",
        "text-xs",
      ].join(" ");
    if (outline)
      return [
        "border",
        borderColors[variant],
        colors[variant],
        "bg-transparent",
        "py-1 px-3",
        "text-base",
      ].join(" ");
    return [
      backgroundColors[variant],
      colors[variant],
      "bg-opacity-10",
      "py-1 px-3",
      "text-base",
    ].join(" ");
  }, [variant, fill, outline]);
  return (
    <span
      className={twMerge(
        "inline-block align-middle whitespace-nowrap rounded-md font-semibold",
        activeVariant,
        className
      )}
    >
      {children}
    </span>
  );
}
