// import { ReactNode, useEffect, useMemo, useRef } from "react";
// import ReactDOM from "react-dom";

// type portalProps = {
//   children: ReactNode;
// };
// export default function Portal({ children }: portalProps) {
//   const { current: div } = useRef(document.createElement("div"));
//   const id = useMemo(() => crypto.randomUUID(), []);
//   useEffect(() => {
//     div.dataset.key = id;
//     document.body.appendChild(div);
//     return () => {
//       document.body.removeChild(div);
//     };
//   }, []);
//   return ReactDOM.createPortal(children, div);
// }
import { ReactNode } from "react";
import ReactDOM from "react-dom";
type portalProps = {
  children: ReactNode;
};
const id = crypto.randomUUID();
const div = document.createElement("div");
div.id = id;
document.body.appendChild(div);
export default function Portal({ children }: portalProps) {
  return ReactDOM.createPortal(children, div);
}
