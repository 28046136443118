import { Button, Icon, List, Modal, Text } from "../../components";
import { iconNameType, togglePropsType } from "../../types";

type actionModalProps = {} & togglePropsType;
type actionType = {
  icon: iconNameType;
  label: string;
  className: string;
  action: () => void;
};
export default function ActionModal({ isOpen, toggle }: actionModalProps) {
  const actions: actionType[] = [
    {
      icon: "Edit2",
      label: "actionTitles.editTask",
      className: "text-primary",
      action: () => {},
    },
    {
      icon: "Trash",
      label: "actionTitles.deleteTask",
      className: "text-danger",
      action: () => {},
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="space-y-1">
        <h6 className="text-gray-900">
          <Text>Appointment EN</Text>
        </h6>
        <p className="text-secondary">Aug 13, 2023</p>
        <p className="text-secondary">15:00- 15:30</p>
      </Modal.Header>
      <Modal.Body>
        <List>
          {actions.map((e, i) => (
            <List.Item key={e.label} onClick={e.action}>
              <Icon.Wrapper variant="secondary">
                <Icon name={e.icon} variant="Bold" className={e.className} />
              </Icon.Wrapper>
              <span className="flex-1 text-start">
                <Text>{e.label}</Text>
              </span>
            </List.Item>
          ))}
        </List>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="primary"
          light
          className="block w-fit mx-auto"
          onClick={toggle}
        >
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
