import { Button, Drawer, Icon, List, SearchBox, Text } from "../../components";
import { togglePropsType } from "../../types";
type chooseCustomerMenuProps = togglePropsType;
export default function ChooseCustomerMenu({
  isOpen,
  toggle,
}: chooseCustomerMenuProps) {
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-40">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6>
            <Text>modalTitles.chooseCustomer</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox variant="gray" onSubmit={() => {}} />
        </Drawer.Header>
        <Drawer.Body>
          <List>
            <List.Item>
              <Icon.Wrapper
                className="bg-[#EEF6FF] text-[#C1CFD9] bg-opacity-100 p-1 text-xl"
                circle
              >
                <Icon name="User" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                <h6 className="block">Brad Dennis</h6>
                <span className="block text-secondary text-sm">
                  #8473286089
                </span>
              </div>
              <Button type="button" variant="primary" light>
                <Text>button.choose</Text>
              </Button>
            </List.Item>
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Button
            type="button"
            variant="primary"
            light
            onClick={toggle}
            className="block w-fit mx-auto"
          >
            <Text>button.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
