const de = {
  global: {
    noItems: "Kein Eintrag gefunden",
    pagination: "Showing {{ from }} to {{ to }} of {{ totalItems }} entries DE",
    filter: "Filter",
    customerInfo: "Kundeninfo",
    addedAt: "Added at DE",
    createdAt: "Erstellt am",
    lastUpdate: "Letzte Aktualisierung",
    until: "bis",
    updatedAt: "Aktualisiert am",
    signUpDate: "Anmeldedatum",
    searchBox: "Suchen...",
    searchCalendarItem: "Search task title or customer names... DE",
    submit: "Bitte übermitteln Sie Ihre Änderungen",
    logout: "Abmelden",
    by: "bei",
    version: "Version",
    signedByCustomer: "Signiert vom Kunde",
    orEnterManual: "oder manuelle Eingabe",
    noAddress: "Keine Adresse vorhanden",
    tomorrow: "Morgen",
    next3days: "Die nächsten 3 Tage",
    in7days: "Die nächsten 7 Tage",
    thisWeek: "diese Woche",
    thisMonth: "dieser Monat",
    last6Month: "letzte 6 Monate",
    selectStore: "Filiale wählen",
    price: "Preis",
    unitPrice: "Einzelpreis",
    finalPrice: "Summe",
    totalPrice: "Gesamtpreis",
    productPrice: "Produktpreis",
    totalLineItemPrice: "Total line item price DE",
    supplierPrice: "EK Preis",
    customerAddress: "Kundenadresse",
    noPimProduct: "Kein Produkt gefunden",
    addProductManually: "Produkt manuell hinzufügen",
    confirmDeleteDocument: "Are you sure you want delete this document? DE",
    pleaseWait: "Please wait ... DE",
  },
  status: {
    customer: {
      notRegistered: "Nicht registriert",
      onboarding: "Onboarding",
      registered: "Registriert",
    },
    offer: {
      none: "-",
      active: "Aktiv",
      accepted: "Angenommen",
      rejected: "Abgelehnt",
    },
    order: {
      none: "-",
      active: "Aktiv",
      submitted: "Eingereicht",
      pendingPayment: "Eingereicht",
      paid: "Bezahlt",
      cancel: "Storniert",
    },
  },
  types: {
    gender: {
      unknown: "Unbekannt",
      male: "Männlich",
      female: "Weiblich",
      diverse: "Verschiedenartig",
      notDefined: "Nicht definiert",
    },
    title: {
      unknown: "Unbekannt ",
      dr: "Dr",
      drIng: "DrIng",
      drHC: "DrHC",
      prof: "Prof",
    },
    offerSortBy: {
      none: "Standard",
      creationDate: "Erstellungsdatum",
      lastUpdate: "Letzte Aktualisierung",
      offerTitle: "Angebotstitel",
    },
    product: {
      pim: "PIM Produkt",
      Iwofurn: "Iwofurn",
      programManual: "Program Produkt",
      programConfigurable: "Konfigurierbar",
    },
    program: {
      none: "Keine",
      manual: "Manuell",
      configurable: "Konfigurierbar",
    },
    document: {
      template: "Vorlage ",
      personal: "Bestehendes Dokument",
    },
    incidentPriority: {
      high: "Hoch",
      normal: "Normal",
      low: "niedrig",
    },
    incidentTopic: {
      generalRequest: "Allgemeine Anfrage",
      productRequest: "Produktanfrage",
      deliveryDateAgreement: "Lieferterminvereinbarung",
      reserveVan: "Transporterreservierung",
      purchaseAgreementChange: "Kaufvertragsänderung",
      customerComplaint: "Kundenbeanstandung",
    },
    incidentOrigin: {
      phone: "Telefon",
      email: "E-mail",
      web: "Website Formular",
    },
  },
  rules: {
    required: "Feld ist erforderlich",
    email: "Die E-Mail ist ungültig",
    postalCode: "Field must be 5 characters in length DE",
    integer: "Only integer are valid DE",
  },
  button: {
    filter: "Filter",
    action: "Aktion",
    addNew: "Neu hinzufügen",
    ok: "Ok",
    cancel: "Abbrechen",
    back: "Zurück",
    close: "Schließen",
    change: "Ändern",
    delete: "Löschen",
    next: "Weiter",
    submit: "Abschicken",
    submitChanges: "Änderungen einreichen",
    search: "Suchen",
    viewDetails: "Details anzeigen",
    choose: "Wählen",
    submitCustomer: "Kunde übermitteln",
    deactivateAccount: "Konto deaktivieren",
    addOffer: "Neues Angebot erstellen",
    saveAndCloseOffer: "Speichern und schließen",
    nextAndCompleteOffer: "Angebot erstellen",
    rejectOffer: "Angebot ablehnen",
    acceptOffer: "Angebot annehmen",
    cancelForNow: "Abbrechen",
    submitOffer: "Angebot abgeben",
    notSet: "nicht angegeben",
    chooseFile: "Datei auswählen",
    createDocument: "neue Dokument",
    requireSigned: "Muss unterschrieben werden",
    addDocument: "Dokument hinzufügen",
    resetFilter: "Zurücksetzen",
    changeStore: "andere Filiale",
    selectStore: "Filiale auswählen",
    clear: "löschen",
    mobileAccount: "App-Benutzer",
    haveNotMobileAccount: "kein App-Benutzer",
    clickToSign: "hier unterschreiben",
    showInfo: "mehr info",
    addAttribute: "neues Attribut",
    addProduct: "neues Produkt",
    addAddress: "Adresse setzen",
    qrCode: "QR code",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
    use: "verwenden",
    productList: "Produktliste",
    calculate: "Preis berechnen",
    importProducts: "Produkte importieren",
    submitIncident: "Anfrage hinzufügen",
    createNewIncident: "neue Anfrage",
    attachProductsIncident: "Position auswählen",
    viewOfferDetails: "View details DE",
    viewOrderDetails: "View details DE",
    backToBasketList: "Back to list DE",
    importBasket: "Import basket DE",
    showCalendar: "Show calendar DE",
    hideCalendar: "Hide calendar DE",
    submitAppointment: "Submit appointment DE",
    submitTodo: "Submit to do DE",
  },
  tableTitles: {
    lineItemName: "Position",
    lineItemAction: "Aktion",
    lineItemUnitPrice: "Einzelpreis",
    lineItemQuantity: "Menge",
    lineItemPrice: "Positionspreis",
    lineItemProgramCode: "Program Code DE",
    lineItemSupplierILN: "Supplier ILN DE",
    basketInternalTag: "Basket internal tag DE",
    basketTag: "Tag DE",
    basketCreatedAt: "Created at DE",
    basketSenderName: "Sender name DE",
    basketPrice: "Iwofurn basket price DE",
  },
  drawerTitles: {
    filter: "Filter",
    createOffer: "Angebot erstellen",
    editOffer: "Angebot bearbeiten",
    acceptOffer: "Angebot annehmen",
    rejectOffer: "Angebot ablehnen",
    personalDocument: "Dokumente",
    documentTemplate: "Dokumentvorlage wählen",
    shipTo: "Lieferadresse",
    billTo: "Rechnungsadresse",
    addProduct: "Produkt hinzufügen",
    chooseProgram: "Programm wählen",
    chooseSupplier: "Lieferant wählen",
    editOrderInfo: "Bestellung bearbeiten",
    createNewIncident: "neue Anfrage hinzufügen",
    attachProductsIncident: "Position zum Anfrage hinzufügen",
    settings: "Einstellungen",
    languages: "Sprache",
    importBasket: "Import products from IWOFURN DE",
    addNewAppointment: "Add new appointment DE",
    addNewTodo: "Add new to do DE",
  },
  modalTitles: {
    favoriteProductDetails: "Product details DE",
    rejectInfo: "Ablehnen",
    updateInfo: "Aktualisieren",
    downPayment: "Anzahlung",
    documentType: "Dokumenttyp auswählen",
    signHere: "Hier unterschreiben",
    mobileAccountInfo: "App-Benutzerinformationen",
    deleteProduct: "Produkt löschen",
    selectProductList: "Produkt auswählen",
    shareQrCodeWithCustomer: "Zahlungs-QR-Code",
    confirmDeleteDocument: "Dokument entfernen",
    assignedOffer: "Assigned offer DE",
    assignedOrder: "Assigned order DE",
    acceptOfferInfo: "Publish info DE",
    chooseCalendarTaskType: "Choose task type DE",
    chooseCustomer: "Choose customer DE",
  },
  actionTitles: {
    editOffer: "Angebot bearbeiten",
    sendEmailOffer: "Angebot als E-Mail versenden",
    rejectOffer: "Angebot ablehnen und löschen",
    customerDetailsOffer: "Kundeninfo",
    // Offer document type
    personalDocument: "Dokument hochladen",
    templateDocument: "Vorlagen auswählen",
    // Offer line items
    searchProduct: "Produkte suchen",
    importBasket: "Import basket DE",
    editProduct: "Produkt bearbeiten",
    addSubProduct: "Unterprodukt hinzufügen",
    deleteProduct: "Produkt löschen",
    // Order Action
    editOrder: "Bearbeiten",
    assignedOffer: "Angebote",
    sendOrderEmail: "Bestellung als E-Mail versenden",
    // Calendar
    addNewAppointment: "Appointment DE",
    addNewTodo: "To do DE",
    editTask: "Edit DE",
    deleteTask: "Delete DE",
  },
  actionDescriptions: {
    // Offer document type
    personalDocument: "vorhandenes Dokument hochladen",
    templateDocument: "Dokumente aus Vorlagen suchen und importieren",
    // Offer line items
    searchProduct: "PIM, IWOFURN ...",
    importBasket: "from IWOFURN DE",
  },
  tabs: {
    // Add customer
    basicInfo: "Allgemein",
    contactInfo: "Kontaktinformationen",
    location: "Standort",
    completed: "Abgeschlossen",
    // Customer details
    overview: "Übersicht",
    communication: "Kommunikationskanäle",
    offers: "Angebote",
    orders: "Bestellungen",
    favoritesProducts: "Favorites DE",
    // Offer details
    lineItems: "Artikel",
    address: "Adresse",
    salesDocuments: "Verkaufsdokumente",
    paymentConditions: "Zahlungsbedingungen",
    offerInfo: "Angebotsinfo",
    incidents: "Anfrage",
  },
  tabsDescription: {
    // Add customer
    basicInfo: "Name..",
    contactInfo: "E-mail..",
    location: "Adresse..",
    completed: "fertiggestellt",
  },
  formControls: {
    firstName: "Vorname",
    lastName: "Nachname",
    gender: "Geschlecht",
    title: "Titel",
    birthDate: "Geburtsdatum",
    email: "E-Mail-Adresse",
    countryCode: "Country code DE",
    mobileNumber: "Handynummer",
    phoneNumber: "Telefonnummer",
    searchAddress: "Adresse suchen",
    name: "Name in Adresse",
    street: "Straße",
    number: "Nummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    state: "Bundesland",
    country: "Land",
    description: "Beschreibung",
    offerTitle: "Angebot Titel",
    CustomerFirstName: "Vorname",
    CustomerLastName: "Nachname",
    CustomerEmailAddress: "Email Adresse",
    CustomerMobileNumber: "Kundennummer",
    CustomerPhoneNumber: "Telefonnummer",
    reason: "Grund",
    paymentDueDate: "Zahlungsdatum",
    orderTitle: "Bestellung Titel",
    orderNote: "Bestellnotizen",
    downPayment: "Anzahlung",
    documentTitle: "Titel des Dokuments",
    note: "Hinweis",
    file: "Datei",
    addedSince: "Hinzugefügt seit",
    productTitle: "Produkttitel",
    articleNumber: "Artikelnummer",
    price: "Preis",
    quantity: "Menge",
    supplierArticleNumber: "Lieferantenartikelnummer",
    deActiveCustomer: "Bestätigen Sie die Kontodeaktivierung",
    incidentTitle: "Titel",
    incidentPriority: "Priorität",
    incidentOrigin: "Ursprung",
    incidentTopic: "Routine",
    incidentTopicDescription: "Routine Hinweis",
    incidentAttachmentProducts: "Position",
    incidentCustomerMood: "Kundenstimmung",
    incidentDeadline: "Abgabetermin",
    incidentDescription: "Beschreibung",
    removeBasketAfterImport: "Remove basket after import DE",
    calendarTaskTime: "Time DE",
    calendarTaskSyncToUser: "Sync to user DE",
    calendarTaskTitle: "Title DE",
    calendarTaskDescription: "Description DE",
  },
  togglers: {
    requiredSign: "Muss unterschrieben werden",
    erp: "im Kundenauftrag enthalten (ERP)",
    signedByCustomer: "bereits vom Kunden unterschrieben",
    calendarTaskAllDay: "All day DE",
  },
  confirms: {
    addOffer:
      "Ich bestätige die ausgewählte Filiale und die Bestellung wird in der ausgewählten Filiale erstellt.",
    acceptOffer: "Ich bestätige, dass das Angebot vom Kunden angenommen wird.",
    rejectOffer: "Ich bestätige, dass das Angebot vom Kunden abgelehnt wird.",
    deleteProduct: "Möchten Sie dieses Produkt wirklich entfernen?",
  },
  placeholders: {
    searchAddress: "Adresse suchen",
    firstName: "Vorname",
    lastName: "Nachname",
    gender: "Bitte Geschlecht auswählen",
    title: "Titel auswählen",
    birthDate: "Geburtsdatum auswählen",
    email: "Bitte E-Mail-Adresse eingeben",
    mobileNumber: "Beispiel: +49 1645687890",
    phoneNumber: "Beispiel: +49 1645687890",
    name: "Adresse Name",
    street: "Straße",
    number: "Nummer",
    postalCode: "PLZ",
    city: "Stadt",
    state: "Bundesland",
    country: "Land",
    documentTitle: "Titel",
    documentNote: "mehr Infos",
    value: "wert",
    attributeName: "Name",
    code: "Code",
    searchPimProduct: "Produktname oder Artikelnummer suchen",
    incidentTitle: "Anfrage Titel",
    incidentPriority: "Priorität auswählen",
    incidentOrigin: "Priorität auswählen",
    incidentTopic: "Ursprung auswählen",
    incidentTopicDescription: "auswählen",
    incidentAttachmentProducts: "auswählen",
    incidentDeadline: "Abgabetermin",
    incidentDescription: "auswählen",
    searchBaskets: "Search iwofurn basket ID or name DE",
    calendarTaskTime: "Select DE",
  },
  alertTitles: {
    // Customer details
    deactivateAccount:
      "Sind Sie sicher, dass Sie dieses Kundenkonto deaktivieren möchten?",
    // Offer details
    supplierLineItemRequired: "No supplier sync. DE",
    supplierSubLineItemRequired: "No supplier sync. DE",
    programLineItemRequired: "No program sync. DE",
    programSubLineItemRequired: "No program sync. DE",
    addressRequired: "Adresse ist erforderlich",
    documentRequired: "Dokument erforderlich",
    paymentConditionRequired: "Zahlungsbedingung ist erforderlich",
    productSupplierNotSet: "Supplier not set DE",
    productProgramNotSet: "Program not set DE",
  },
  alertDescriptions: {
    // Customer details
    deactivateAccount:
      "Über diese Aktion wird der Kunde per E-Mail benachrichtigt.",
    // Offer details
    supplierLineItemRequired:
      "There is some line item in this offer that has no supplier. please check it. DE",
    supplierSubLineItemRequired:
      "There is some sub line item in this offer that has no supplier. please check it. DE",
    programLineItemRequired:
      "There is some line item in this offer that has no program. please check it. DE",
    programSubLineItemRequired:
      "There is some sub line item in this offer that has no program. please check it. DE",
    addressRequired: "Rechnungs- und Lieferadressen nicht festgelegt.",
    documentRequired:
      "Es gibt Dokumente, die nicht vom Kunden unterzeichnet sind.",
    paymentConditionRequired: "Zahlungsbedingungen nicht festgelegt.",
    selectStore: "Bitte wählen Sie Ihr Filiale aus",
    haveNotMobileAccount: "Dieser Benutzer hat kein mobiles App-Konto!",
  },
  menu: {
    titles: {
      customers: "KUNDEN",
      account: "KONTEN",
    },
    items: {
      customers: "Kunden",
      offers: "Angebote",
      orders: "Aufträge",
      calendar: "Calendar DE",
      notifications: "Benachrichtigungen",
      settings: "Einstellungen",
    },
  },
  toast: {
    success: {
      addCustomer: "Der Kunde wurde erfolgreich hinzugefügt",
      editCustomer: "Der Kunde hat erfolgreich bearbeitet",
      removeCustomer: "Customer removed successfully. DE",
      createOffer: "Angebot erfolgreich erstellt.",
      editOffer: "Angebot erfolgreich bearbeitet.",
      acceptOffer: "Angebot erfolgreich angenommen",
      rejectOffer: "Offer rejected successfully. DE",
      sendOfferMail: "Email sended successfully. DE",
      sendOrderMail: "Email sended successfully. DE",
      copyCimNumber: "Cim number copied successfully. DE",
      copyOfferNumber: "Offer number copied successfully. DE",
      copyOrderNumber: "Order number copied successfully. DE",
      setDownPayment: "Anzahlung erfolgreich eingerichtet",
      addDocument: "Dokument erfolgreich erstellt",
      editDocument: "Document edited successfully. DE",
      removeDocument: "Document removed successfully. DE",
      addSubLineItem: "Sub line item added successfully. DE",
      addLineItem: "Produkt erfolgreich hinzugefügt",
      editSubLineItem: "Sub line item edited successfully. DE",
      editLineItem: "Line item edited successfully. DE",
      removeSubLineItem: "Sub line item removed successfully. DE",
      removeLineItem: "Line item removed successfully. DE",
      addIncident: "Incident added successfully. DE",
      importIwofurnProduct: "Product imported successfully. DE",
      importBasket: "Your Basket items has been imported. DE",
    },
    error: {
      default: "Ein Fehler ist aufgetreten",
      file: "Bitte Datei auswählen",
      form: "Die Formulardaten sind unvollständig",
      server: "Ein technisches Problem liegt vor",
      incidentCustomerMood: "Customer mood is required DE",
      8016: "Only active offer could be updated",
      8014: "Signed document could not be removed or updateed DE",
      8028: "Make Order Not Possible When Line Item Supplier Id Null DE",
      8029: "Make Order Not Possible When Sub Line Item Supplier Id Null DE",
      8018: "Update Sub Line Item Is Not Possible When Line Item NotFound. DE",
      6003: "Der Programmattributcode sollte eindeutig sein",
      5000: "Down payment should be between 0 and total amount. DE",
      5001: "Liefer- und Rechnungsadresse sollten angegeben werden",
      5003: "Es gibt einige nicht unterzeichnete Dokumente. Bitte unterschreiben Sie diese, bevor Sie den Kundenauftrag erstellen.",
      5004: "Es gibt keine Verkaufsposition.",
      1001: "Company Not Found. DE",
      1002: "Die Mobiltelefonnummer ist bereits registriert",
      1003: "Device Token Is Not Valid. DE",
      1004: "Email Adresse ist bereits registriert",
      1005: "Email Confirmation Code Is Expired. DE",
      1006: "Email Confirmation Code Is NotValid. DE",
      1007: "Mobile Number Is Not Confirmed. DE",
      1008: "Mobile Number Verification Code Is Not Valid. DE",
      1009: "Pass Code Must Be Null Or Empty. DE",
      1010: "Pass Code Must Exists. DE",
      1011: "Remove Customer Is Not Possible. DE",
      1012: "Der Kunde muss die DSGVO akzeptieren",
      1013: "Customer Must Be Person. DE",
      1014: "New Pass Code Is Not Match. DE",
      1015: "Mobile Number Must Registered. DE",
      1201: "Invalid Pass Code. DE",
      1301: "Kunde nicht gefunden",
      12000: "Duplicate Iwofurn Configuration DE",
      12001: "Iwofurn Configuration Not Found DE",
      12002: "Store Iln Not Exist DE",
      12003: "Iwofurn Configuration Url Is Not Set DE",
      12004: "Iwofurn Configuration User Name Is Not Set DE",
      12005: "Iwofurn Configuration Password Is Not Set DE",
    },
  },
  offerPaymentDetails: {
    text: "Die Zahlungsdetails können Sie hier einsehen",
    charges: "Gebühren",
    quantity: "Menge",
    total: "Gesamt",
    lineItems: "Positionen",
    downPayment: "Anzahlung",
  },
  page: {
    customerList: {
      searchBox: "Suche...",
    },
    addNewCustomer: {
      title: "neue Kunde",
      conditions: {
        title: "Geschäftsbedingungen",
        text: "Muss vom Kunden akzeptiert werden",
        gdprAccepted: "DSGVO Akzeptiert",
      },
    },
    customerDetails: {
      overview: {
        removeCustomer: {
          title: "Kundenkonto deaktivieren",
        },
      },
      communication: {
        byPhone: "Phone",
        byApplication: "In App-Benachrichtigung",
        bySocialMedia: "Whatsapp",
        byEmail: "Email",
        bySMS: "SMS",
        byPost: "Post",
        familyBonusApplied: "TEILNAHME FAMILIENBONUS",
        newsletterSubscribed: "Newsletter",
      },
      offers: {
        filter: {
          status: {
            label: "Status",
          },
          sortBy: {
            label: "Sortiere nach",
          },
        },
        documents: {
          noDocumentTitle: "There is no Document DE",
          noDocumentText: "You can not add or delete any document DE",
        },
      },
      orders: {},
    },
    offers: {
      documents: {
        noDocumentTitle: "There is no Document DE",
        noDocumentText: "You can not add or delete any document DE",
      },
    },
    calendar: {
      noItemTitle: "This day is free DE",
      noItemDescription: "No item found DE",
    },
  },
};
export default de;
