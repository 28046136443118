type progressProps = {
  value: number;
};
export default function Progress({ value }: progressProps) {
  return (
    <div className="w-full h-2 rounded-full overflow-hidden bg-gray-50">
      <span
        style={{ width: `${value}%` }}
        className="block max-w-full h-full bg-primary transition-[width]"
      />
    </div>
  );
}
