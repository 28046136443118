import { cloneDeep, isEmpty } from "lodash";
import { Fragment, useContext, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { OfferContext } from "..";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Wrapper,
} from "../../../../components";
import { countries, rules } from "../../../../constants";
import { SearchAddress } from "../../../../containers";
import { useAxios, useToggleState } from "../../../../hooks";
import { convertAddress } from "../../../../methods";
import { addressType } from "../../../../types";
type addressProps = {
  type: "billTo" | "shipTo";
};
type offerUpdateDataType = {
  title: string;
  customerCIMNumber: string;
  companyPrefixOrderNumber: number;
  billTo: addressType | null;
  shipTo: addressType | null;
  customerPersonInfo: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber: string;
  };
};
export default function Address({ type }: addressProps) {
  const isShipTo = type === "shipTo";
  const isBillTo = type === "billTo";
  const { axios, loading } = useAxios();
  const { offerData, customerData, setOfferData, isEnabled } =
    useContext(OfferContext);
  const customerHasAddress = !!customerData?.addresses?.length;
  const label = useMemo(() => {
    if (isShipTo) return "drawerTitles.shipTo";
    if (isBillTo) return "drawerTitles.billTo";
    return "";
  }, [isShipTo, isBillTo]);
  const addressData = useMemo(() => {
    const init = {} as addressType;
    if (isBillTo) return offerData.billTo || init;
    if (isShipTo) return offerData.shipTo || init;
    return init;
  }, [isShipTo, isBillTo, offerData.billTo, offerData.shipTo]);
  const initData = { ...addressData, country: "DE" };
  const [data, setData] = useState<addressType>(initData);
  const [showFormMenu, toggleFormMenu] = useToggleState(false);
  const hasAddress = !isEmpty(addressData);
  const handleSetValue = (key: string) => {
    return (val: string) => {
      setData((p) => ({ ...p, [key]: val }));
    };
  };
  const setCustomerAddress = () => {
    setData(customerData.addresses.at(0) || initData);
  };
  const submit = () => {
    const url = `/productservice/api/offers/${offerData.offerId}`;
    const body: offerUpdateDataType = {
      billTo: offerData.billTo,
      shipTo: offerData.shipTo,
      companyPrefixOrderNumber: 1000,
      customerCIMNumber: offerData.customer.cimNumber,
      customerPersonInfo: offerData.customer.personInfo,
      title: offerData.title,
    };
    if (isShipTo) body.shipTo = { ...data, type: 1 };
    if (isBillTo) body.billTo = { ...data, type: 2 };
    axios.put(url, body).then(() => {
      setOfferData((p) => {
        const data = cloneDeep(p);
        if (isShipTo) data.shipTo = body.shipTo;
        if (isBillTo) data.billTo = body.billTo;
        return data;
      });
      toggleFormMenu();
      toast.success("toast.success.editOffer");
    });
  };
  return (
    <Fragment>
      <section className="space-y-3">
        <div className="w-full flex items-center justify-between">
          <span>
            <Text>{label}</Text>
          </span>
          {isEnabled && (
            <button
              type="button"
              className="bg-white rounded-lg p-2"
              onClick={toggleFormMenu}
            >
              <Icon name="Edit2" className="text-primary" size={20} />
            </button>
          )}
        </div>
        {isShipTo && (
          <hr className="w-full block bg-[#FFC700] h-1 rounded-full" />
        )}
        {isBillTo && (
          <hr className="w-full block bg-success h-1 rounded-full" />
        )}
        <Wrapper>
          <Wrapper.Body className="h-40 flex flex-col flex-center">
            <Icon
              name="Location"
              variant="Bulk"
              size={32}
              className="text-primary block mb-4"
            />
            {hasAddress ? (
              <p className="flex-1 text-center text-secondary leading-6">
                <span>
                  {addressData?.name} {addressData?.street}{" "}
                  {addressData?.number}
                </span>
                <br />
                <span>
                  {addressData?.postalCode} {addressData?.city}
                </span>
                <br />
                <span>
                  {addressData?.state} {addressData?.country}
                </span>
              </p>
            ) : (
              <div className="flex-1 space-y-4">
                <p className="text-center text-secondary">
                  <Text>global.noAddress</Text>
                </p>
                {isEnabled && (
                  <Button
                    className="block w-fit mx-auto"
                    onClick={toggleFormMenu}
                  >
                    <Icon name="Add" size={20} className="mr-1" />
                    <Text>button.addAddress</Text>
                  </Button>
                )}
              </div>
            )}
          </Wrapper.Body>
        </Wrapper>
      </section>
      <Drawer
        as={Form}
        onSubmit={submit}
        isOpen={showFormMenu}
        toggle={toggleFormMenu}
      >
        <Drawer.Menu>
          <Drawer.Header className="space-y-2">
            <Text>{label}</Text>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            {customerHasAddress && (
              <div className="flex items-center gap-4 border border-gray rounded p-4">
                <Icon name="User" size={25} className="mb-auto text-primary" />
                <div className="flex-1 space-y-1">
                  <h6>
                    <Text>global.customerAddress</Text>:
                  </h6>
                  <p className="text-gray-500">
                    {convertAddress(customerData.addresses?.at(0))}
                  </p>
                </div>
                <Button light className="btn-sm" onClick={setCustomerAddress}>
                  <Text>button.use</Text>
                </Button>
              </div>
            )}
            <SearchAddress
              setValue={(data) => setData((p) => ({ ...p, ...data }))}
            />
            <span className="block w-full text-center text-sm text-secondary !my-6">
              <Text>global.orEnterManual</Text>
            </span>
            <InputGroup
              label="formControls.name"
              placeholder="placeholders.name"
              value={data.name}
              setValue={handleSetValue("name")}
              rules={rules.required}
            />
            <section className="flex gap-4">
              <div className="flex-[2]">
                <InputGroup
                  label="formControls.street"
                  placeholder="placeholders.street"
                  value={data.street}
                  setValue={handleSetValue("street")}
                  rules={rules.required}
                />
              </div>
              <div className="flex-1">
                <InputGroup
                  label="formControls.number"
                  placeholder="placeholders.number"
                  value={data.number}
                  setValue={handleSetValue("number")}
                  rules={rules.required}
                />
              </div>
            </section>
            <section className="flex items-start gap-4">
              <div className="flex-1">
                <InputGroup
                  label="formControls.postalCode"
                  placeholder="placeholders.postalCode"
                  value={data.postalCode}
                  setValue={handleSetValue("postalCode")}
                  rules={rules.required}
                />
              </div>
              <div className="flex-1">
                <InputGroup
                  label="formControls.city"
                  placeholder="placeholders.city"
                  value={data.city}
                  setValue={handleSetValue("city")}
                  rules={rules.required}
                />
              </div>
            </section>
            <section className="flex items-start gap-4">
              <div className="flex-1">
                <Select
                  label="formControls.country"
                  placeholder="placeholders.country"
                  items={countries}
                  value={data.country}
                  setValue={handleSetValue("country")}
                  rules={rules.required}
                />
              </div>
              <div className="flex-1">
                <Select
                  label="formControls.state"
                  placeholder="placeholders.state"
                  items={
                    countries.find((e) => e.id === data.country)?.states || []
                  }
                  value={data.state}
                  setValue={handleSetValue("state")}
                  rules={rules.required}
                />
              </div>
            </section>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button variant="danger" onClick={toggleFormMenu}>
              <Text>button.cancel</Text>
            </Button>
            <Button type="submit" loading={loading.update}>
              <Text>button.submitChanges</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
    </Fragment>
  );
}
