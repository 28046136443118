import isEmpty from "lodash/isEmpty";
import { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Modal, Text } from "../components";
import { useSelector } from "../hooks";
import {
  toggleSelectStoreMenu,
  toggleSelectStoreModal,
} from "../redux/selectStoreActions";
import { storeAddressType } from "../types";
export default function SelectStoreModal() {
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const showSelectStoreMenu = useSelector(
    (s) => s.selectStoreActions.showSelectStoreMenu
  );
  const showSelectStoreModal = useSelector(
    (s) => s.selectStoreActions.showSelectStoreModal
  );
  const isOpen = useMemo(() => {
    if (isEmpty(profile)) return false;
    if (!profile.storeDefaultFilter) return !showSelectStoreMenu;
    return showSelectStoreModal;
  }, [profile, showSelectStoreModal, showSelectStoreMenu]);
  const toggle = () => {
    dispatch(toggleSelectStoreModal());
  };
  const toggleStoreMenu = () => {
    dispatch(toggleSelectStoreMenu());
  };
  const showAddress = (data: storeAddressType | null | undefined) => {
    if (!data) return "";
    const keys: (keyof storeAddressType)[] = [
      "street",
      "houseNo",
      "postalCode",
      "city",
      "state",
      "country",
    ];
    return keys.map((key) => data[key]).join(", ");
  };
  const hasDefaultStore = !!profile.storeDefaultFilter;
  const activeStore = profile.storeDefaultFilter;
  return (
    <Fragment>
      {hasDefaultStore ? (
        <Modal isOpen={isOpen} toggle={toggle}>
          <Modal.Body>
            <div className="space-y-3 text-center border-b border-gray-100 pb-5 mb-10">
              <div className="relative w-20 h-20 bg-gray-100 rounded flex flex-center mx-auto overflow-hidden">
                {activeStore?.mainPhoto && (
                  <img
                    className="absolute block w-full h-full inset-0 object-cover"
                    src={activeStore.mainPhoto}
                    alt={activeStore.title}
                  />
                )}
              </div>
              <h6>{activeStore?.title}</h6>
              <p className="text-secondary">
                {showAddress(activeStore?.storeAddress)}
              </p>
            </div>
            <button
              className="flex items-center gap-4"
              onClick={() => {
                toggle();
                toggleStoreMenu();
              }}
            >
              <Icon.Wrapper variant="secondary">
                <Icon
                  name="RefreshSquare"
                  variant="Bold"
                  className="text-primary"
                />
              </Icon.Wrapper>
              <Text>button.changeStore</Text>
            </button>
          </Modal.Body>
          <Modal.Footer className="flex flex-center">
            <Button light onClick={toggle}>
              <Text>button.close</Text>
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal isOpen={isOpen} toggle={() => {}}>
          <Modal.Body className="space-y-3 text-center">
            <div className="w-20 h-20 bg-gray-100 rounded flex flex-center mx-auto">
              <Icon
                name="Building"
                variant="Bold"
                className="text-placeholder"
                size={48}
              />
            </div>
            <h6>
              <Text>global.selectStore</Text>
            </h6>
            <p>
              <Text>alertDescriptions.selectStore</Text>
            </p>
          </Modal.Body>
          <Modal.Footer className="flex flex-center">
            <Button light onClick={toggleStoreMenu}>
              <Text>button.selectStore</Text>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
}
