import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { useAxios, useConverters } from "../../../hooks";
import { orderListItemType, pagingDataType } from "../../../types";
type dataType = pagingDataType<orderListItemType>;
type paramsType = {
  keyword?: string;
  pageNumber: number;
  pageSize: number;
};
export default function OrdersList() {
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as dataType);
  const { convertAmount } = useConverters();
  const [params, setParams] = useState<paramsType>({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "/productservice/api/orders";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Layout>
      <Layout.Header>
        <SearchBox onSubmit={handleSetParams("keyword")} />
      </Layout.Header>
      <Layout.Body>
        <Wrapper>
          <Wrapper.Body>
            <Table.Wrapper>
              <Table>
                <Table.Body loading={loading.get}>
                  {data.items?.map((e) => (
                    <tr key={e.orderId}>
                      <td>
                        <Link
                          to={`/orders/${e.orderId}`}
                          className="flex-center w-fit gap-4"
                        >
                          <div className="flex-center w-10 h-10 rounded-full text-primary bg-primary/5">
                            <Icon name="ReceiptText" variant="Bold" size={28} />
                          </div>
                          <div className="space-y-1">
                            <span>{e.title}</span>
                            <span className="block text-secondary">
                              #{e.orderNumber}
                            </span>
                            <span className="block">
                              <Icon
                                name="LocationTick"
                                className="text-danger"
                                size={18}
                              />{" "}
                              {e.storeInfo?.title}
                            </span>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/offers/${e.offerId}`} className="space-y-1">
                          <span className="block">
                            <Icon
                              name="Link1"
                              size={18}
                              className="text-primary"
                            />{" "}
                            {e.offerTitle}
                          </span>
                          <span className="block text-secondary">
                            #{e.orderNumber}
                          </span>
                        </Link>
                      </td>
                      <td>
                        <span className="block mb-1">
                          {e.customerPersonInfo.firstName}{" "}
                          {e.customerPersonInfo.lastName}
                        </span>
                        <span className="block text-secondary">
                          #{e.customerCIMNumber}
                        </span>
                      </td>
                      <td className="text-secondary">
                        {convertAmount(e.totalAmount)}
                      </td>
                      <td>
                        <Status.Order id={e.status} />
                      </td>
                      <td>
                        <Button as={Link} to={e.orderId} light>
                          <Text>button.viewDetails</Text>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </Table.Body>
              </Table>
            </Table.Wrapper>
          </Wrapper.Body>
        </Wrapper>
        <Pagination
          pageNumber={params.pageNumber}
          setActivePage={handleSetParams("pageNumber")}
          totalItems={data.totalItems}
          totalPages={data.totalPages}
        />
      </Layout.Body>
    </Layout>
  );
}
