import { ChangeEvent, useContext, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { OrderContext } from "..";
import { Button, Drawer, Form, InputGroup, Text } from "../../../../components";
import { accept, rules } from "../../../../constants";
import { useAxios, useUploadFile } from "../../../../hooks";
import { showFileName } from "../../../../methods";
import { fileDataType, togglePropsType } from "../../../../types";
type formData = {
  title: string;
  description: string;
  file: fileDataType | null;
};
export default function PersonalDocumentMenu({
  isOpen,
  toggle,
}: togglePropsType) {
  const initData = {
    title: "",
    description: "",
    file: null,
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const { axios, loading } = useAxios();
  const uploadFile = useUploadFile();
  const { orderData, getOrderData } = useContext(OrderContext);
  const [data, setData] = useState<formData>(initData);
  const [fileLoading, setFileLoading] = useState(false);
  const handleSetValue = (key: string) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const clearData = () => {
    setData(initData);
  };
  const clickInput = () => {
    inputRef.current?.click();
  };
  const clearInputValue = () => {
    const input = inputRef.current;
    input?.value && (input.value = "");
  };
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    setFileLoading(true);
    uploadFile(file)
      .then((e) => {
        setData((p) => ({ ...p, file: e }));
      })
      .finally(() => {
        setFileLoading(false);
      });
  };
  const submit = async () => {
    if (!data.file) return toast.error("toast.error.file");
    const url = `/productservice/api/orders/${orderData.orderId}/documents`;
    const document = {
      title: data.title,
      description: data.description,
      fileType: data.file.type,
      fileSize: data.file.metaData.ActualSize,
      documentUrl: data.file.url,
      templateCode: null,
      signUrl: null,
      sign: false,
      signMandatory: false,
      isErp: false,
    };
    const body = { documents: [document] };
    axios.patch(url, body).then(() => {
      clearInputValue();
      toast.success("toast.success.addDocument");
      clearData();
      getOrderData();
      toggle();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.personalDocument</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="formControls.documentTitle"
            value={data.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
            placeholder="placeholders.documentTitle"
          />
          <InputGroup
            as="textarea"
            label="formControls.note"
            value={data.description}
            setValue={handleSetValue("description")}
            placeholder="placeholders.documentNote"
          />
          <div className="border border-gray-200 rounded flex flex-center gap-4 p-2">
            <span>
              <Text>formControls.file</Text>
            </span>
            <span className="flex-1 truncate">
              {showFileName(data.file?.url)}
            </span>
            <input
              ref={inputRef}
              hidden
              type="file"
              accept={accept.document}
              onChange={handleUploadFile}
            />
            <Button
              onClick={clickInput}
              light
              className="btn-sm"
              loading={fileLoading}
            >
              <Text>button.chooseFile</Text>
            </Button>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.createDocument</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
