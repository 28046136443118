const countries = [
  {
    id: "DE",
    name: "Deutschland",
    states: [
      {
        id: "Bayern",
        name: "Bayern",
        country: "DE",
      },
      {
        id: "Baden-Württemberg",
        name: "Baden-Württemberg",
        country: "DE",
      },
      {
        id: "Hessen",
        name: "Hessen",
        country: "DE",
      },
      {
        id: "Berlin",
        name: "Berlin",
        country: "DE",
      },
      {
        id: "Brandenburg",
        name: "Brandenburg",
        country: "DE",
      },
      {
        id: "Bremen",
        name: "Bremen",
        country: "DE",
      },
      {
        id: "Hamburg",
        name: "Hamburg",
        country: "DE",
      },
      {
        id: "Mecklenburg-Vorpommern",
        name: "Mecklenburg-Vorpommern",
        country: "DE",
      },
      {
        id: "Niedersachsen",
        name: "Niedersachsen",
        country: "DE",
      },
      {
        id: "Nordrhein-Westfalen",
        name: "Nordrhein-Westfalen",
        country: "DE",
      },
      {
        id: "Rheinland-Pfalz",
        name: "Rheinland-Pfalz",
        country: "DE",
      },
      {
        id: "Saarland",
        name: "Saarland",
        country: "DE",
      },
      {
        id: "Sachsen",
        name: "Sachsen",
        country: "DE",
      },
      {
        id: "Sachsen-Anhalt",
        name: "Sachsen-Anhalt",
        country: "DE",
      },
      {
        id: "Schleswig-Holstein",
        name: "Schleswig-Holstein",
        country: "DE",
      },
      {
        id: "Thüringen",
        name: "Thüringen",
        country: "DE",
      },
    ],
  },
];
export default countries;
// {
//     id: "AT",
//     name: "Österreich",
//     states: [
//       {
//         id: "Burgenland",
//         name: "Burgenland",
//         country: "AT",
//       },
//       {
//         id: "Kärnten",
//         name: "Kärnten",
//         country: "AT",
//       },
//       {
//         id: "Niederösterreich",
//         name: "Niederösterreich",
//         country: "AT",
//       },
//       {
//         id: "Oberösterreich",
//         name: "Oberösterreich",
//         country: "AT",
//       },
//       {
//         id: "Salzburg",
//         name: "Salzburg",
//         country: "AT",
//       },
//       {
//         id: "Steiermark",
//         name: "Steiermark",
//         country: "AT",
//       },
//       {
//         id: "Tirol",
//         name: "Tirol",
//         country: "AT",
//       },
//       {
//         id: "Vorarlberg",
//         name: "Vorarlberg",
//         country: "AT",
//       },
//     ],
//   },
