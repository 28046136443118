import { isEmpty } from "lodash";
import { Fragment, useContext, useState } from "react";
import { CustomerContext } from "..";
import { Image, Loading, Text } from "../../../../components";
import { useAxios, useConverters, useToggleState } from "../../../../hooks";
import {
  favoriteProductDetails,
  favoriteProductItem,
} from "../../../../types/favoriteProduct";
import ProductDetails from "./ProductDetails";
export default function ProductCard({
  favoriteProductId,
  title,
  articleNumber,
  addedAt,
  coverMedia,
}: favoriteProductItem) {
  const { customerData } = useContext(CustomerContext);
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const [details, setDetails] = useState({} as favoriteProductDetails);
  const [showDetails, toggleDetails] = useToggleState(false);
  const hasDetails = !isEmpty(details);
  const getDetails = () => {
    const customerId = customerData.id;
    const productId = favoriteProductId;
    const url = `/productservice/api/favoriteproducts/${productId}/${customerId}`;
    const config = { params: { cache: true } };
    axios.get(url, config).then(({ data }) => {
      setDetails(data);
      toggleDetails();
    });
  };
  return (
    <Fragment>
      <button
        type="button"
        className="relative bg-white rounded text-start p-1 pb-2 shadow overflow-hidden"
        onClick={getDetails}
      >
        <div
          data-active={loading.get}
          className="absolute flex-center inset-0 bg-white/5 backdrop-blur-sm opacity-0 pointer-events-none data-active:opacity-100 data-active:pointer-events-auto transition-opacity"
        >
          <Loading.Inline />
        </div>
        <Image
          src={coverMedia.thumbnailUrl}
          alt="1234"
          className="w-full aspect-image object-cover rounded-lg mb-2"
        />
        <div className="space-y-1 px-1">
          <h6 className="whitespace-normal">{title}</h6>
          <p className="text-sm text-secondary">#{articleNumber}</p>
          <p className="text-sm text-secondary">
            <Text>global.addedAt</Text> {convertDate(addedAt)}
          </p>
        </div>
      </button>
      <ProductDetails
        isOpen={hasDetails && showDetails}
        toggle={toggleDetails}
        details={details}
      />
    </Fragment>
  );
}
