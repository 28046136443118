import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { useAxios, useToggleState } from "../../../hooks";
import { getRandomColor } from "../../../methods";
import {
  customerFilterType,
  customerItemType,
  pagingDataType,
} from "../../../types";
import FilterMenu from "./FilterMenu";
export default function CustomersList() {
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as pagingDataType<customerItemType>);
  const [params, setParams] = useState<customerFilterType>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const [showFilterMenu, toggleFilterMenu] = useToggleState(false);
  const handleSetParams = (key: string) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p: any) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p: any) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    const url = "/accountservice/api/customerregistrations";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params, axios]);
  return (
    <React.Fragment>
      <Layout>
        <Layout.Header>
          <div className="flex-1">
            <SearchBox
              placeholder="page.customerList.searchBox"
              onSubmit={handleSetParams("keyword")}
            />
          </div>
          <Button light onClick={toggleFilterMenu}>
            <Icon name="Filter" variant="Bold" size={20} />
          </Button>
          <Button as={Link} to="new">
            <Icon name="Add" size={20} className="mr-1" />
            <Text>button.addNew</Text>
          </Button>
        </Layout.Header>
        <Layout.Body>
          <Wrapper>
            <Wrapper.Body>
              <Fragment>
                <Table.Wrapper>
                  <Table>
                    <Table.Body loading={loading.get}>
                      {data.items?.map((e, i) => (
                        <tr key={e.id}>
                          <td>
                            <Link
                              to={e.id}
                              className="flex items-center gap-3 w-fit"
                            >
                              <Avatar color={getRandomColor(i)} />
                              <div>
                                <span className="block mb-2">
                                  {e.personInfo?.firstName || "-"}{" "}
                                  {e.personInfo?.lastName || "-"}
                                </span>
                                <span className="block text-secondary">
                                  {e.cimNumber || "-"}
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td>{e.personInfo?.emailAddress || "-"}</td>
                          <td className="text-gray-900">
                            <Icon name="Location" variant="Bold" size={18} />{" "}
                            {e.addresses?.at(0)?.state || "-"}
                          </td>
                          <td>
                            <Button as={Link} to={e.id} light>
                              <Text>button.viewDetails</Text>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </Table.Body>
                  </Table>
                </Table.Wrapper>
              </Fragment>
            </Wrapper.Body>
          </Wrapper>
          <Pagination
            pageNumber={data.pageNumber}
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Layout.Body>
      </Layout>
      <FilterMenu
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
    </React.Fragment>
  );
}
