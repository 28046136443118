import React, {
  ElementType,
  HTMLInputTypeAttribute,
  ReactNode,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useConverters, useToggleState, useTranslate } from "../hooks";
import { convertPrice, replaceNonDigits, textToCapitalize } from "../methods";
import { rulesType } from "../types";
import Button from "./_Button";
import DatePicker from "./_DatePicker";
import Icon from "./_Icon";
import Modal from "./_Modal";
import Text from "./_Text";

type inputGroupProps<E extends ElementType> = {
  as?: E;
  value: any;
  setValue?: (val: any) => void;
  onClick?: () => void;
  label?: string;
  rules?: rulesType;
  append?: ReactNode;
  prepend?: ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  type?: HTMLInputTypeAttribute | "price";
};
export default function InputGroup<E extends ElementType>({
  as,
  value = "",
  label = "",
  rules = [],
  setValue = () => {},
  onClick = () => {},
  append = null,
  prepend = null,
  type = "text",
  placeholder,
  readOnly,
}: inputGroupProps<E>) {
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const { convertDate } = useConverters();
  const Component = as || "input";
  const isDate = type === "date";
  const isPrice = type === "price";
  const isText = type === "text";
  const translate = useTranslate();
  const inputId = useId();
  const [isOpenCalendar, toggleCalendar] = useToggleState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleValue = useMemo(() => {
    if (isPrice) return convertPrice(String(value ?? ""));
    if (isDate) return convertDate(value);
    return value ?? "";
  }, [isPrice, isDate, value]);
  const handleChange = (e: any) => {
    // if (isDate) return setValue(new Date(e));
    if (isPrice) return setValue(replaceNonDigits(e.target.value));
    if (isText) return setValue(textToCapitalize(e.target.value));
    setValue(e.target.value);
  };
  const handleClick = () => {
    if (isDate) return toggleCalendar();
    onClick();
  };
  const handleType = useMemo(() => {
    if (isPrice) return "tel";
    if (isDate) return "text";
    return type;
  }, [type, isPrice, isDate]);
  useEffect(() => {
    inputGroupRef.current?.classList.toggle("required", !!rules.length);
  }, [rules]);
  useEffect(() => {
    inputGroupRef.current?.classList.toggle("error", !!errorMessage);
  }, [errorMessage]);
  useEffect(() => {
    const formControl = inputGroupRef.current?.querySelector(".form-control");
    // @ts-ignore: Unreachable code error
    formControl.onValid = () => {
      return rules.every((rule) => {
        const ruleValue = rule(value);
        const isValid = ruleValue === true;
        setErrorMessage(isValid ? "" : ruleValue);
        return isValid;
      });
    };
  }, [rules, value]);
  return (
    <React.Fragment>
      <div ref={inputGroupRef} className="input-group group w-full">
        {!!label && (
          <label
            htmlFor={inputId}
            className="block w-full mb-2 truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
          >
            <Text>{label}</Text>
          </label>
        )}
        <div className="input-box relative row items-stretch w-full bg-gray-50 border border-primary/10 rounded-md [&>textarea]:min-h-[10rem] [&>textarea]:py-3 [&>textarea]:leading-5 [&>*]:min-w-0 focus-within:border-primary group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md transition-colors">
          {prepend}
          <Component
            className="form-control flex-1 h-11"
            placeholder={!!placeholder ? translate(placeholder) || "" : ""}
            readOnly={readOnly}
            value={handleValue}
            onChange={handleChange}
            type={handleType}
            onClick={handleClick}
          />
          {append}
          {!!errorMessage && (
            <span className="input-group-text h-11 text-danger overflow-hidden">
              <Icon name="InfoCircle" className="animate-shake" />
            </span>
          )}
          {isDate && (
            <span className="input-group-text text-primary">
              <Icon name="Calendar" />
            </span>
          )}
          {isPrice && <span className="input-group-text">&euro;</span>}
          {!!rules.length && !label && !errorMessage && (
            <span className="input-group-text text-secondary">&lowast;</span>
          )}
        </div>
        {errorMessage && (
          <p className="text-xs text-danger mt-1">
            <Text>{errorMessage}</Text>
          </p>
        )}
      </div>
      {isDate && (
        <Modal
          modalClassName="z-40"
          isOpen={isOpenCalendar}
          toggle={toggleCalendar}
        >
          {label && (
            <Modal.Header>
              <Text>{label}</Text>
            </Modal.Header>
          )}
          <Modal.Body>
            <DatePicker
              value={value}
              setValue={(e) => {
                setValue(e);
                toggleCalendar();
              }}
            />
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <Button light onClick={toggleCalendar}>
              <Text>button.back</Text>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
}
