import { useId } from "react";
import Text from "./_Text";
import Icon from "./_Icon";

type checkBoxProps = {
  label?: string;
  value: boolean;
  setValue?: (val: boolean) => void;
};
export default function CheckBox({
  value,
  setValue = () => {},
  label,
}: checkBoxProps) {
  const id = useId();
  const hasLabel = !!label;
  return (
    <label
      data-has-label={hasLabel}
      data-active={value}
      className="group data-[data-has-label='true']:w-full flex-center gap-4"
    >
      <input
        id={id}
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
        hidden
      />
      <span className="w-6 h-6 rounded-md bg-light">
        <Icon
          name="TickSquare"
          variant="Bold"
          className="w-full h-full text-primary opacity-0 group-data-active:opacity-100 transition-opacity"
        />
      </span>
      {label && (
        <span className="flex-1">
          <Text>{label}</Text>
        </span>
      )}
    </label>
  );
}
