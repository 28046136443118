import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { ProductContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  List,
  SearchBox,
  Text,
  Types,
} from "../../../../../components";
import { useAxios } from "../../../../../hooks";
import { programType } from "../../../../../types";
type programsType = programType[];
export default function Program() {
  const { axios, loading } = useAxios();
  const { supplier, setProgram, setProduct } = useContext(ProductContext);
  const [keyword, setKeyword] = useState<string | null>(null);
  const [programs, setPrograms] = useState<programsType>([]);
  const programList = useMemo(() => {
    if (!keyword) return programs;
    return programs.filter((program) =>
      [program.title, program.code].some((val) =>
        val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  }, [keyword, programs]);
  const handleChoose = (program: programType) => {
    return () => {
      setProduct((p) => {
        const data = cloneDeep(p);
        if (!data) return data;
        data.discount = null;
        return data;
      });
      setProgram({
        attributes: program.attributes,
        code: program.code,
        programId: program.id,
        programType: program.programType,
        shortDescription: program.shortDescription,
        supplierCode: program.supplierCode,
        title: program.title,
        tenantId: program.tenantId ?? null,
        zrNumber: program.zrNumber,
      });
    };
  };
  const getPrograms = () => {
    const url = "/productservice/bysuppliercode";
    const config = { params: { suuplierCode: supplier?.code } };
    axios.get(url, config).then(({ data }) => {
      setPrograms(data);
    });
  };
  useEffect(getPrograms, [supplier?.code]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox variant="gray" onSubmit={setKeyword} />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {programList.map((e) => (
            <List.Item
              key={e.id}
              onClick={handleChoose(e)}
              className="cursor-pointer"
            >
              <Icon.Wrapper rounded>
                <Icon name="DocumentText" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                <h6 className="truncate">{e.title}</h6>
                <span className="block text-secondary truncate">#{e.code}</span>
              </div>
              <div className="flex flex-center flex-1">
                <Types.Program id={e.programType} />
              </div>
              <Button as="span" light>
                <Text>button.choose</Text>
              </Button>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
    </Fragment>
  );
}
