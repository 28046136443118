import { Fragment, useContext, useEffect, useState } from "react";
import { ProductContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { useAxios } from "../../../../../hooks";
import { supplierType } from "../../../../../types";
type suppliersType = {
  items: supplierType[];
  // pageNumber: number;
  // pageSize: number;
  totalItems: number;
  totalPages: number;
};
type paramsType = {
  keyword: string | null;
  pageNumber: number | string;
  pageSize: number;
};
export default function Supplier() {
  const { axios, loading } = useAxios();
  const { setSupplier } = useContext(ProductContext);
  const [suppliers, setSuppliers] = useState<suppliersType>({
    items: [],
    // pageNumber: 1,
    // pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  });
  const [params, setParams] = useState<paramsType>({
    keyword: "",
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleChoose = (supplier: supplierType) => {
    return () => {
      setSupplier(supplier);
    };
  };
  const getSuppliers = () => {
    const url = "/productservice/api/suppliers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setSuppliers(data);
    });
  };
  useEffect(getSuppliers, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox
          variant="gray"
          onSubmit={(keyword) =>
            setParams((p) => ({ ...p, pageNumber: 1, keyword }))
          }
        />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {suppliers.items.map((e) => (
            <List.Item
              key={e.supplierId}
              onClick={handleChoose(e)}
              className="cursor-pointer"
            >
              <Icon.Wrapper rounded>
                <Icon name="Building" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                <h6 className="block truncate">{e.name}</h6>
                <span className="block truncate text-secondary">#{e.iln}</span>
              </div>
              <Button as="span" light>
                <Text>button.choose</Text>
              </Button>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          pageNumber={+params.pageNumber}
          pageSize={params.pageSize}
          setActivePage={(pageNumber) =>
            setParams((p) => ({ ...p, pageNumber }))
          }
          totalItems={suppliers.totalItems}
          totalPages={suppliers.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
