import noItemsIcon from "../assets/icons/no items.svg";
import Text from "./_Text";
export default function NoItems() {
  return (
    <div className="w-full space-y-4 text-center p-4 text-2xl text-placeholder">
      <img
        className="block w-64 mx-auto aspect-square object-contain"
        src={noItemsIcon}
        alt="No item found"
      />
      <p className="text-3xl">
        <Text>global.noItems</Text>
      </p>
      <p className="text-base">
        <Text>global.noItems</Text>
      </p>
    </div>
  );
}
