import { Fragment, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  BackButton,
  Button,
  Form,
  Icon,
  InputGroup,
  Layout,
  Loading,
  Text,
  Toggler,
  Wrapper,
} from "../../../components";
import { SignatureModal } from "../../../containers";
import {
  useAxios,
  useConverters,
  useDataState,
  useToggleState,
} from "../../../hooks";
import { documentType } from "../../../types";
import ConfirmModal from "./ConfirmModal";
import EditMenu from "./EditMenu";
export default function DocumentDetails() {
  const { axios, loading } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const { convertDate } = useConverters();
  const isOrderDocument = location.pathname.startsWith("/orders");
  const { offerId, orderId, documentId } = useParams();
  const [data, setData, setBaseData, isChanged] = useDataState(
    {} as documentType
  );
  const [showEditMenu, toggleEditMenu] = useToggleState(false);
  const [showSignModal, toggleSignModal] = useToggleState(false);
  const [showConfirm, toggleConfirm] = useToggleState(false);
  const isTemplate = !!data.templateCode;
  const getData = () => {
    const url = isOrderDocument
      ? `/productservice/api/orders/${orderId}/documents`
      : `/productservice/api/offers/${offerId}/documents`;
    axios.get(url).then(({ data }) => {
      const document = data.find((e: documentType) => e.id === documentId);
      document && setBaseData(document);
    });
  };
  const submitSignature = (signUrl: string) => {
    const url = isOrderDocument
      ? `/productservice/api/orders/${orderId}/updatedocument`
      : `/productservice/api/offers/${offerId}/updatedocument`;
    const body = { documents: [{ ...data, sign: true, signUrl }] };
    axios.patch(url, body).then(() => {
      toast.success("toast.success.editDocument");
      toggleSignModal();
      getData();
    });
  };
  const removeDocument = () => {
    const url = isOrderDocument
      ? `/productservice/api/orders/${orderId}/document`
      : `/productservice/api/offers/${offerId}/document`;
    const config = { data: [data.id] };
    axios.delete(url, config).then(() => {
      toast.success("toast.success.removeDocument");
      const url = isOrderDocument ? `/orders/${orderId}` : `/offers/${offerId}`;
      navigate(url, { replace: true });
    });
  };
  const submit = () => {
    const url = isOrderDocument
      ? `/productservice/api/orders/${orderId}/updatedocument`
      : `/productservice/api/offers/${offerId}/updatedocument`;
    const body = { documents: [data] };
    axios.patch(url, body).then(() => {
      toast.success("toast.success.editDocument");
      setBaseData(data);
    });
  };
  useEffect(getData, [offerId, orderId, documentId, isOrderDocument]);
  return (
    <Fragment>
      <Layout as={Form} onSubmit={submit}>
        <Layout.Header>
          <BackButton className="mr-auto" />
          <Button
            variant="danger"
            onClick={toggleConfirm}
            loading={loading.delete}
          >
            <Icon name="Trash" variant="Bold" size={20} />
          </Button>
          <Button type="submit" disabled={!isChanged} loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </Layout.Header>
        <Layout.Body className="grid grid-cols-7 gap-4">
          {loading.get ? (
            <section className="col-span-full">
              <Loading.Header />
            </section>
          ) : (
            <Fragment>
              <Wrapper className="col-span-full">
                <Wrapper.Body className="flex items-start gap-4">
                  <Icon.Wrapper className="w-24 h-24" rounded>
                    <Icon
                      name="DocumentText"
                      variant="Bold"
                      className="!w-16 !h-16"
                    />
                  </Icon.Wrapper>
                  <section className="flex-1 space-y-3">
                    <h6>
                      {data.title}{" "}
                      {!isTemplate && (
                        <button
                          type="button"
                          className="align-middle text-primary ml-1"
                          onClick={toggleEditMenu}
                        >
                          <Icon name="Edit2" size={20} />
                        </button>
                      )}
                    </h6>
                    <span className="block text-secondary">
                      <Text>
                        {isTemplate
                          ? "types.document.template"
                          : "types.document.personal"}
                      </Text>
                    </span>
                    <span className="block text-secondary uppercase">
                      {data.fileType?.replace("/", " ")}
                    </span>
                  </section>
                  <section className="space-y-3 text-end">
                    <span className="bloc text-secondary">
                      <Text>global.lastUpdate</Text>:{" "}
                      {convertDate(data.updatedAt || data.createdAt)}
                    </span>
                    <span className="block text-secondary">
                      <Text>global.by</Text> :{" "}
                      {data.updatedBy || data.createdBy}
                    </span>
                  </section>
                </Wrapper.Body>
              </Wrapper>
              <div className="space-y-4 col-span-4">
                <Wrapper>
                  <Wrapper.Body>
                    <InputGroup
                      as="textarea"
                      label="formControls.description"
                      value={data.description}
                      setValue={(description) =>
                        setData((p) => ({ ...p, description }))
                      }
                    />
                  </Wrapper.Body>
                </Wrapper>
                {isTemplate && (
                  <Wrapper>
                    <Wrapper.Body className="flex items-center justify-between">
                      <Toggler
                        disabled
                        label="togglers.requiredSign"
                        value={data.signMandatory}
                        setValue={(signMandatory) =>
                          setData((p) => ({ ...p, signMandatory }))
                        }
                      />
                    </Wrapper.Body>
                  </Wrapper>
                )}
                <Wrapper>
                  <Wrapper.Body className="flex items-center justify-between">
                    <Toggler
                      disabled={isTemplate}
                      label="togglers.erp"
                      value={data.isErp}
                      setValue={(isErp) => setData((p) => ({ ...p, isErp }))}
                    />
                  </Wrapper.Body>
                </Wrapper>
                {isTemplate && data.signMandatory && (
                  <Wrapper>
                    <Wrapper.Body className="flex items-center justify-between">
                      <h6>
                        <Text>global.signedByCustomer</Text>:
                      </h6>
                      {!!data.signDate ? (
                        <span className="text-secondary">
                          {convertDate(data.signDate)}
                        </span>
                      ) : (
                        <button
                          type="button"
                          className="btn-link"
                          onClick={toggleSignModal}
                        >
                          <Text>button.clickToSign</Text>
                        </button>
                      )}
                    </Wrapper.Body>
                  </Wrapper>
                )}
                {!isTemplate && (
                  <Wrapper>
                    <Wrapper.Body className="flex items-center justify-between">
                      <Toggler
                        label="togglers.signedByCustomer"
                        value={data.sign}
                        setValue={(sign) => setData((p) => ({ ...p, sign }))}
                      />
                    </Wrapper.Body>
                  </Wrapper>
                )}
              </div>
              <Wrapper className="col-span-3">
                <Wrapper.Body>
                  {!!data.documentUrl && (
                    <embed
                      type={data.fileType}
                      src={data.documentUrl}
                      className="block w-full rounded aspect-[4/5]"
                      width="100%"
                    />
                  )}
                </Wrapper.Body>
              </Wrapper>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
      <SignatureModal
        isOpen={showSignModal}
        toggle={toggleSignModal}
        onSubmit={submitSignature}
        loading={loading.update}
      />
      <EditMenu
        isOpen={showEditMenu}
        toggle={toggleEditMenu}
        data={data}
        setData={setData}
      />
      <ConfirmModal
        isOpen={showConfirm}
        toggle={toggleConfirm}
        onConfirm={removeDocument}
      />
    </Fragment>
  );
}
