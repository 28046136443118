import { useState } from "react";
import { Button, Drawer, Form, InputGroup, Text } from "../components";
import { rules } from "../constants";
import { useAxios } from "../hooks";
import { orderListItemType, togglePropsType } from "../types";
type editOrderMenuProps = togglePropsType & {
  orderData: orderListItemType;
  onEdited: () => void;
};
type dataType = {
  title: string;
  orderNote: string;
};
export default function EditOrderMenu({
  isOpen,
  toggle,
  orderData,
  onEdited,
}: editOrderMenuProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<dataType>({
    title: orderData.title,
    orderNote: orderData.orderNote,
  });
  const handleSetValue = (key: keyof dataType) => {
    return (val: string) => setData((p) => ({ ...p, [key]: val }));
  };
  const submit = () => {
    const url = `/productservice/api/orders/${orderData.orderId}`;
    const body = {
      orderTitle: data.title,
      orderNote: data.orderNote,
      customerPersonInfo: orderData.customerPersonInfo,
      applicantFullName: orderData.applicantFullName,
      paymentDueDate: orderData.paymentDueDate,
    };
    axios.put(url, body).then(() => {
      onEdited();
      toggle();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.editOrderInfo</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="formControls.orderTitle"
            value={data.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
          />
          <InputGroup
            as="textarea"
            label="formControls.orderNote"
            value={data.orderNote}
            setValue={handleSetValue("orderNote")}
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
