const offerStatus = {
  none: 0,
  active: 1,
  accepted: 2,
  rejected: 3,
};
const orderStatus = {
  none: 0,
  active: 1,
  submitted: 2,
  pendingPayment: 3,
  paid: 4,
  cancel: 5,
};
const programType = {
  none: 0,
  manual: 1,
  configurable: 2,
};
const productType = {
  pim: 1,
  programManual: 2,
  programConfigurable: 3,
  Iwofurn: 4,
};
const enums = { productType, programType, offerStatus, orderStatus };
export default enums;
