import { Fragment, useEffect, useRef } from "react";
import { Icon, Text } from "../components";
import { useSelector, useToggleState } from "../hooks";
// import logo from "../assets/logos/logo.svg";
import { useDispatch } from "react-redux";
import logoText from "../assets/logos/logo text.svg";
import { toggleSelectStoreModal } from "../redux/selectStoreActions";
import { togglePropsType } from "../types";
import MenuItem from "./MenuItem";
import ProfileModal from "./ProfileModal";
import SelectStoreMenu from "./SelectStoreMenu";
import SelectStoreModal from "./SelectStoreModal";
import SettingsMenu from "./SettingsMenu";
export default function Menu({ isOpen, toggle }: togglePropsType) {
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const profileThumbnail = profile.companyAvatarThumbnailUrl;
  const [showProfile, toggleProfile] = useToggleState(false);
  const [showSettings, toggleSettings] = useToggleState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleSelectStore = () => {
    dispatch(toggleSelectStoreModal());
  };
  useEffect(() => {
    menuRef.current?.classList.toggle("active", isOpen);
  }, [isOpen]);
  return (
    <Fragment>
      <div
        ref={menuRef}
        className="menu group fixed flex flex-col top-0 left-0 w-20 [&.active]:w-56 h-full bg-white pt-4 pb-8 px-4 border-r border-gray-200 shadow transition-[width] z-20"
      >
        <button
          onClick={toggle}
          className="flex flex-center absolute rounded bg-[#EEF6FF] -right-[1.125rem] top-[0.875rem] overflow-hidden w-9 h-9 rotate-0 group-[.menu.active]:rotate-180 transition-transform"
        >
          <Icon
            name="Login"
            variant="Bold"
            size={24}
            className="text-primary"
          />
        </button>
        <div className="flex items-center h-8 mb-10 w-full overflow-hidden">
          <img
            className="hidden group-[.menu.active]:block min-w-[10rem] w-40 h-6 max-w-none rounded-sm object-contain"
            src={logoText}
            alt="mobelhof"
          />
          {/* <img
            className="block group-[.menu.active]:hidden min-w-[2rem] w-8 h-8 rounded-sm object-contain"
            src={logo}
            alt="mobelhof"
          /> */}
        </div>
        <div className="space-y-2">
          <h6 className="block text-sm uppercase truncate">
            <Text>menu.titles.customers</Text>
          </h6>
          <MenuItem
            label="menu.items.customers"
            icon="People"
            to="/customers"
          />
          <MenuItem
            label="menu.items.offers"
            icon="DiscountShape"
            to="/offers"
          />
          <MenuItem label="menu.items.orders" icon="ReceiptText" to="/orders" />
          <MenuItem
            label="menu.items.calendar"
            icon="CalendarTick"
            to="/calendar"
          />
        </div>
        <hr className="block bg-gray my-4" />
        <div className="space-y-2">
          <h6 className="block text-sm uppercase truncate">
            <Text>menu.titles.account</Text>
          </h6>
          <MenuItem
            label="menu.items.notifications"
            icon="Notification"
            to="/notifications"
          />
          <MenuItem
            label="menu.items.settings"
            icon="Setting2"
            onClick={toggleSettings}
          />
        </div>
        <button
          type="button"
          className="menu-item group flex items-center w-full gap-1 text-start"
          onClick={toggleSelectStore}
        >
          <i className="w-11 h-11 flex-center">
            <Icon name="LocationTick" variant="Bold" className="text-danger" />
          </i>
          <span className="flex-1 hidden group-[.menu.active]:block truncate">
            {profile.storeDefaultFilter?.title || (
              <Text>global.selectStore</Text>
            )}
          </span>
        </button>
        <button
          type="button"
          className="mt-auto flex items-center gap-1"
          onClick={toggleProfile}
        >
          {!!profileThumbnail ? (
            <img
              className="w-8 h-8 bg-secondary/10 rounded-full shadow object-cover"
              src={profileThumbnail}
              alt={profile.emailAddress}
            />
          ) : (
            <Icon.Wrapper variant="secondary" className="!p-1">
              <Icon name="User" variant="Bold" className="text-primary" />
            </Icon.Wrapper>
          )}
          <div className="flex-1 text-start hidden group-[.menu.active]:block">
            <span className="block text-sm truncate">
              {profile.firstName} {profile.lastName}
            </span>
            <span className="block text-xs text-secondary truncate">
              {profile.emailAddress}
            </span>
          </div>
          <Icon name="ArrowDown2" size={10} />
        </button>
      </div>
      <ProfileModal isOpen={showProfile} toggle={toggleProfile} />
      <SettingsMenu isOpen={showSettings} toggle={toggleSettings} />
      <SelectStoreModal />
      <SelectStoreMenu />
    </Fragment>
  );
}
// {navItems.map((e, i) => (
//   <Fragment key={e.title}>
//     {i !== 0 && <hr className="block bg-gray my-4" />}
//     <div key={e.title} className="space-y-2">
//       <h6 className="block text-sm uppercase truncate">
//         <Text>{e.title}</Text>
//       </h6>
//       {e.items.map((e) => (
//         <NavLink
//           key={e.label}
//           to={e.to}
//           className="menu-item group flex items-center w-full rounded [&.active]:bg-primary/10 [&.active]:text-primary transition-colors gap-1"
//         >
//           <div className="w-11 h-11 relative">
//             <img
//               className="absolute inset-[0.625rem] scale-100 group-[.menu-item.active]:scale-0 transition-transform"
//               src={e.icon}
//               alt={e.label}
//             />
//             <img
//               className="absolute inset-[0.625rem] scale-0 group-[.menu-item.active]:scale-100 transition-transform"
//               src={e.activeIcon}
//               alt={e.label}
//             />
//           </div>
//           <span className="flex-1 hidden group-[.menu.active]:block truncate">
//             <Text>{e.label}</Text>
//           </span>
//         </NavLink>
//       ))}
//     </div>
//   </Fragment>
// ))}
