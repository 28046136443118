import i18n from "i18next";
import en from "../langs/en";
import de from "../langs/de";
import { config } from "../constants";
const resources = {
  en: { translation: en },
  de: { translation: de },
};
i18n.init({
  resources,
  lng: config.lang,
});
export default i18n;
