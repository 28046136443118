import { getRandomColor } from "../methods";
import Icon from "./_Icon";
type avatarProps = { color?: string };
export default function Avatar({ color = getRandomColor() }: avatarProps) {
  return (
    <i
      style={{ color }}
      className="w-10 h-10 rounded-full flex-center bg-light overflow-hidden"
    >
      <Icon name="User" variant="Bold" size={40} />
    </i>
  );
}
