import { ComponentProps, useEffect, useMemo, useRef } from "react";
import imageLoadingGif from "../assets/icons/image Loading.gif";
import imageIcon from "../assets/icons/image.jpeg";
type imageProps = {
  src?: string;
  alt?: string;
  thumbnail?: string;
};
type imgProps = Omit<ComponentProps<"img">, keyof imageProps>;
export default function Image({
  src,
  alt,
  thumbnail = imageIcon,
  loading = "lazy",
  ...props
}: imageProps & imgProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const handleThumbnail = useMemo(
    () => (!!src ? imageLoadingGif : thumbnail),
    [src, thumbnail]
  );
  const handleSrc = () => {
    const element = imageRef.current;
    if (!src || !element) return;
    const image = new window.Image();
    image.src = src;
    image.onload = () => {
      element.src = src;
    };
  };
  useEffect(handleSrc, [src]);
  return (
    <img
      ref={imageRef}
      src={handleThumbnail}
      alt={alt}
      loading={loading}
      {...props}
    />
  );
}
