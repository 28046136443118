import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export default function usePrompt(message: string, when: boolean = true) {
  const { navigator } = useContext(NavigationContext);
  const reloadConfirm = () => {
    if (when) {
      window.onbeforeunload = () => message;
    }
    return () => {
      window.onbeforeunload = null;
    };
  };
  const handleNavigator = () => {
    if (!when) return;
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = window.confirm(message);
      if (result !== false) {
        push(...args);
      }
    };
    return () => {
      navigator.push = push;
    };
  };
  useEffect(handleNavigator, [navigator, when]);
  useEffect(reloadConfirm, [message, when]);
}
