import { Button, Icon, Modal, Text } from "../../components";
import { iconNameType, togglePropsType } from "../../types";
type addNewTaskProps = {
  onSelectAppointment: () => void;
  onSelectTodo: () => void;
} & togglePropsType;
type itemsType = {
  icon: iconNameType;
  label: string;
  onClick: () => void;
};
export default function AddNewTask({
  isOpen,
  toggle,
  onSelectAppointment,
  onSelectTodo,
}: addNewTaskProps) {
  const items: itemsType[] = [
    {
      icon: "Profile2User",
      label: "actionTitles.addNewAppointment",
      onClick: onSelectAppointment,
    },
    {
      icon: "TaskSquare",
      label: "actionTitles.addNewTodo",
      onClick: onSelectTodo,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.chooseCalendarTaskType</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {items.map((e) => (
          <button
            key={e.label}
            type="button"
            className="w-full flex items-center gap-4 px-3 py-5 border border-gray bg-[#F5F8FA] rounded text-start"
            onClick={() => {
              e.onClick();
              toggle();
            }}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              size={40}
              className="text-primary"
            />
            <h6 className="flex-1">
              <Text>{e.label}</Text>
            </h6>
          </button>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          light
          variant="primary"
          onClick={toggle}
          className="block w-fit mx-auto"
        >
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
