import { Fragment, useContext, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { OrderContext } from "..";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../../components";
import { deadlineItems, rules, types } from "../../../../constants";
import { useAxios, useToggleState } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
import ProductList from "./ProductList";
type incidentFormProps = togglePropsType & {
  getIncidents: () => void;
};
type dataType = {
  orderId: string;
  title: string;
  priority: number;
  origin: number;
  topic: number;
  topicDescription: string;
  customerMood: number;
  deadline: Date;
  description: string;
  orderLineItemIds: string[] | [];
};

export default function IncidentForm({
  isOpen,
  toggle,
  getIncidents,
}: incidentFormProps) {
  const { axios, loading } = useAxios();
  const { orderData } = useContext(OrderContext);
  const initData = {
    orderId: orderData.orderId,
    priority: types.incidentPriority[1].id,
    origin: types.incidentOrigin[0].id,
    orderLineItemIds: [],
  } as dataType;
  const [data, setData] = useState(initData);
  const [showProductList, toggleProductList] = useToggleState(false);
  const selectedProductValue = useMemo(() => {
    const lineItems = orderData.lineItems?.flat();
    const selectedIds = data.orderLineItemIds ?? [];
    const values = selectedIds.map(
      (id) => lineItems.find((e) => e.id === id)?.translates?.at(0)?.displayName
    );
    return values.join(", ");
  }, [orderData.lineItems, data.orderLineItemIds]);
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const hasCustomerMood = !!String(data.customerMood ?? "");
    if (!hasCustomerMood)
      return toast.error("toast.error.incidentCustomerMood");
    const url = "/productservice/api/incidents";
    const body = { ...data };
    body.priority = +body.priority;
    body.origin = +body.origin;
    body.topic = +body.topic;
    axios.post(url, body).then(() => {
      toast.success("toast.success.addIncident");
      setData(initData);
      getIncidents();
      toggle();
    });
  };
  return (
    <Fragment>
      <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header>
            <h6>
              <Text>drawerTitles.createNewIncident</Text>
            </h6>
            <span className="text-secondary">23/09/2023</span>
          </Drawer.Header>
          <Drawer.Body className="space-y-5">
            <InputGroup
              label="formControls.incidentTitle"
              value={data.title}
              placeholder="placeholders.incidentTitle"
              setValue={handleSetValue("title")}
              rules={rules.required}
            />
            <section className="flex-center gap-3">
              <div className="flex-1">
                <Select
                  label="formControls.incidentPriority"
                  value={data.priority}
                  placeholder="placeholders.incidentPriority"
                  setValue={handleSetValue("priority")}
                  items={types.incidentPriority}
                  rules={rules.required}
                />
              </div>
              <div className="flex-1">
                <Select
                  label="formControls.incidentOrigin"
                  value={data.origin}
                  placeholder="placeholders.incidentOrigin"
                  setValue={handleSetValue("origin")}
                  items={types.incidentOrigin}
                  rules={rules.required}
                />
              </div>
            </section>
            <Select
              label="formControls.incidentTopic"
              value={data.topic}
              placeholder="placeholders.incidentTopic"
              setValue={handleSetValue("topic")}
              items={types.incidentTopic}
              rules={rules.required}
            />
            <InputGroup
              as="textarea"
              label="formControls.incidentTopicDescription"
              value={data.topicDescription}
              placeholder="placeholders.incidentTopicDescription"
              setValue={handleSetValue("topicDescription")}
              rules={rules.required}
            />
            <InputGroup
              label="formControls.incidentAttachmentProducts"
              value={selectedProductValue}
              placeholder="placeholders.incidentAttachmentProducts"
              // rules={rules.required}
              onClick={toggleProductList}
              readOnly
              append={
                <span className="input-group-text text-primary">
                  <Icon name="ClipboardText" size={22} />
                </span>
              }
            />
            <section>
              <label className="block w-full mb-2 truncate after:content-['*'] after:text-danger">
                <Text>formControls.incidentCustomerMood</Text>
              </label>
              <div className="flex-center gap-3">
                {types.customerModes.map((e) => (
                  <button
                    key={e.id}
                    type="button"
                    data-active={data.customerMood === e.id}
                    className="flex-center bg-gray-50 border border-gray-50 flex-1 h-10 text-2xl rounded opacity-50 data-active:opacity-100 data-active:border-primary transition-[opacity,border-color]"
                    dangerouslySetInnerHTML={{ __html: e.name }}
                    onClick={() => handleSetValue("customerMood")(e.id)}
                  />
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <InputGroup
                label="formControls.incidentDeadline"
                value={data.deadline}
                type="date"
                placeholder="placeholders.incidentDeadline"
                setValue={handleSetValue("deadline")}
                // rules={rules.required}
              />
              <div className="flex flex-wrap gap-1">
                {deadlineItems.map((e) => (
                  <button
                    key={e.name}
                    type="button"
                    className="bg-white text-secondary border border-gray rounded p-2"
                    onClick={() => handleSetValue("deadline")(e.id)}
                  >
                    <Text>{e.name}</Text>
                  </button>
                ))}
              </div>
            </section>
            <InputGroup
              as="textarea"
              label="formControls.incidentDescription"
              value={data.description}
              placeholder="placeholders.incidentDescription"
              setValue={handleSetValue("description")}
              rules={rules.required}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-3">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>button.cancel</Text>
            </Button>
            <Button type="submit" variant="primary" loading={loading.post}>
              <Text>button.submitIncident</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      {isOpen && (
        <ProductList
          isOpen={showProductList}
          toggle={toggleProductList}
          onSubmit={handleSetValue("orderLineItemIds")}
        />
      )}
    </Fragment>
  );
}
