import { Fragment } from "react";
import { config } from "../constants";
import Icon from "./_Icon";
import Progress from "./_Progress";
import Text from "./_Text";
import Wrapper from "./_Wrapper";

type listProps = {
  length?: number;
};
type importProductLoadingProps = {
  value: number;
};
const loadingItems = [{ delay: "0" }, { delay: "-500ms" }, { delay: "0" }];
function LoadingInline() {
  return (
    <div className="w-fit flex-center gap-[2px] mx-auto p-4">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-5 h-5 rounded-full bg-primary animate-loader"
        />
      ))}
    </div>
  );
}
function LoadingTr({ length = Math.floor(config.pageSize / 2) }: listProps) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((e) => (
        <tr key={e} className="animate-pulse">
          <td>
            <section className="flex items-center gap-3 w-fit">
              <i className="w-10 h-10 rounded-full bg-gray-100" />
              <div className="max-w-full space-y-2">
                <span className="max-w-full block w-48 h-4 rounded-full bg-gray-100" />
                <span className="max-w-full block w-24 h-4 rounded-full bg-gray-100" />
              </div>
            </section>
          </td>
          <td>
            <span className="max-w-full block w-48 h-4 rounded-full bg-gray-100" />
          </td>
          <td>
            <span className="max-w-full block w-32 h-4 rounded-full bg-gray-100" />
          </td>
          <td>
            <span className="max-w-full inline-block w-24 h-10 rounded bg-gray-100" />
          </td>
        </tr>
      ))}
    </Fragment>
  );
}
function LoadingList({ length = Math.floor(config.pageSize / 2) }: listProps) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((e) => (
        <li
          key={e}
          className="animate-pulse flex items-center w-full gap-4 py-2 border-b border-dashed border-gray-100 last:border-none"
        >
          <i className="w-10 h-10 rounded bg-gray-100" />
          <div className="flex-1 space-y-2">
            <span className="max-w-full block w-28 h-4 rounded-full bg-gray-100" />
            <span className="max-w-full block w-16 h-4 rounded-full bg-gray-100" />
          </div>
          <span className="w-24 h-10 rounded bg-gray-100" />
        </li>
      ))}
    </Fragment>
  );
}
function LoadingButton() {
  return (
    <div className="absolute inset-0 m-auto w-fit h-fit flex-center gap-[2px]">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-2 h-2 rounded-full animate-loader bg-current"
        />
      ))}
    </div>
  );
}
function LoadingHeader() {
  return (
    <Fragment>
      <Wrapper className="animate-pulse">
        <Wrapper.Body className="flex items-start gap-4">
          <i className="w-24 h-24 rounded bg-gray-100" />
          <div className="flex-1 flex flex-col items-start justify-around h-24">
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-56 h-4 rounded-full bg-gray-100" />
              <span className="block w-40 h-4 rounded-full bg-gray-100" />
            </div>
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-44 h-4 rounded-full bg-gray-100" />
              <span className="block w-52 h-4 rounded-full bg-gray-100" />
            </div>
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-48 h-4 rounded-full bg-gray-100" />
              <span className="block w-48 h-4 rounded-full bg-gray-100" />
            </div>
          </div>
        </Wrapper.Body>
        <Wrapper.Footer className="flex items-center gap-4">
          <span className="block w-24 h-4 rounded-full bg-gray-100" />
          <span className="block w-28 h-4 rounded-full bg-gray-100" />
          <span className="block w-20 h-4 rounded-full bg-gray-100" />
          <span className="block w-24 h-4 rounded-full bg-gray-100" />
        </Wrapper.Footer>
      </Wrapper>
      <div className="mt-8">
        <LoadingInline />
      </div>
    </Fragment>
  );
}
function CardList() {
  return (
    <Fragment>
      {[...Array(4).keys()].map((e) => (
        <div key={e} className="bg-white rounded text-start p-1 pb-2">
          <div className="w-full aspect-image rounded-lg mb-2 bg-gray-100" />
          <div className="space-y-3 px-1">
            <div className="w-full h-5 rounded-full bg-gray-100"></div>
            <div className="w-1/2 h-3 rounded-full bg-gray-100"></div>
            <div className="w-2/3 h-3 rounded-full bg-gray-100"></div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
function ImportProductLoading({ value }: importProductLoadingProps) {
  return (
    <div className="absolute inset-0 flex-center z-10 bg-white/90">
      <div className="w-full max-w-xs flex-center flex-col text-center gap-6 py-6">
        <Icon
          name="DocumentDownload"
          variant="Bold"
          className="text-primary text-7xl animate-bounce"
        />
        <p className="text-secondary">
          <Text>global.pleaseWait</Text>
        </p>
        <Progress value={value} />
      </div>
    </div>
  );
}
const Loading = {
  Cards: CardList,
  Tr: LoadingTr,
  List: LoadingList,
  Inline: LoadingInline,
  Button: LoadingButton,
  Header: LoadingHeader,
  ImportProduct: ImportProductLoading,
};
export default Loading;
