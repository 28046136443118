import { cloneDeep } from "lodash";
import isEmpty from "lodash/isEmpty";
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import emailIcon from "../../../assets/icons/email.svg";
import telephoneIcon from "../../../assets/icons/telephone.svg";
import {
  BackButton,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { EditOrderMenu } from "../../../containers";
import { useAxios, useConverters, useToggleState } from "../../../hooks";
import { copyText } from "../../../methods";
import { documentType, orderDetailsType } from "../../../types";
import Addresses from "./Addresses";
import Documents from "./Documents";
import Incidents from "./Incidents";
import LineItems from "./LineItems";
import OfferInfoModal from "./OfferInfoModal";
import PaymentConditions from "./PaymentConditions";
import UpdateInfo from "./UpdateInfo";
type orderContextType = {
  orderData: orderDetailsType;
  setOrderData: Dispatch<SetStateAction<orderDetailsType>>;
  getOrderData: () => void;
};
export const OrderContext = createContext({} as orderContextType);
export default function OrderDetails() {
  const { axios, loading } = useAxios();
  const { orderId } = useParams();
  const { convertAmount } = useConverters();
  const tabs = [
    {
      label: "tabs.lineItems",
      Component: LineItems,
    },
    {
      label: "tabs.address",
      Component: Addresses,
    },
    {
      label: "tabs.salesDocuments",
      Component: Documents,
    },
    {
      label: "tabs.paymentConditions",
      Component: PaymentConditions,
    },
    {
      label: "tabs.incidents",
      Component: Incidents,
    },
  ];
  const [data, setData] = useState<orderDetailsType>({} as orderDetailsType);
  const [showEditMenu, toggleEditMenu] = useToggleState(false);
  const [showOfferInfo, toggleOfferInfo] = useToggleState(false);
  const [showUpdateInfoModal, toggleUpdateInfoModal] = useToggleState(false);
  const hasData = !isEmpty(data);
  const signInItems = [
    {
      icon: emailIcon,
      value: data.customerPersonInfo?.emailAddress,
    },
    {
      icon: telephoneIcon,
      value: data.customerPersonInfo?.mobileNumber,
    },
  ];
  const copyOrderNumber = () => {
    copyText(data.orderNumber).then(() => {
      toast.success("toast.success.copyOrderNumber");
    });
  };
  const getData = () => {
    const url = `/productservice/api/orders/${orderId}`;
    axios.get(url).then(({ data }) => {
      const result = cloneDeep(data);
      result.documents = result.documents?.map((e: documentType) => {
        let sign = e.sign;
        const isTemplate = !!e.templateCode;
        if (isTemplate && e.signMandatory) sign = e.sign;
        if (isTemplate && !e.signMandatory) sign = true;
        if (!isTemplate) sign = e.sign;
        return { ...e, sign };
      });
      setData(result);
    });
  };
  useEffect(getData, [orderId]);
  return (
    <Layout>
      <Tabs activeKey={tabs[0].label}>
        <Layout.Header>
          <BackButton className="mr-auto" />
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {loading.get ? (
            <Loading.Header />
          ) : (
            <Fragment>
              <Wrapper>
                <Wrapper.Body className="flex items-start gap-4">
                  <section className="flex-1 space-y-4">
                    <div className="flex items-start gap-2">
                      <Icon.Wrapper className="rounded">
                        <Icon name="ReceiptText" variant="Bold" />
                      </Icon.Wrapper>
                      <div className="flex-1">
                        <h6 className="leading-8">
                          {data.title}{" "}
                          <button
                            className="text-primary ml-1"
                            onClick={toggleEditMenu}
                          >
                            <Icon name="Edit2" size={20} />
                          </button>
                        </h6>
                        <span
                          className="block text-secondary"
                          onClick={copyOrderNumber}
                        >
                          #{data.orderNumber}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-start gap-2">
                      <Icon.Wrapper className="rounded">
                        <Icon name="User" variant="Bold" />
                      </Icon.Wrapper>
                      <div className="flex-1">
                        <h6 className="leading-8">
                          {data.customerPersonInfo?.firstName}{" "}
                          {data.customerPersonInfo?.lastName}
                        </h6>
                        <div className="text-secondary flex items-center gap-2 [&_img]:inline-block [&_img]:w-4 [&_img]:mr-1">
                          {signInItems.map((e) => (
                            <span key={e.icon} hidden={!e.value}>
                              <img src={e.icon} alt={e.value} />
                              {e.value}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="space-y-14 ml-auto flex flex-col items-end">
                    <div className="flex gap-4 items-center">
                      <span
                        hidden={!data.storeInfo}
                        className="block w-fit rounded-md border border-gray-300 py-1 px-2"
                      >
                        <Icon
                          name="LocationTick"
                          variant="Bold"
                          size={18}
                          className="text-danger"
                        />{" "}
                        {data.storeInfo?.title}
                      </span>
                      <span className="bg-gray-50 px-2 py-1 rounded-md">
                        <Icon
                          name="DollarSquare"
                          variant="Bold"
                          size={22}
                          className="text-dark"
                        />{" "}
                        {convertAmount(data.totalAmount)}
                      </span>
                      <Status.Order id={data.status} />
                    </div>
                    <div>
                      <button type="button" onClick={toggleOfferInfo}>
                        <Icon
                          name="Link1"
                          variant="Linear"
                          size={20}
                          className="text-primary"
                        />{" "}
                        <span className="btn-link">{data.offerTitle}</span>
                      </button>
                    </div>
                  </section>
                </Wrapper.Body>
                <Wrapper.Footer className="py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              <OrderContext.Provider
                value={{
                  orderData: data,
                  setOrderData: setData,
                  getOrderData: getData,
                }}
              >
                {hasData && (
                  <Fragment>
                    {tabs.map(({ Component, ...e }) => (
                      <Tabs.Item key={e.label} eventKey={e.label}>
                        <Component />
                      </Tabs.Item>
                    ))}
                    <UpdateInfo
                      isOpen={showUpdateInfoModal}
                      toggle={toggleUpdateInfoModal}
                    />
                    <OfferInfoModal
                      isOpen={showOfferInfo}
                      toggle={toggleOfferInfo}
                    />
                    <EditOrderMenu
                      isOpen={showEditMenu}
                      toggle={toggleEditMenu}
                      onEdited={getData}
                      orderData={data}
                    />
                  </Fragment>
                )}
              </OrderContext.Provider>
            </Fragment>
          )}
        </Layout.Body>
      </Tabs>
    </Layout>
  );
}
