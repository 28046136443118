import { Button, Drawer, Icon, Text } from "../../../../components";
import {
  iconNameType,
  incidentItemType,
  togglePropsType,
} from "../../../../types";
import { ReactNode, useMemo } from "react";
import { types } from "../../../../constants";
import { useConverters } from "../../../../hooks";
type incidentInfoProps = togglePropsType & {
  incident: incidentItemType;
};
type infoItemType = {
  label: string;
  value: ReactNode;
  icon: iconNameType | null;
};
export default function IncidentInfo({
  isOpen,
  toggle,
  incident,
}: incidentInfoProps) {
  const { convertDate } = useConverters();
  const [priority, origin, customerMood, topic] = useMemo(() => {
    const priority = types.incidentPriority.find(
      (e) => e.id === incident.priority
    )?.name;
    const origin = types.incidentOrigin.find(
      (e) => e.id === incident.origin
    )?.name;
    const customerMood = types.customerModes.find(
      (e) => e.id === incident.customerMood
    )?.name;
    const topic = types.incidentTopic.find(
      (e) => e.id === incident.topic
    )?.name;
    return [priority, origin, customerMood, topic].map((e) => e ?? "");
  }, [incident]);
  const infoItems: infoItemType[] = [
    {
      label: "global.createdAt",
      value: convertDate(incident.createdAt),
      icon: "Clock",
    },
    {
      label: "formControls.incidentDeadline",
      value: convertDate(incident.deadline),
      icon: "Clock",
    },
    {
      label: "formControls.incidentPriority",
      value: <Text>{priority}</Text>,
      icon: "Component",
    },
    {
      label: "formControls.incidentOrigin",
      value: <Text>{origin}</Text>,
      icon: "Component",
    },
    {
      label: "formControls.incidentCustomerMood",
      value: (
        <span
          className="text-xl"
          dangerouslySetInnerHTML={{ __html: customerMood }}
        />
      ),
      icon: null,
    },
  ];
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex-center flex-col space-y-2">
          <Icon.Wrapper>
            <Icon name="DocumentText" variant="Bold" />
          </Icon.Wrapper>
          <h6>{incident.title}</h6>
          <span className="block text-secondary">#{incident.ticketNumber}</span>
        </Drawer.Header>
        <Drawer.Body className="space-y-3 [&>hr]:h-2 [&>hr]:w-full [&>hr]:bg-gray-50">
          <section className="grid grid-cols-2 gap-x-6">
            {infoItems.map((e) => (
              <div
                key={e.label}
                className="flex items-center justify-between text-sm text-gray-900 py-4 border-b border-gray last:col-span-full last:flex-col last:border-b-0"
              >
                <h6 className="flex-1 text-[length:inherit]">
                  {e.icon && (
                    <Icon
                      name={e.icon}
                      className="mr-1 text-warning"
                      size={20}
                    />
                  )}
                  <span className="text-secondary">
                    <Text>{e.label}</Text>:
                  </span>
                </h6>
                {e.value}
              </div>
            ))}
          </section>
          <hr />
          <div className="text-center space-y-3">
            <h6>
              <Text>{topic}</Text>
            </h6>
            <p>{incident.topicDescription}</p>
          </div>
          <hr />
          <div className="text-center">
            <p>{incident.description}</p>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex-center">
          <Button light type="button" onClick={toggle}>
            <Text>button.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
