import Axios, {
  AxiosProgressEvent,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useLayoutEffect, useMemo, useState } from "react";
import { authInstance, config, loginRequest } from "../constants";
import { getToken, handleErrors } from "../methods";
import { axiosLoadingType } from "../types";
type dataType = { [key: string]: any };
const data: dataType = {};
export default function useAxios() {
  const axios = useMemo(() => Axios.create({ baseURL: config.baseUrl }), []);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const loading: axiosLoadingType = {
    get: getLoading,
    post: postLoading,
    update: updateLoading,
    delete: deleteLoading,
  };
  const loadingHandler = (method: string | undefined, value: boolean) => {
    const isGet = method === "get";
    const isPost = method === "post";
    const isUpdate = method === "put" || method === "patch";
    const isDelete = method === "delete";
    isGet && setGetLoading(value);
    isPost && setPostLoading(value);
    isUpdate && setUpdateLoading(value);
    isDelete && setDeleteLoading(value);
  };
  const progressHandler = (progressEvent: AxiosProgressEvent) => {
    const total = progressEvent.total ?? 1;
    const loaded = progressEvent.loaded;
    const percent = (loaded * 100) / total;
    const progress = Math.min(Math.round(percent), 100);
    setProgress(progress);
  };
  const reqHandler = async (req: InternalAxiosRequestConfig<any>) => {
    loadingHandler(req.method, true);
    const isGet = req.method === "get";
    const isPost = req.method === "post";
    const isPut = req.method === "put";
    const isPatch = req.method === "patch";
    const cache = req.params?.cache;
    const dataKey = `${req.url}${JSON.stringify(req.params)}`;
    const hasData = data[dataKey];
    const needCache = [isGet, cache, hasData].every(Boolean);
    const adapterData = {
      config: req,
      request: req,
      headers: req.headers,
      data: data[dataKey],
      status: 200,
      statusText: "OK",
    };
    const idToken = !needCache && (await getToken());
    if ([isPost, isPut, isPatch].some(Boolean)) {
      req.onUploadProgress = progressHandler;
    }
    if (needCache) {
      req.adapter = () => Promise.resolve(adapterData);
      return req;
    }
    if (idToken) {
      req.headers["Authorization"] = `Bearer ${idToken}`;
      req.headers["Ocp-Apim-Subscription-Key"] = config.subscriptionKey;
      return req;
    }
    authInstance.loginRedirect(loginRequest);
    return req;
  };
  const resHandler = (res: AxiosResponse<any>) => {
    loadingHandler(res.config.method, false);
    setProgress(0);
    const isNoContent = res.status === 204;
    if (isNoContent) return Promise.reject({ response: res });
    const key = `${res.config.url}${JSON.stringify(res.config.params)}`;
    const value = res.data;
    data[key] = value;
    return res;
  };
  const errHandler = (err: any) => {
    loadingHandler(err.config?.method, false);
    setProgress(0);
    // const isNetworkError = err.code === "ERR_NETWORK";
    // if (isNetworkError) return axios.request(err.config);
    handleErrors(err);
    return Promise.reject(err);
  };
  useLayoutEffect(() => {
    const req = axios.interceptors.request.use(reqHandler);
    const res = axios.interceptors.response.use(resHandler, errHandler);
    return () => {
      axios.interceptors.request.eject(req);
      axios.interceptors.response.eject(res);
    };
  }, []);
  return { axios, loading, progress };
}
