import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { ProductContext } from ".";
import { OfferContext } from "../..";
import {
  Button,
  Drawer,
  Iframe,
  Loading,
  Text,
} from "../../../../../components";
import { useAxios } from "../../../../../hooks";

export default function Iwofurn() {
  const { axios, loading } = useAxios();
  const { offerData, getOfferData } = useContext(OfferContext);
  const { supplier, program, isSubLineItem, lineItemId, toggle } =
    useContext(ProductContext);
  const [url, setUrl] = useState("");
  const iwofurnLoading = !url && loading.post;
  const internalTag = useMemo(() => {
    const time = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10 ** 5);
    return [randomNumber, time].join("");
  }, []);
  const submit = () => {
    const url = isSubLineItem
      ? `/productservice/api/offers/${offerData.offerId}/${lineItemId}/add-iwofurn-sub-line-item`
      : `/productservice/api/offers/${offerData.offerId}/add-iwofurn-line-item`;
    const body = {
      internalTag: internalTag,
      deleteAfterImport: false,
    };
    axios.post(url, body).then(() => {
      toast.success("toast.success.importIwofurnProduct");
      toggle();
      getOfferData();
    });
  };
  const getUrl = () => {
    const url = "/productservice/api/iwofurnconfigurations/iwofurn-url";
    const body = {
      storeCode: offerData.storeInfo?.code,
      supplierCode: supplier?.code,
      programCode: program?.code,
    };
    axios.post(url, body).then(({ data }) => {
      const url = new URL(data);
      url.searchParams.set("transaction", internalTag);
      setUrl(url.toString());
    });
  };
  useEffect(getUrl, []);
  return (
    <Fragment>
      <Drawer.Body>
        {iwofurnLoading ? (
          <Loading.Inline />
        ) : (
          <Iframe
            title="iwofurn"
            src={url}
            className="block w-full h-full rounded shadow"
          />
        )}
      </Drawer.Body>
      <Drawer.Footer className="flex-center">
        <Button
          loading={loading.post}
          type="button"
          variant="success"
          onClick={submit}
        >
          <Text>button.importProducts</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
