import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { CustomerContext } from "..";
import { Loading, NoItems, SearchBox, Wrapper } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { favoriteProductItem } from "../../../../types/favoriteProduct";
import ProductCard from "./ProductCard";
export default function Favorites() {
  const { customerData } = useContext(CustomerContext);
  const { axios, loading } = useAxios();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<favoriteProductItem[]>([]);
  const products = useMemo(() => {
    type keys = (keyof favoriteProductItem)[];
    const search = keyword.toLocaleLowerCase();
    const searchableKeys: keys = ["articleNumber", "title"];
    const result = data.filter((e) =>
      searchableKeys
        .map((key) => e[key] ?? "")
        .some((val) => val.toString().toLowerCase().includes(search))
    );
    return result;
  }, [keyword, data]);
  const getData = () => {
    const url = "/productservice/api/favoriteproducts";
    const config = { params: { customerId: customerData.id } };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, []);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="flex-1 lg:flex-none lg:basis-1/3">
          <SearchBox onSubmit={setKeyword} />
        </div>
        {/* <Button light>
          <Icon name="Filter" variant="Bold" />
        </Button> */}
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {loading.get ? (
          <Loading.Cards />
        ) : (
          <Fragment>
            {!!products.length ? (
              products.map((e) => (
                <ProductCard key={e.favoriteProductId} {...e} />
              ))
            ) : (
              <Wrapper className="col-span-full">
                <Wrapper.Body className="flex-center">
                  <NoItems />
                </Wrapper.Body>
              </Wrapper>
            )}
          </Fragment>
        )}
      </section>
    </Fragment>
  );
}
