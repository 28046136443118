import { Fragment, useContext } from "react";
import { CustomerContext } from ".";
import { Button, Icon, Modal, Text } from "../../../components";
import { dateFormats } from "../../../constants";
import { togglePropsType } from "../../../types";
import { useConverters } from "../../../hooks";
export default function AccountInfo({ isOpen, toggle }: togglePropsType) {
  const { convertDate } = useConverters();
  const { customerData } = useContext(CustomerContext);
  const hasAccount = !!customerData.signInInfo;
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>modalTitles.mobileAccountInfo</Text>
      </Modal.Header>
      <Modal.Body className="space-y-6">
        <div className="flex-center flex-col space-y-2">
          <Icon name="User" size={25} className="text-primary" />
          <h6>
            {hasAccount ? (
              <Fragment>
                {customerData?.signInInfo?.firstName}{" "}
                {customerData?.signInInfo?.lastName}
              </Fragment>
            ) : (
              <Fragment>
                {customerData?.personInfo?.firstName}{" "}
                {customerData?.personInfo?.lastName}
              </Fragment>
            )}
          </h6>
          <span>CIM: {customerData?.cimNumber}</span>
          <span className="text-secondary">
            {customerData?.signInInfo?.fullMobileNumber ?? "---"}
          </span>
        </div>
        <div className="flex-center flex-col space-y-2">
          <Icon name="Mobile" size={25} className="text-primary" />
          <h6>
            <Text>global.signUpDate</Text>:
          </h6>
          {hasAccount ? (
            <Fragment>
              <span className="text-secondary">
                {convertDate(customerData?.signInInfo?.createdAt)}
              </span>
              <span className="text-secondary">
                {convertDate(
                  customerData?.signInInfo?.createdAt,
                  dateFormats.onlyTime
                )}
              </span>
            </Fragment>
          ) : (
            <span className="text-danger">
              <Icon name="CloseCircle" size={20} />{" "}
              <Text>alertDescriptions.haveNotMobileAccount</Text>
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
