import { Fragment } from "react";
import { Toaster as DefaultToaster, ToastBar, toast } from "react-hot-toast";
import { useTranslate } from "../hooks";
import Icon from "./_Icon";

export default function Toaster() {
  const translate = useTranslate();
  return (
    <DefaultToaster
      position="top-center"
      toastOptions={{ duration: 5000, className: "text-lg" }}
    >
      {(t) => (
        <ToastBar
          toast={{ ...t, message: translate(t.message?.toString() ?? "") }}
          style={{
            minWidth: "400px",
            maxWidth: "500px",
            width: "auto",
            padding: "16px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
          }}
        >
          {({ message, icon }) => (
            <Fragment>
              {icon}
              <div className="flex-1 font-bold [&>*]:justify-start [&>*]:m-0 py-1 pl-3 pr-4 text-start">
                {message}
              </div>
              <button
                type="button"
                className="text-gray-900"
                onClick={() => toast.dismiss(t.id)}
              >
                <Icon name="CloseCircle" size={20} />
              </button>
            </Fragment>
          )}
        </ToastBar>
      )}
    </DefaultToaster>
  );
}
