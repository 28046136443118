import { Button, Icon, Modal, Text } from "../../../../components";
import { iconNameType, togglePropsType } from "../../../../types";
type documentTypeModalProps = togglePropsType & {
  onSelectPersonal: () => void;
  onSelectTemplate: () => void;
};
type itemType = {
  icon: iconNameType;
  label: string;
  desc: string;
  action: () => void;
};
export default function DocumentTypeModal({
  isOpen,
  toggle,
  onSelectPersonal,
  onSelectTemplate,
}: documentTypeModalProps) {
  const items: itemType[] = [
    {
      icon: "DocumentUpload",
      label: "actionTitles.personalDocument",
      desc: "actionDescriptions.personalDocument",
      action: onSelectPersonal,
    },
    {
      icon: "DocumentText",
      label: "actionTitles.templateDocument",
      desc: "actionDescriptions.templateDocument",
      action: onSelectTemplate,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.documentType</Text>
        </h6>
        {/* <p className="text-secondary">
          If you need more info, please check out{" "}
          <Link to="/" className="text-primary">
            FAQ Page
          </Link>
        </p> */}
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {items.map((e) => (
          <button
            key={e.label}
            type="button"
            className="w-full flex flex-center gap-4 p-6 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
            onClick={() => {
              e.action();
              toggle();
            }}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              className="text-primary"
              size={40}
            />
            <div className="text-start flex-1 space-y-1">
              <h6>
                <Text>{e.label}</Text>
              </h6>
              <p className="text-secondary">
                <Text>{e.desc}</Text>
              </p>
            </div>
          </button>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
