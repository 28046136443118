import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router";
import { Button, Drawer, Form, InputGroup, Text } from "../../../components";
import { rules } from "../../../constants";
import { useAxios } from "../../../hooks";
import { documentType, togglePropsType } from "../../../types";

type editMenuProps = togglePropsType & {
  data: documentType;
  setData: Dispatch<SetStateAction<documentType>>;
};
export default function EditMenu({
  isOpen,
  toggle,
  data: documentData,
  setData: setDocumentData,
}: editMenuProps) {
  const location = useLocation();
  const { axios, loading } = useAxios();
  const { offerId, orderId } = useParams();
  const isOrderDocument = location.pathname.startsWith("/orders");
  const [data, setData] = useState<documentType>(documentData);
  const isTemplate = !!data.templateCode;
  const handleSetDefaultData = () => {
    setData(documentData);
  };
  const submit = () => {
    const url = isOrderDocument
      ? `/productservice/api/orders/${orderId}/updatedocument`
      : `/productservice/api/offers/${offerId}/updatedocument`;
    const body = { documents: [data] };
    axios.patch(url, body).then(() => {
      toast.success("toast.success.editDocument");
      setDocumentData((p) => {
        const prevData = cloneDeep(p);
        prevData.title = data.title;
        return prevData;
      });
      toggle();
    });
  };
  useEffect(handleSetDefaultData, [documentData]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <Text>
            {isTemplate ? "types.document.template" : "types.document.personal"}
          </Text>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            label="formControls.documentTitle"
            value={data.title}
            setValue={(title) => setData((p) => ({ ...p, title }))}
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
