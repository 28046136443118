import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { OrderContext } from "..";
import {
  Button,
  CheckBox,
  Drawer,
  Icon,
  SearchBox,
  Text,
} from "../../../../components";
import { useConverters } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
type productListProps = togglePropsType & {
  onSubmit: (data: string[]) => void;
};
export default function ProductList({
  isOpen,
  toggle,
  onSubmit,
}: productListProps) {
  const { orderData } = useContext(OrderContext);
  const { convertAmount } = useConverters();
  const [data, setData] = useState<string[]>([]);
  const hasData = !!data.length;
  const isActive = (id: string) => {
    return data.includes(id);
  };
  const handleSelect = (id: string) => {
    return () =>
      setData((p) => {
        const data = cloneDeep(p);
        const index = data.findIndex((e) => e === id);
        if (index === -1) return [...data, id];
        data.splice(index, 1);
        return data;
      });
  };
  const handleSubmit = () => {
    onSubmit(data);
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.attachProductsIncident</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="border-b-0">
          <SearchBox variant="gray" onSubmit={() => {}} />
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          {orderData.lineItems?.flat()?.map((e) => {
            const title = e.translates?.at(0)?.displayName;
            return (
              <div
                key={e.id}
                className="flex flex-center w-full gap-4"
                onClick={handleSelect(e.id)}
              >
                <CheckBox
                  value={isActive(e.id)}
                  setValue={handleSelect(e.id)}
                />
                <div className="flex flex-center w-12 h-12 rounded-full text-primary bg-primary/10">
                  <Icon name="Box" variant="Bold" size={28} />
                </div>
                <div className="flex-1">
                  <h6 className="block w-full truncate">{title}</h6>
                  <span className="block truncate text-secondary">
                    #{e.articleNumber}
                  </span>
                </div>
                <span>{convertAmount(e.unitPrice?.amount)}</span>
              </div>
            );
          })}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={!hasData}
            onClick={handleSubmit}
          >
            <Text>button.attachProductsIncident</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
