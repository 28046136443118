import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ImportBasketContext } from ".";
import { OfferContext } from "../..";
import {
  Badge,
  Button,
  CheckBox,
  Drawer,
  Icon,
  List,
  Loading,
  Text,
} from "../../../../../components";
import { dateFormats } from "../../../../../constants";
import { useAxios, useConverters, useToggleState } from "../../../../../hooks";
import { discountLineItemType, iconNameType } from "../../../../../types";
type headerDataItemType = {
  icon: iconNameType;
  label: string;
  value: any;
};
type lineItemType = {
  id: string;
  channelProductId: string;
  articleNumber: string;
  supplierArticleNumber: string;
  supplier: {
    supplierId: string;
    code: string;
    name: string;
    description: string;
    logo: {
      logoUrl: string;
      thumbnailUrl: string;
      type: string;
    };
    address: {
      country: string;
      state: string;
      city: string;
      postalCode: string;
      street: string;
      number: string;
      name: string;
    };
    iln: string;
  };
  translates: {
    language: string;
    displayName: string;
  }[];
  programAttributes: {
    code: string;
    name: string;
    description: string;
    value: string;
    isRequired: boolean;
    isAttributeProgram: boolean;
  }[];
  programCode: string;
  productType: number;
  unitPrice: {
    currency: string;
    amount: number;
    currencySymbol: string;
  };
  quantity: number;
  shortDescription: string;
  subLineItems: Omit<lineItemType, "subLineItems">;
  categories: {
    code: string;
    translates: {
      language: string;
      name: string;
      shortDescription: string;
    }[];
  }[];
  programInfo: {
    programId: string;
    supplierCode: string;
    code: string;
    title: string;
    shortDescription: string;
    attributes: {
      code: string;
      name: string;
      description: string;
      isRequired: boolean;
    }[];
    tenantId: string;
    programType: number;
  };
  discount: discountLineItemType;
  totalSalePrice: number;
  totalDiscountAmount: number;
};
type dataType = lineItemType;
type paramsType = {
  internaqlTag: string;
  keyword: string | null;
};
export default function BasketDetails() {
  const { axios, loading, progress } = useAxios();
  const { convertAmount, convertDate } = useConverters();
  const { offerData, getOfferData } = useContext(OfferContext);
  const {
    activeBasket,
    clearActiveBasketTag,
    isSubLineItem,
    lineItemId,
    toggle,
  } = useContext(ImportBasketContext);
  const [data, setData] = useState<dataType[]>([]);
  const [params] = useState<paramsType>({
    internaqlTag: activeBasket?.tag ?? "",
    keyword: null,
  });
  // const handleSetParams = (key: keyof paramsType) => {
  //   return (value: any) =>
  //     setParams((p) => ({
  //       ...p,
  //       [key]: value,
  //     }));
  // };
  const getData = () => {
    const url = "/productservice/api/offers/get-iwofurn-basket-line-items";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const [removeAfterImport, toggleRemoveAfterImport] = useToggleState(false);
  const headerData: headerDataItemType[] = [
    {
      icon: "SidebarRight",
      label: "tableTitles.basketInternalTag",
      value: activeBasket?.internalTag,
    },
    {
      icon: "Document",
      label: "tableTitles.basketTag",
      value: activeBasket?.tag,
    },
    {
      icon: "Calendar2",
      label: "tableTitles.basketCreatedAt",
      value: convertDate(activeBasket?.stamp, dateFormats.fullDate),
    },
    {
      icon: "LocationTick",
      label: "tableTitles.basketSenderName",
      value: `${activeBasket?.senderName} (ILN: ${activeBasket?.iln})`,
    },
    {
      icon: "DollarSquare",
      label: "tableTitles.basketPrice",
      value: (
        <Badge variant="primary">{convertAmount(activeBasket?.price)}</Badge>
      ),
    },
  ];
  const submitImport = () => {
    const url = isSubLineItem
      ? `/productservice/api/offers/${offerData.offerId}/${lineItemId}/add-iwofurn-sub-line-item`
      : `/productservice/api/offers/${offerData.offerId}/add-iwofurn-line-item`;
    const body = {
      internalTag: activeBasket?.tag,
      deleteAfterImport: removeAfterImport,
    };
    axios.post(url, body).then(() => {
      toast.success("toast.success.importBasket");
      getOfferData();
      toggle();
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      {loading.post && <Loading.ImportProduct value={progress} />}
      <Drawer.Header className="space-y-3 w-full text-end">
        {headerData.map((e) => (
          <div
            key={e.label}
            className="flex items-center gap-4 whitespace-nowrap"
          >
            <p className="text-start text-secondary truncate flex-1">
              <Icon name={e.icon} variant="Bold" size={16} />{" "}
              <Text>{e.label}</Text>:
            </p>
            <p className="text-end text-gray-900">{e.value}</p>
          </div>
        ))}
      </Drawer.Header>
      {/* <Drawer.Header className="border-b-0">
        <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
      </Drawer.Header> */}
      <Drawer.Body>
        <List loading={loading.get}>
          {data.map((e) => (
            <List.Item key={e.id} className="py-4">
              <Icon.Wrapper className="self-start">
                <Icon name="Box" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1 space-y-[2px]">
                <h6 className="block text-gray-900">
                  {e.translates?.at(0)?.displayName}
                </h6>
                <p className="text-sm truncate text-secondary">
                  #{e.articleNumber}
                </p>
                <p className="text-sm text-secondary">
                  <Icon name="Document" variant="Bold" size={14} />{" "}
                  <Text>tableTitles.lineItemProgramCode</Text>:{" "}
                  <span className="text-gray-900">{e.programCode}</span>
                </p>
                <p className="text-sm text-secondary">
                  <Icon name="Building" variant="Bold" size={14} />{" "}
                  <Text>tableTitles.lineItemSupplierILN</Text>:{" "}
                  <span className="text-gray-900">{e.supplier?.iln}</span>
                </p>
              </div>
              <span className="px-4">X{e.quantity}</span>
              <span>{convertAmount(e.totalSalePrice)}</span>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
      <Drawer.Footer className="flex items-center gap-4">
        <CheckBox
          label="formControls.removeBasketAfterImport"
          value={removeAfterImport}
          setValue={toggleRemoveAfterImport}
        />
      </Drawer.Footer>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={clearActiveBasketTag}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={submitImport}
          disabled={loading.get}
        >
          <Text>button.importBasket</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
