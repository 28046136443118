import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assets/css/index.css";
import { i18n } from "./boot";
import { authInstance, loginRequest } from "./constants";
import { SettingsProvider } from "./providers";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={authInstance}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <SettingsProvider>
              <App />
            </SettingsProvider>
          </MsalAuthenticationTemplate>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </MsalProvider>
);
reportWebVitals();
