import enIcon from "../assets/icons/en.svg";
import deIcon from "../assets/icons/de.svg";
import { languageType } from "../types";
type languageItemType = {
  name: string;
  id: languageType;
  icon: string;
};
const languages: languageItemType[] = [
  {
    name: "En",
    id: "en",
    icon: enIcon,
  },
  {
    name: "De",
    id: "de",
    icon: deIcon,
  },
];
export default languages;
