type formatType = Intl.DateTimeFormatOptions;
const onlyMonth: formatType = {
  year: "numeric",
  month: "2-digit",
};
const onlyDate: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const onlyTime: formatType = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const fullDate: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const dateFormats = { onlyMonth, onlyDate, fullDate, onlyTime };
export default dateFormats;
