const now = new Date();
const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
const next3days = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 3
);
const thisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
const deadlineItems = [
  {
    name: "global.tomorrow",
    id: tomorrow,
  },
  {
    name: "global.next3days",
    id: next3days,
  },
  {
    name: "global.in7days",
    id: thisWeek,
  },
];
export default deadlineItems;
