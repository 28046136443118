import {
  ComponentProps,
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { asProps } from "../types";
import Button from "./_Button";
import Icon from "./_Icon";

type contextType = {
  toggleShow: () => void;
  onSelect: (val: any) => void;
  drop: "left" | "right";
};
type dropdownElementProps = {
  className?: string;
  children?: ReactNode;
};
type dropdownProps = {
  onSelect?: (val: any) => void;
  drop?: "left" | "right";
} & dropdownElementProps;
type dropdownItemProps = {
  eventKey?: any;
} & dropdownElementProps;
const DropdownContext = createContext({} as contextType);

function Dropdown({
  className = "",
  onSelect = () => {},
  drop = "right",
  children,
}: dropdownProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((p) => !p);
  };
  const handleClick = (e: MouseEvent) => {
    const outside = !ref.current?.contains(e.target as Node);
    outside && setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div
      ref={ref}
      data-active={show}
      className={`dropdown inline-block relative group ${className}`}
    >
      <DropdownContext.Provider value={{ toggleShow, onSelect, drop }}>
        {children}
      </DropdownContext.Provider>
    </div>
  );
}
function DropdownToggle<E extends ElementType = typeof Button>({
  as,
  className = "",
  children = null,
  ...props
}: dropdownElementProps & ComponentProps<E> & asProps<E>) {
  const Component = as || Button;
  const { toggleShow } = useContext(DropdownContext);
  return (
    <Component
      className={`dropdown-toggle ${className}`}
      onClick={toggleShow}
      {...props}
    >
      {children}
      <Icon name="ArrowDown2" size={18} className="ml-1" />
    </Component>
  );
}
function DropdownMenu({
  className = "",
  children = null,
}: dropdownElementProps) {
  const { drop } = useContext(DropdownContext);
  return (
    <div
      style={{ [drop]: 0, transformOrigin: `top ${drop}` }}
      className={`dropdown-menu absolute w-full min-w-[13rem] bg-white rounded shadow-sm border border-gray-200 top-full z-10 transition-[transform,opacity] pointer-events-none opacity-0 scale-90 group-data-active:pointer-events-auto group-data-active:opacity-100 group-data-active:scale-100 py-2 px-4 ${className}`}
    >
      {children}
    </div>
  );
}
function DropdownItem<E extends ElementType = "button">({
  as,
  className = "",
  children = null,
  eventKey = "",
  ...props
}: dropdownItemProps & ComponentProps<E> & asProps<E>) {
  const Component = as || "button";
  const { toggleShow, onSelect } = useContext(DropdownContext);
  const handleClick = () => {
    onSelect(eventKey);
    toggleShow();
  };
  return (
    <Component
      type="button"
      className={`dropdown-item block w-full text-left py-2 px-4 text-dark transition-colors border-b border-gray-100 last:border-b-0 ${className}`}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Component>
  );
}
Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
export default Dropdown;
