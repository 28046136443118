import { twMerge } from "tailwind-merge";
import Text from "./_Text";

type togglerProps = {
  label?: string;
  disabled?: boolean;
  value: boolean;
  setValue: (val: boolean) => void;
};
export default function Toggler({
  label,
  value,
  setValue,
  disabled,
}: togglerProps) {
  return (
    <div className="w-full flex items-center justify-between">
      {!!label && (
        <h6 className="flex-1">
          <Text>{label}</Text>
        </h6>
      )}
      <button
        type="button"
        className={twMerge(
          "toggler group relative w-11 h-10 px-2 py-3",
          value && "active"
        )}
        onClick={() => setValue(!value)}
        disabled={disabled}
      >
        <div className="w-full h-full bg-gray-400 rounded-full transition-colors group-[.toggler.active]:bg-primary shadow"></div>
        <div className="absolute top-0 bottom-0 left-0 w-5 h-5 group-[.toggler.active]:left-6 transition-[left] my-auto">
          <span className="absolute -inset-2 bg-gray-400 rounded-full scale-0 transition-[transform,background-color] group-[.toggler.active]:bg-primary group-[.toggler:hover]:scale-100 opacity-20" />
          <span
            style={{
              boxShadow:
                "0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f",
            }}
            className="absolute inset-0 bg-white rounded-full border border-gray-100"
          />
        </div>
      </button>
    </div>
  );
}
