import OrderDetails from "../pages/Orders/Details";
import OrdersList from "../pages/Orders/List";

const order = [
  {
    path: "/orders",
    element: <OrdersList />,
  },
  {
    path: "/orders/:orderId",
    element: <OrderDetails />,
  },
];
export default order;
