import { Fragment, useContext, useMemo, useState } from "react";
import { OrderContext } from "..";
import { Button, Icon, Table, Text, Wrapper } from "../../../../components";
import { useAxios, useConverters, useToggleState } from "../../../../hooks";
import QrCodeModal from "./QrCodeModal";

export default function PaymentConditions() {
  const { axios, loading } = useAxios();
  const { orderData } = useContext(OrderContext);
  const { convertAmount } = useConverters();
  const [showQrCodeModal, toggleQrCodeModal] = useToggleState(false);
  const [qrCode, setQrCode] = useState("");
  const quantity = useMemo(() => {
    const itemsQuantity =
      orderData.lineItems?.map((e) => e.quantity)?.reduce((a, b) => a + b, 0) ??
      0;
    const subItemsQuantity =
      orderData.lineItems
        ?.map((e) => e.subLineItems)
        ?.flat()
        ?.map((e) => e.quantity)
        ?.reduce((a, b) => a + b, 0) ?? 0;
    return itemsQuantity + subItemsQuantity;
  }, [orderData.lineItems]);
  const getQrCodeImage = () => {
    const url = `/productservice/api/orders/${orderData.orderId}/qrcode-download`;
    const config = { params: { cache: true } };
    axios.get(url, config).then(({ data }) => {
      setQrCode(data);
      toggleQrCodeModal();
    });
  };
  return (
    <Fragment>
      <h6>
        <Text>offerPaymentDetails.text</Text>
      </h6>
      <Wrapper>
        <Wrapper.Body>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text>offerPaymentDetails.charges</Text>
                </th>
                <th>
                  <Text>offerPaymentDetails.quantity</Text>
                </th>
                <th>
                  <Text>offerPaymentDetails.total</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text>offerPaymentDetails.lineItems</Text>
                </td>
                <td>{quantity}</td>
                <td>{convertAmount(orderData.totalAmount)}</td>
              </tr>
            </tbody>
          </Table>
        </Wrapper.Body>
      </Wrapper>
      {/* <Wrapper className="w-1/2 ml-auto">
        <Wrapper.Body>
          <Table>
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td className="text-end">€5,500.00</td>
              </tr>
              <tr>
                <td>Tax</td>
                <td className="text-end">€51.75</td>
              </tr>
              <tr>
                <td>Total</td>
                <td className="text-end">€5,551.75</td>
              </tr>
            </tbody>
          </Table>
        </Wrapper.Body>
      </Wrapper> */}
      <Wrapper className="w-1/2 ml-auto">
        <Wrapper.Body className="flex items-center">
          <span className="mr-auto">
            <Text>offerPaymentDetails.downPayment</Text>{" "}
          </span>
          <div>{convertAmount(orderData.downPaymentAmount)}</div>
        </Wrapper.Body>
        <Wrapper.Footer className="flex flex-center">
          <Button light onClick={getQrCodeImage} loading={loading.get}>
            <Icon name="Scanner" variant="Bold" size={20} />{" "}
            <Text>button.qrCode</Text>
          </Button>
        </Wrapper.Footer>
      </Wrapper>
      <QrCodeModal
        isOpen={showQrCodeModal}
        toggle={toggleQrCodeModal}
        qrCode={qrCode}
      />
    </Fragment>
  );
}
