import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { OrderContext } from "..";
import {
  Button,
  Icon,
  SearchBox,
  Table,
  Text,
  Types,
  Wrapper,
} from "../../../../components";
import { useAxios, useConverters, useToggleState } from "../../../../hooks";
import { incidentItemType } from "../../../../types";
import IncidentForm from "./IncidentForm";
import IncidentInfo from "./IncidentInfo";
type dataType = incidentItemType[];
export default function Incidents() {
  const { axios, loading } = useAxios();
  const { orderData } = useContext(OrderContext);
  const { convertDate } = useConverters();
  const [data, setData] = useState<dataType>([]);
  const [keyword, setKeyword] = useState<string | null>();
  const [showCreateIncident, toggleCreateIncident] = useToggleState(false);
  const [activeInfoId, setActiveInfoId] = useState("");
  const incidents = useMemo(() => {
    return data.filter((e) =>
      e.title.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase() ?? "")
    );
  }, [data, keyword]);
  const isActiveId = (id: string) => {
    return activeInfoId === id;
  };
  const clearActiveInfoId = () => {
    setActiveInfoId("");
  };
  const getData = () => {
    const url = "/productservice/api/incidents";
    const config = { params: { orderId: orderData.orderId } };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, []);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3 mr-auto">
          <SearchBox onSubmit={setKeyword} />
        </div>
        <Button type="button" onClick={toggleCreateIncident}>
          <Icon name="Add" size={20} /> <Text>button.createNewIncident</Text>
        </Button>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {incidents.map((e) => (
                  <Fragment key={e.id}>
                    <IncidentInfo
                      isOpen={isActiveId(e.id)}
                      toggle={clearActiveInfoId}
                      incident={e}
                    />
                    <tr>
                      <td>
                        <button
                          type="button"
                          className="flex items-center text-start gap-4"
                          onClick={() => setActiveInfoId(e.id)}
                        >
                          <Icon.Wrapper>
                            <Icon name="DocumentText" variant="Bold" />
                          </Icon.Wrapper>
                          <div className="space-y-1">
                            <h6>
                              {e.title}{" "}
                              <Icon
                                name="InfoCircle"
                                size={18}
                                className="text-primary"
                              />
                            </h6>
                            <span className="block text-secondary">
                              #{e.ticketNumber}
                            </span>
                          </div>
                        </button>
                      </td>
                      <td className="space-y-1 [&>*]:block [&>*]:w-fit">
                        <Types.IncidentPriority id={e.priority} />
                        <Types.IncidentOrigin id={e.origin} />
                      </td>
                      <td className="space-y-1">
                        <h6>
                          <Text>global.until</Text> ({convertDate(e.deadline)})
                        </h6>
                        <span className="block text-secondary">
                          <Text>global.createdAt</Text> (
                          {convertDate(e.createdAt)})
                        </span>
                      </td>
                      {/* <td>
                        <Badge variant="success">
                          <Text>Active API</Text>
                        </Badge>
                      </td> */}
                    </tr>
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      {/* <Pagination/> */}
      <IncidentForm
        isOpen={showCreateIncident}
        toggle={toggleCreateIncident}
        getIncidents={getData}
      />
    </Fragment>
  );
}
