import { Fragment } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { Button, Icon, Modal, Text } from "../components";
import { useAxios } from "../hooks";
import { IconVariantType, iconNameType, togglePropsType } from "../types";
type orderActionsProps = togglePropsType & {
  orderId: string;
  offerId: string;
  title: string;
  orderNumber: string;
  onEdit: () => void;
};
type actionItemType = {
  icon: iconNameType;
  variant: IconVariantType;
  label: string;
  action: () => void;
};
export default function OrderActions({
  isOpen,
  toggle,
  offerId,
  orderId,
  orderNumber,
  title,
  onEdit,
}: orderActionsProps) {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const goToOfferDetails = () => {
    navigate(`/offers/${offerId}`);
  };
  const sendOrderEmail = () => {
    const url = `/productservice/api/orders/${orderId}/sendmail`;
    axios.post(url).then(() => {
      toast.success("toast.success.sendOrderMail");
      toggle();
    });
  };
  const actions: actionItemType[] = [
    {
      icon: "Edit2",
      variant: "Bold",
      label: "actionTitles.editOrder",
      action: () => {
        onEdit();
        toggle();
      },
    },
    {
      icon: "Link1",
      variant: "Outline",
      label: "actionTitles.assignedOffer",
      action: goToOfferDetails,
    },
    {
      icon: "Send2",
      variant: "Bold",
      label: "actionTitles.sendOrderEmail",
      action: sendOrderEmail,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="mb-1">{title}</h6>
        <span className="block text-secondary">#{orderNumber}</span>
      </Modal.Header>
      <Modal.Body>
        {actions.map((e, i) => (
          <Fragment key={e.label}>
            {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
            <button
              type="button"
              className="w-full flex-center gap-4"
              disabled={loading.post}
              onClick={e.action}
            >
              <Icon.Wrapper variant="secondary">
                <Icon
                  name={e.icon}
                  variant={e.variant}
                  className="text-primary"
                />
              </Icon.Wrapper>
              <span className="flex-1 text-start">
                <Text>{e.label}</Text>
              </span>
            </button>
          </Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle} loading={loading.post}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
