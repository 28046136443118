import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { AddCustomerDataContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { countries } from "../../../constants";
import { SearchAddress } from "../../../containers";
import { searchAddressType } from "../../../types";
export default function Location() {
  const { data, setData } = useContext(AddCustomerDataContext);
  const formControls2 = [
    {
      label: ["formControls.name"],
      items: [
        {
          state: "name",
          placeholder: "placeholders.name",
        },
      ],
    },
    {
      label: ["formControls.street", "formControls.number"],
      items: [
        {
          state: "street",
          placeholder: "placeholders.street",
        },
        {
          state: "number",
          placeholder: "placeholders.number",
        },
      ],
    },
    {
      label: ["formControls.postalCode", "formControls.city"],
      items: [
        {
          state: "postalCode",
          placeholder: "placeholders.postalCode",
        },
        {
          state: "city",
          placeholder: "placeholders.city",
        },
      ],
    },
    {
      label: ["formControls.country", "formControls.state"],
      items: [
        {
          Element: Select,
          state: "country",
          placeholder: "placeholders.country",
          items: countries,
        },
        {
          Element: Select,
          state: "state",
          placeholder: "placeholders.state",
          items: countries.find((e) => e.id === data.addresses[0].country)
            ?.states,
        },
      ],
    },
  ];
  const handleSetAddress = (address: searchAddressType) => {
    setData((p: any) => {
      const data = cloneDeep(p);
      data.addresses = [{ ...p.addresses[0], ...address }];
      return data;
    });
  };
  const handleValue = (key: string) => {
    const address = data.addresses[0];
    return address[key];
  };
  const handleSetData = (key: string) => {
    return (val: any) =>
      setData((p: any) => ({
        ...p,
        addresses: [{ ...p.addresses[0], [key]: val }],
      }));
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6">
        {/* <p className="text-secondary">
          <Text>If you need more info, please check out</Text>{" "}
          <Link to="/" className="text-primary">
            <Text>FAQ Page</Text>
          </Link>
        </p> */}
        <div className="flex w-full gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>formControls.searchAddress</Text>
          </span>
          <div className="flex-[5] flex gap-4">
            <div style={{ flex: 1 }}>
              <SearchAddress setValue={handleSetAddress} />
            </div>
          </div>
        </div>
        <span className="block text-secondary text-center">
          <Text>global.orEnterManual</Text>
        </span>
        {formControls2.map((e) => (
          <div key={e.label.join(".")} className="flex w-full gap-2">
            <span className="flex-[2] leading-11 truncate">
              {e.label.map((label, i) => (
                <Fragment key={label}>
                  {i !== 0 && " / "}
                  <Text key={label}>{label}</Text>
                </Fragment>
              ))}
            </span>
            <div className="flex-[5] flex gap-4">
              {e.items.map(
                ({ Element = InputGroup, state, flex, ...e }: any) => (
                  <div key={state} style={{ flex: flex || 1 }}>
                    <Element
                      value={handleValue(state)}
                      setValue={handleSetData(state)}
                      {...e}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
}
