import { Button, Modal, Text } from "../../../components";
import { togglePropsType } from "../../../types";
type confirmModalProps = togglePropsType & {
  onConfirm: () => void;
};
export default function ConfirmModal({
  isOpen,
  toggle,
  onConfirm,
}: confirmModalProps) {
  const handleOnConfirm = () => {
    onConfirm();
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-dark">
        <Text>modalTitles.confirmDeleteDocument</Text>
      </Modal.Header>
      <Modal.Body>
        <p className="text-secondary">
          <Text>global.confirmDeleteDocument</Text>
        </p>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button light onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button variant="danger" onClick={handleOnConfirm}>
          <Text>button.delete</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
