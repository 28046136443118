import { useContext } from "react";
import { toast } from "react-hot-toast";
import { OfferContext } from "..";
import { Button, Icon, Modal, Text } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { lineItemDetailsType, togglePropsType } from "../../../../types";
type subLineItemProps =
  | {
      isSubLineItem?: never;
      lineItemId?: never;
    }
  | {
      isSubLineItem: boolean;
      lineItemId: string;
    };
type removeModalProps = subLineItemProps &
  togglePropsType & {
    data: lineItemDetailsType;
  };
export default function RemoveModal({
  isOpen,
  toggle,
  data,
  isSubLineItem = false,
  lineItemId,
}: removeModalProps) {
  const { axios, loading } = useAxios();
  const { offerData, getOfferData } = useContext(OfferContext);
  const submitRemove = () => {
    const productId = isSubLineItem ? lineItemId : data.id;
    const subProductId = isSubLineItem ? data.id : null;
    const url = isSubLineItem
      ? `/productservice/api/offers/${offerData.offerId}/${productId}/sub-line-item/${subProductId}`
      : `/productservice/api/offers/${offerData.offerId}/line-item/${productId}`;
    axios.delete(url).then(() => {
      if (isSubLineItem) {
        toast.success("toast.success.removeSubLineItem");
      } else {
        toast.success("toast.success.removeLineItem");
      }
      getOfferData();
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.deleteProduct</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-8">
        <p className="text-secondary">
          <Text>confirms.deleteProduct</Text>
        </p>
        <div className="flex items-center text-start gap-4">
          {isSubLineItem ? (
            <Icon.Wrapper className="border border-primary bg-white">
              <Icon name="Box1" variant="Linear" size={28} />
            </Icon.Wrapper>
          ) : (
            <Icon.Wrapper>
              <Icon name="Box" variant="Bold" size={28} />
            </Icon.Wrapper>
          )}
          <div className="flex-1 space-y-1">
            <span className="block">{data.translates?.at(0)?.displayName}</span>
            <span className="block text-secondary">#{data.articleNumber}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button light onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          variant="danger"
          onClick={submitRemove}
          loading={loading.delete}
        >
          <Text>button.delete</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
