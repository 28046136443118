import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { OfferContext } from ".";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../../../components";
import { rules } from "../../../constants";
import { useAxios } from "../../../hooks";
import { addressType, togglePropsType } from "../../../types";
type offerDataType = {
  offerId?: string | null;
  title: string;
  customerCIMNumber: string;
  companyPrefixOrderNumber: number;
  customerPersonInfo: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber: string;
  };
  billTo: addressType | null;
  shipTo: addressType | null;
};
export default function EditOffer({ isOpen, toggle }: togglePropsType) {
  const { axios, loading } = useAxios();
  const { offerData, setOfferData } = useContext(OfferContext);
  const [data, setData] = useState<offerDataType>({} as offerDataType);
  const submit = () => {
    const url = `/productservice/api/offers/${data.offerId}`;
    const body = { ...data };
    body.billTo = offerData.billTo;
    body.shipTo = offerData.shipTo;
    axios.put(url, body).then(() => {
      toast.success("toast.success.editOffer");
      setOfferData((p) => {
        const data = cloneDeep(p);
        data.title = body.title;
        data.customer.personInfo = {
          ...data.customer.personInfo,
          ...body.customerPersonInfo,
        };
        return data;
      });
      toggle();
    });
  };
  useEffect(() => {
    if (!isOpen) return;
    setData({
      billTo: offerData.billTo,
      shipTo: offerData.shipTo,
      offerId: offerData.offerId,
      title: offerData.title,
      customerCIMNumber: "",
      companyPrefixOrderNumber: 1000,
      customerPersonInfo: {
        firstName: offerData.customer?.personInfo?.firstName ?? "",
        lastName: offerData.customer?.personInfo?.lastName ?? "",
        emailAddress: offerData.customer?.personInfo?.emailAddress ?? "",
        mobileNumber: offerData.customer?.personInfo?.mobileNumber ?? "",
      },
    });
  }, [isOpen, offerData]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.editOffer</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Icon.Wrapper className="mx-auto">
            <Icon name="DiscountShape" variant="Bold" />
          </Icon.Wrapper>
          <InputGroup
            label="formControls.offerTitle"
            value={data.title}
            setValue={(title) => setData((p) => ({ ...p, title }))}
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerFirstName"
            value={data.customerPersonInfo?.firstName}
            setValue={(firstName) =>
              setData((p) => ({
                ...p,
                customerPersonInfo: {
                  ...p.customerPersonInfo,
                  firstName,
                },
              }))
            }
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerLastName"
            value={data.customerPersonInfo?.lastName}
            setValue={(lastName) =>
              setData((p) => ({
                ...p,
                customerPersonInfo: {
                  ...p.customerPersonInfo,
                  lastName,
                },
              }))
            }
            rules={rules.required}
          />
          <InputGroup
            label="formControls.CustomerEmailAddress"
            value={data.customerPersonInfo?.emailAddress}
            setValue={(emailAddress) =>
              setData((p) => ({
                ...p,
                customerPersonInfo: {
                  ...p.customerPersonInfo,
                  emailAddress,
                },
              }))
            }
            rules={rules.email}
          />
          <InputGroup
            label="formControls.CustomerMobileNumber"
            value={data.customerPersonInfo?.mobileNumber}
            setValue={(mobileNumber) =>
              setData((p) => ({
                ...p,
                customerPersonInfo: {
                  ...p.customerPersonInfo,
                  mobileNumber,
                },
              }))
            }
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
