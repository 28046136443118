import { Fragment } from "react";
import { Button, Icon, Modal, Text } from "../../../../components";
import {
  IconVariantType,
  iconNameType,
  lineItemDetailsType,
  togglePropsType,
} from "../../../../types";
type actionsModalType = togglePropsType & {
  onEdit: () => void;
  onRemove: () => void;
  onAddSubLineItem: () => void;
  data: lineItemDetailsType;
  isSubLineItem: boolean;
};
type actionItemType = {
  isActive: boolean;
  icon: iconNameType;
  variant: IconVariantType;
  className: string;
  label: string;
  action: () => void;
};
export default function ActionsModal({
  isOpen,
  toggle,
  onEdit,
  onRemove,
  onAddSubLineItem,
  data,
  isSubLineItem,
}: actionsModalType) {
  const items: actionItemType[] = [
    // {
    //   icon: detailsIcon,
    //   label: "Show product details",
    //   action: () => {},
    // },
    {
      isActive: true,
      icon: "Edit2",
      variant: "Bold",
      className: "text-primary",
      label: "actionTitles.editProduct",
      action: onEdit,
    },
    {
      isActive: !isSubLineItem,
      icon: "BoxAdd",
      variant: "Outline",
      className: "text-primary",
      label: "actionTitles.addSubProduct",
      action: onAddSubLineItem,
    },
    {
      isActive: true,
      icon: "Trash",
      variant: "Bold",
      className: "text-danger",
      label: "actionTitles.deleteProduct",
      action: onRemove,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-center space-y-1">
        <h6>{data.translates?.at(0)?.displayName}</h6>
        <span className="block text-secondary">#{data.articleNumber}</span>
      </Modal.Header>
      <Modal.Body>
        {items
          .filter((e) => e.isActive)
          .map((e, i) => (
            <Fragment key={e.label}>
              {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
              <button
                className="flex items-center w-full gap-4"
                onClick={() => {
                  e.action();
                  toggle();
                }}
              >
                <Icon.Wrapper variant={null} className="bg-gray">
                  <Icon
                    name={e.icon}
                    variant={e.variant}
                    className={e.className}
                  />
                </Icon.Wrapper>
                <span>
                  <Text>{e.label}</Text>
                </span>
              </button>
            </Fragment>
          ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
