import { Fragment, useContext, useMemo, useState } from "react";
import { OrderContext } from "../";
import { Icon, SearchBox, Table, Text, Wrapper } from "../../../../components";
import noProductIcon from "../../../../assets/icons/no product.svg";
import ProductRow from "./ProductRow";
import { useConverters } from "../../../../hooks";
export default function LineItems() {
  const { orderData } = useContext(OrderContext);
  const { convertAmount } = useConverters();
  const [keyword, setKeyword] = useState<null | string>("");
  const [activeSubLineItemsId, setActiveSubLineItemsId] = useState<string>("");
  const handleActiveSubLineItems = (id: string) => {
    setActiveSubLineItemsId((p) => (id === p ? "" : id));
  };
  const lineItems = useMemo(() => {
    if (!keyword) return orderData.lineItems;
    return orderData.lineItems.filter((e) =>
      [e.translates[0].displayName, e.articleNumber].some((str) =>
        str.includes(keyword)
      )
    );
  }, [keyword, orderData.lineItems]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3 mr-auto">
          <SearchBox onSubmit={setKeyword} />
        </div>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <tbody>
                {!lineItems.length ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="w-2/5 mx-auto">
                        <img
                          className="block w-full"
                          src={noProductIcon}
                          alt="no product"
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    {lineItems.map((lineItem) => (
                      <Fragment key={lineItem.id}>
                        <ProductRow
                          data={lineItem}
                          showSubLineItems={
                            lineItem.id === activeSubLineItemsId
                          }
                          onToggleSubLineItems={handleActiveSubLineItems}
                        />
                        {lineItem.id === activeSubLineItemsId &&
                          lineItem.subLineItems.map((subLineItem) => (
                            <ProductRow
                              key={lineItem.id + subLineItem.id}
                              data={{ ...subLineItem, subLineItems: [] }}
                              isSubLineItem
                              lineItemId={lineItem.id}
                            />
                          ))}
                      </Fragment>
                    ))}
                  </Fragment>
                )}
              </tbody>
            </Table>
          </Table.Wrapper>
          <div className="flex items-center bg-gray-50 px-4 py-2 gap-4 rounded-md">
            <Icon
              name="DollarSquare"
              variant="Bold"
              size={24}
              className="text-dark"
            />
            <span className="mr-auto">
              <Text>global.totalPrice</Text>
            </span>
            <span>{convertAmount(orderData.totalAmount)}</span>
          </div>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
