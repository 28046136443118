import { cloneDeep } from "lodash";
import { Fragment, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OfferContext } from "..";
import uploadIcon from "../../../../assets/icons/document upload.svg";
import pdfIcon from "../../../../assets/icons/pdf vector.svg";
import { SearchBox, Text } from "../../../../components";
import { SignatureModal } from "../../../../containers";
import { useAxios, useToggleState } from "../../../../hooks";
import { documentType } from "../../../../types";
import ChooseDocumentMenu from "./ChooseDocumentMenu";
import DocumentTypeModal from "./DocumentTypeModal";
import NoDocument from "./NoDocument";
import PersonalDocumentMenu from "./PersonalDocumentMenu";
export default function Documents() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const { offerData, setOfferData, isEnabled } = useContext(OfferContext);
  const [showTypeModal, toggleTypeModal] = useToggleState(false);
  const [activeSignatureId, setActiveSignatureId] = useState<string>("");
  const [showChooseMenu, toggleChooseMenu] = useToggleState(false);
  const [showPersonalMenu, togglePersonalMenu] = useToggleState(false);
  const [keyword, setKeyword] = useState<string>("");
  const showSignatureModal = (id: string) => {
    return activeSignatureId === id;
  };
  const toggleSignatureModal = () => {
    setActiveSignatureId("");
  };
  const fileIcons = {
    "application/pdf": pdfIcon,
    "image/png": pdfIcon,
  };
  const handleSubmitSearch = (search: string | null) => {
    const keyword = (search ?? "").toLowerCase();
    setKeyword(keyword);
  };
  const handleClickRequiredSign = (e: documentType) => {
    return () => {
      const documentUrl = `/offers/${offerData.offerId}/documents/${e.id}`;
      !!e.templateCode ? setActiveSignatureId(e.id) : navigate(documentUrl);
    };
  };
  const submitSignature = (signUrl: string) => {
    const url = `/productservice/api/offers/${offerData.offerId}/updatedocument`;
    const documentIndex = offerData.documents?.findIndex(
      (e) => e.id === activeSignatureId
    );
    if (
      !offerData.documents ||
      documentIndex === -1 ||
      documentIndex === undefined
    )
      return;
    const document = cloneDeep(offerData.documents[documentIndex]);
    document.signUrl = signUrl;
    document.sign = true;
    const body = { documents: [document] };
    axios.patch(url, body).then(() => {
      setOfferData((p) => {
        const prevData = cloneDeep(p);
        if (!prevData.documents) return prevData;
        prevData.documents[documentIndex].signUrl = signUrl;
        prevData.documents[documentIndex].sign = true;
        return prevData;
      });
      toggleSignatureModal();
    });
  };
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3">
          <SearchBox onSubmit={handleSubmitSearch} />
        </div>
      </section>
      <section className="grid grid-cols-5 gap-4">
        {isEnabled && (
          <button
            type="button"
            className="bg-primary/10 border border-dashed border-primary p-4 rounded text-center"
            onClick={toggleTypeModal}
          >
            <img
              className="block w-16 mx-auto mb-6"
              src={uploadIcon}
              alt="upload"
            />
            <h6 className="truncate block text-sm">
              <Text>button.addDocument</Text>
            </h6>
            <span className="truncate block text-secondary text-sm">
              <Text>button.createDocument</Text>
            </span>
          </button>
        )}
        {offerData.documents
          ?.filter((e) => e.title.toLowerCase().includes(keyword.toLowerCase()))
          .map((e) => (
            <Fragment key={e.id}>
              <div className="bg-white rounded px-2 py-4 text-center">
                <Link
                  to={`/offers/${offerData.offerId}/documents/${e.id}`}
                  className="block w-fit mx-auto mb-6"
                >
                  <img
                    className="block w-16"
                    src={fileIcons[e.fileType]}
                    alt={e.title}
                  />
                </Link>
                <h6 className="block text-sm">{e.title}</h6>
                {e.sign ? (
                  <span className="block text-secondary text-xs">
                    <Text>global.signedByCustomer</Text>
                  </span>
                ) : (
                  <button
                    type="button"
                    disabled={!isEnabled}
                    className="block w-full btn-link text-sm text-center mx-auto truncate"
                    onClick={handleClickRequiredSign(e)}
                  >
                    <Text>button.requireSigned</Text>
                  </button>
                )}
              </div>
              {isEnabled && (
                <SignatureModal
                  isOpen={showSignatureModal(e.id)}
                  toggle={toggleSignatureModal}
                  onSubmit={submitSignature}
                  loading={loading.update}
                />
              )}
            </Fragment>
          ))}
        {!isEnabled && !offerData.documents?.length && <NoDocument />}
      </section>
      {isEnabled && (
        <Fragment>
          <DocumentTypeModal
            isOpen={showTypeModal}
            toggle={toggleTypeModal}
            onSelectPersonal={togglePersonalMenu}
            onSelectTemplate={toggleChooseMenu}
          />
          <PersonalDocumentMenu
            isOpen={showPersonalMenu}
            toggle={togglePersonalMenu}
          />
          <ChooseDocumentMenu
            isOpen={showChooseMenu}
            toggle={toggleChooseMenu}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
