import { Trans, useTranslation } from "react-i18next";

type textProps = {
  children: string;
  [key: string]: string | number;
};
export default function Text({ children, ...props }: textProps) {
  const { t } = useTranslation();

  return (
    <Trans t={t} tOptions={props}>
      {children}
    </Trans>
  );
}
