const en = {
  global: {
    noItems: "No item found",
    pagination: "Showing {{ from }} to {{ to }} of {{ totalItems }} entries",
    filter: "Filter",
    customerInfo: "Customer info",
    addedAt: "Added at",
    createdAt: "Created at",
    lastUpdate: "Last update",
    until: "Until",
    updatedAt: "Updated at",
    signUpDate: "Signup date",
    searchBox: "Search...",
    searchCalendarItem: "Search task title or customer names...",
    submit: "Please submit your changes",
    logout: "Logout",
    by: "By",
    version: "Version",
    signedByCustomer: "Signed by customer",
    orEnterManual: "Or enter manual",
    noAddress: "No address here",
    tomorrow: "Tomorrow",
    next3days: "Next 3 days",
    in7days: "in 7 days",
    thisWeek: "this week",
    thisMonth: "this month",
    last6Month: "last 6 month",
    selectStore: "Select your store",
    price: "Price",
    unitPrice: "Unit price",
    finalPrice: "Final price",
    totalPrice: "Total Price",
    productPrice: "Product price",
    totalLineItemPrice: "Total line item price",
    supplierPrice: "Supplier price",
    customerAddress: "Customer address",
    noPimProduct: "No item",
    addProductManually: "Add product manually",
    confirmDeleteDocument: "Are you sure you want delete this document?",
    pleaseWait: "Please wait ...",
  },
  status: {
    customer: {
      notRegistered: "Not registered",
      onboarding: "Onboarding",
      registered: "Registered",
    },
    offer: {
      none: "None",
      active: "Active",
      accepted: "Accepted",
      rejected: "Rejected",
    },
    order: {
      none: "None",
      active: "Active",
      submitted: "Submitted",
      pendingPayment: "Pending payment",
      paid: "Paid",
      cancel: "Cancel",
    },
  },
  types: {
    gender: {
      unknown: "Unknown",
      male: "Male",
      female: "Female",
      diverse: "Diverse",
      notDefined: "Not Defined",
    },
    title: {
      unknown: "Unknown",
      dr: "Dr",
      drIng: "DrIng",
      drHC: "DrHC",
      prof: "Prof",
    },
    offerSortBy: {
      none: "None",
      creationDate: "Creation Date",
      lastUpdate: "Last Update",
      offerTitle: "Offer Title",
    },
    product: {
      pim: "Pim product",
      Iwofurn: "Iwofurn",
      programManual: "Manual",
      programConfigurable: "Configurable",
    },
    program: {
      none: "None",
      manual: "Manual",
      configurable: "Configurable",
    },
    document: {
      template: "Template",
      personal: "Personal Document",
    },
    incidentPriority: {
      high: "High",
      normal: "Normal",
      low: "Low",
    },
    incidentTopic: {
      generalRequest: "general request",
      productRequest: "product request",
      deliveryDateAgreement: "delivery date agreement",
      reserveVan: "Reserve a van",
      purchaseAgreementChange: "Purchase agreement change",
      customerComplaint: "customer complaint",
    },
    incidentOrigin: {
      phone: "Phone",
      email: "Email",
      web: "Web",
    },
  },
  rules: {
    required: "Field is required",
    email: "The email is invalid",
    postalCode: "Field must be 5 characters in length",
    integer: "Only integer are valid",
  },
  button: {
    filter: "Filter",
    action: "Action",
    addNew: "Add New",
    cancel: "Cancel",
    ok: "Ok",
    back: "Back",
    close: "Close",
    change: "Change",
    delete: "Delete",
    next: "Next",
    submit: "Submit",
    submitChanges: "Submit Changes",
    search: "Search",
    viewDetails: "View Details",
    choose: "Choose",
    submitCustomer: "Submit customer",
    deactivateAccount: "Deactivate Account",
    addOffer: "Add new Offer",
    saveAndCloseOffer: "Save and close",
    nextAndCompleteOffer: "Next and complete offer",
    rejectOffer: "Reject Offer",
    acceptOffer: "Accept Offer",
    cancelForNow: "Cancel for now",
    submitOffer: "Submit offer",
    notSet: "Not set",
    chooseFile: "Choose file",
    createDocument: "Create document",
    requireSigned: "Required to be signed",
    addDocument: "Add document",
    resetFilter: "Reset",
    changeStore: "Change store",
    selectStore: "Select store",
    clear: "Clear",
    mobileAccount: "Mobile account",
    haveNotMobileAccount: "Have not mobile account",
    clickToSign: "Click here to sign",
    showInfo: "Show info",
    addAttribute: "Add an attribute",
    addProduct: "Add Product",
    addAddress: "Add address",
    qrCode: "QR code",
    day: "Day",
    month: "Month",
    year: "Year",
    use: "Use",
    productList: "Product list",
    calculate: "Calculate",
    importProducts: "Import products",
    submitIncident: "Submit incident",
    createNewIncident: "Create new",
    attachProductsIncident: "Attach selected products",
    viewOfferDetails: "View details",
    viewOrderDetails: "View details",
    backToBasketList: "Back to list",
    importBasket: "Import basket",
    showCalendar: "Show calendar",
    hideCalendar: "Hide calendar",
    submitAppointment: "Submit appointment",
    submitTodo: "Submit to do",
  },
  tableTitles: {
    lineItemName: "Products",
    lineItemAction: "Action",
    lineItemUnitPrice: "Unit price",
    lineItemQuantity: "Quantity",
    lineItemPrice: "Line price",
    lineItemProgramCode: "Program Code",
    lineItemSupplierILN: "Supplier ILN",
    basketInternalTag: "Basket internal tag",
    basketTag: "Tag",
    basketCreatedAt: "Created at",
    basketSenderName: "Sender name",
    basketPrice: "Iwofurn basket price",
  },
  drawerTitles: {
    filter: "Filter",
    createOffer: "Create Offer",
    editOffer: "Edit offer details",
    acceptOffer: "Accept offer",
    rejectOffer: " Reject offer",
    personalDocument: "Personal Document",
    documentTemplate: "Choose document template",
    shipTo: "Ship to",
    billTo: "Bill to",
    addProduct: "Add product",
    chooseProgram: "Choose program",
    chooseSupplier: "Choose supplier",
    editOrderInfo: "Edit order info",
    createNewIncident: "Create new incident",
    attachProductsIncident: "Attach products to incident form",
    settings: "Settings",
    languages: "Languages",
    importBasket: "Import products from IWOFURN",
    addNewAppointment: "Add new appointment",
    addNewTodo: "Add new to do",
  },
  modalTitles: {
    favoriteProductDetails: "Product details",
    rejectInfo: "Reject info",
    updateInfo: "Update info",
    downPayment: "Down payment",
    documentType: "Select document Type",
    signHere: "Sign here",
    mobileAccountInfo: "Mobile account info",
    deleteProduct: "Delete product",
    selectProductList: "Select Product list",
    shareQrCodeWithCustomer: "Share this QR code with customer",
    confirmDeleteDocument: "Delete Document",
    assignedOffer: "Assigned offer",
    assignedOrder: "Assigned order",
    acceptOfferInfo: "Publish info",
    chooseCalendarTaskType: "Choose task type",
    chooseCustomer: "Choose customer",
  },
  actionTitles: {
    editOffer: "Edit",
    sendEmailOffer: "Send offer as an email",
    rejectOffer: "Reject and delete offer",
    customerDetailsOffer: "Open customer details",
    // Offer document type
    personalDocument: "Upload Document",
    templateDocument: "Choose from Templates",
    // Offer line items
    searchProduct: "Search products",
    importBasket: "Import basket",
    editProduct: "Edit product",
    addSubProduct: "Add Sub-product",
    deleteProduct: "Delete product",
    // Order Action
    editOrder: "Edit ino",
    assignedOffer: "Offer assigned",
    sendOrderEmail: "Send order as an email",
    // Calendar
    addNewAppointment: "Appointment",
    addNewTodo: "To do",
    editTask: "Edit",
    deleteTask: "Delete",
  },
  actionDescriptions: {
    // Offer document type
    personalDocument: "If you need more info, please check it out",
    templateDocument: "search and import documents from templates",
    // Offer line items
    searchProduct: "From different sources PIM, IWOFURN, ...",
    importBasket: "from IWOFURN",
  },
  tabs: {
    // Add customer
    basicInfo: "Basic info",
    contactInfo: "Contact info",
    location: "Location",
    completed: "Completed",
    // Customer details
    overview: "Overview",
    communication: "Communication channels",
    offers: "Offers",
    orders: "Orders",
    favoritesProducts: "Favorites",
    // Offer details
    lineItems: "Line items",
    address: "Address",
    salesDocuments: "Sales documents",
    paymentConditions: "Payment conditions",
    offerInfo: "Offer info",
    incidents: "Incidents",
  },
  tabsDescription: {
    // Add customer
    basicInfo: "Name & ...",
    contactInfo: "Email & number",
    location: "Address & ...",
    completed: "Woah, we are here",
  },
  formControls: {
    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    title: "Title",
    birthDate: "Date of birth",
    email: "Email address",
    countryCode: "Country code",
    mobileNumber: "Contact mobile Number",
    phoneNumber: "Contact phone Number",
    searchAddress: "Search address",
    name: "Name in address",
    street: "Street",
    number: "Number",
    postalCode: "Postal code",
    city: "City",
    state: "State",
    country: "Country",
    description: "Description",
    offerTitle: "Offer title",
    CustomerFirstName: "Customer first name",
    CustomerLastName: "Customer last name",
    CustomerEmailAddress: "Customer email address",
    CustomerMobileNumber: "Customer number",
    CustomerPhoneNumber: "Customer phone number",
    reason: "Add a reason",
    paymentDueDate: "Payment due date",
    orderTitle: "Order title",
    orderNote: "Order note",
    downPayment: "Down payment",
    documentTitle: "Document title",
    note: "Note",
    file: "File",
    addedSince: "Added since",
    productTitle: "Product title",
    articleNumber: "Article number",
    price: "Price",
    quantity: "Quantity",
    supplierArticleNumber: "Supplier article number",
    deActiveCustomer: "Confirm Account Deactivation",
    incidentTitle: "Title",
    incidentPriority: "Priority",
    incidentOrigin: "Origin",
    incidentTopic: "Topic",
    incidentTopicDescription: "Topic description",
    incidentAttachmentProducts: "Attachment products",
    incidentCustomerMood: "Customer mood",
    incidentDeadline: "Deadline",
    incidentDescription: "Incident description",
    removeBasketAfterImport: "Remove basket after import",
    calendarTaskTime: "Time",
    calendarTaskSyncToUser: "Sync to user",
    calendarTaskTitle: "Title",
    calendarTaskDescription: "Description",
  },
  togglers: {
    requiredSign: "Required to be signed",
    erp: "ERP",
    signedByCustomer: "Signed by customer",
    calendarTaskAllDay: "All day",
  },
  confirms: {
    addOffer: "I checked my store and chosen correctly.",
    acceptOffer:
      "By confirming this part, the offer will be accepted by customer.are you sure about this?",
    rejectOffer:
      "By confirming this part, the offer will be rejected by customer. are you sure about this?",
    deleteProduct: "Are you sure about removing this product?",
  },
  placeholders: {
    searchAddress: "Search address",
    firstName: "Type name",
    lastName: "Type last name",
    gender: "Select gender",
    title: "Select title",
    birthDate: "Select date of birth",
    email: "Type email",
    mobileNumber: "11 22 33 444",
    phoneNumber: "11 22 33 444",
    name: "Name",
    street: "Street",
    number: "Number",
    postalCode: "Postal code",
    city: "City",
    state: "State",
    country: "Country",
    documentTitle: "Title",
    documentNote: "Add a note about this document",
    value: "Value",
    attributeName: "Attribute name",
    code: "Code",
    searchPimProduct: "Search Product name or article number",
    incidentTitle: "Enter incident title",
    incidentPriority: "Select",
    incidentOrigin: "Select",
    incidentTopic: "Select",
    incidentTopicDescription: "Add a note about this topic",
    incidentAttachmentProducts: "Select",
    incidentDeadline: "Deadline",
    incidentDescription: "Add a note about this incident",
    searchBaskets: "Search iwofurn basket ID or name",
    calendarTaskTime: "Select",
  },
  alertTitles: {
    // Customer details
    deactivateAccount: "You are deactivating this account",
    // Offer details
    supplierLineItemRequired: "No supplier sync.",
    supplierSubLineItemRequired: "No supplier sync.",
    programLineItemRequired: "No program sync.",
    programSubLineItemRequired: "No program sync.",
    addressRequired: "Required address",
    documentRequired: "Required documents",
    paymentConditionRequired: "Required payment conditions",
    productSupplierNotSet: "Supplier not set",
    productProgramNotSet: "Program not set",
  },
  alertDescriptions: {
    // Customer details
    deactivateAccount: "This action will inform the customer by email.",
    // Offer details
    supplierLineItemRequired:
      "There is some line item in this offer that has no supplier. please check it.",
    supplierSubLineItemRequired:
      "There is some sub line item in this offer that has no supplier. please check it.",
    programLineItemRequired:
      "There is some line item in this offer that has no program. please check it.",
    programSubLineItemRequired:
      "There is some sub line item in this offer that has no program. please check it.",
    addressRequired:
      "Billing and shipping addresses not set. please check address tab.",
    documentRequired:
      "There are documents that not signed by the customer. Please check sales documents tab.",
    paymentConditionRequired:
      "Payment conditions not set. please check payment conditions tab.",
    selectStore: "You must choose your store.",
    haveNotMobileAccount: "This user have not mobile app account!",
  },
  menu: {
    titles: {
      customers: "CUSTOMERS",
      account: "ACCOUNT",
    },
    items: {
      customers: "Customers",
      offers: "Offers",
      orders: "Orders",
      calendar: "Calendar",
      notifications: "Notifications",
      settings: "Settings",
    },
  },
  toast: {
    success: {
      addCustomer: "Customer added successfully.",
      editCustomer: "Customer edited successfully.",
      removeCustomer: "Customer removed successfully.",
      createOffer: "Offer created successfully.",
      editOffer: "Offer edited successfully.",
      acceptOffer: "Offer accepted successfully.",
      rejectOffer: "Offer rejected successfully.",
      sendOfferMail: "Email sended successfully.",
      sendOrderMail: "Email sended successfully.",
      copyCimNumber: "Cim number copied successfully.",
      copyOfferNumber: "Offer number copied successfully.",
      copyOrderNumber: "Order number copied successfully.",
      setDownPayment: "Down payment set up successfully.",
      addDocument: "Document created successfully.",
      editDocument: "Document edited successfully.",
      removeDocument: "Document removed successfully.",
      addSubLineItem: "Sub line item added successfully.",
      addLineItem: "Line item added successfully.",
      editSubLineItem: "Sub line item edited successfully.",
      editLineItem: "Line item edited successfully.",
      removeSubLineItem: "Sub line item removed successfully.",
      removeLineItem: "Line item removed successfully.",
      addIncident: "Incident added successfully.",
      importIwofurnProduct: "Product imported successfully.",
      importBasket: "Your Basket items has been imported.",
    },
    error: {
      default: "An error occurred",
      file: "Please choose file",
      form: "data is not correct",
      server: "Server error",
      incidentCustomerMood: "Customer mood is required",
      8016: "Only active offer could be updated",
      8014: "Signed document could not be removed or updated",
      8028: "Make Order Not Possible When Line Item Supplier Id Null",
      8029: "Make Order Not Possible When Sub Line Item Supplier Id Null",
      8018: "Update Sub Line Item Is Not Possible When Line Item NotFound.",
      6003: "Program Attribute Code Should Be Unique.",
      5000: "Down payment should be between 0 and total amount.",
      5001: "Shipto and billto address should be added.",
      5003: "Mandatory documents should be signed.",
      5004: "Offer should have items with down payment rule.",
      1001: "Company Not Found.",
      1002: "Mobile Number Is Already Registered.",
      1003: "Device Token Is Not Valid.",
      1004: "Email Address Is Already Registered.",
      1005: "Email Confirmation Code Is Expired.",
      1006: "Email Confirmation Code Is NotValid.",
      1007: "Mobile Number Is Not Confirmed.",
      1008: "Mobile Number Verification Code Is Not Valid.",
      1009: "Pass Code Must Be Null Or Empty.",
      1010: "Pass Code Must Exists.",
      1011: "Remove Customer Is Not Possible.",
      1012: "Customer Must Accept GDPR.",
      1013: "Customer Must Be Person.",
      1014: "New Pass Code Is Not Match.",
      1015: "Mobile Number Must Registered.",
      1201: "Invalid Pass Code.",
      1301: "Customer NotFound.",
      12000: "Duplicate Iwofurn Configuration",
      12001: "Iwofurn Configuration Not Found",
      12002: "Store Iln Not Exist",
      12003: "Iwofurn Configuration Url Is Not Set",
      12004: "Iwofurn Configuration User Name Is Not Set",
      12005: "Iwofurn Configuration Password Is Not Set",
    },
  },
  offerPaymentDetails: {
    text: "You can check payment details here",
    charges: "Charges",
    quantity: "Quantity",
    total: "Total",
    lineItems: "Line items",
    downPayment: "Down payment",
  },
  page: {
    customerList: {
      searchBox: "Search...",
    },
    addNewCustomer: {
      title: "New customer",
      conditions: {
        title: "Terms & Conditions",
        text: "Must be accepted by customer.",
        gdprAccepted: "DSGVO Akzeptiert Terms & conditions",
      },
    },
    customerDetails: {
      overview: {
        removeCustomer: {
          title: "Deactivate Account",
        },
      },
      communication: {
        byPhone: "Phone",
        byApplication: "In App-Benachrichtigung",
        bySocialMedia: "Whatsapp",
        byEmail: "Email",
        bySMS: "SMS",
        byPost: "Post",
        familyBonusApplied: "TEILNAHME FAMILIENBONUS",
        newsletterSubscribed: "News letter",
      },
      offers: {
        filter: {
          status: {
            label: "Status",
          },
          sortBy: {
            label: "Sort by",
          },
        },
      },
      orders: {},
    },
    offers: {
      documents: {
        noDocumentTitle: "There is no Document",
        noDocumentText: "You can not add or delete any document",
      },
    },
    calendar: {
      noItemTitle: "This day is free",
      noItemDescription: "No item found",
    },
  },
};
export default en;
