import { EventType, SilentRequest } from "@azure/msal-browser";
import authInstance from "./_authInstance";
import { config } from "./_config";

authInstance.enableAccountStorageEvents();

const activeAccount = authInstance.getActiveAccount();
const allAccounts = authInstance.getAllAccounts();

authInstance.addEventCallback((event) => {
  // @ts-ignore: Unreachable code error
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
    // @ts-ignore: Unreachable code error
    const account = event.payload?.account;
    authInstance.setActiveAccount(account);
  }
});

const loginRequest: SilentRequest = {
  account: activeAccount || allAccounts?.at(0),
  scopes: [String(config.scope)],
};

export default loginRequest;
