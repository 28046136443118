import { Fragment, useContext } from "react";
import { OfferContext } from "..";
import { Button, Icon, Types } from "../../../../components";
import { useConverters, useToggleState } from "../../../../hooks";
import { lineItemDetailsType } from "../../../../types";
import ActionsModal from "./Actions";
import ImportBasketMenu from "./ImportBasketMenu";
import InfoMenu from "./InfoMenu";
import RemoveModal from "./RemoveModal";
import SearchProductMenu from "./SearchProductMenu";
import SelectProductModal from "./SelectProductModal";
type subLineItemProps =
  | { isSubLineItem?: never; lineItemId?: never }
  | { isSubLineItem: boolean; lineItemId: string };
type productRowProps = { data: lineItemDetailsType } & subLineItemProps;
export default function ProductRow({
  data,
  isSubLineItem = false,
  lineItemId = "",
}: productRowProps) {
  const { convertAmount } = useConverters();
  const { isEnabled } = useContext(OfferContext);
  const [showSubLineItems, toggleSubLineItems] = useToggleState(false);
  const [showInfo, toggleInfo] = useToggleState(false);
  const [showAction, toggleAction] = useToggleState(false);
  const [showEdit, toggleEdit] = useToggleState(false);
  const [showRemove, toggleRemove] = useToggleState(false);
  const [showSelectType, toggleSelectType] = useToggleState(false);
  const [showSearchMenu, toggleSearchMenu] = useToggleState(false);
  const [showImportBasketMenu, toggleImportBasketMenu] = useToggleState(false);
  const hasSupplierAndProgram = [
    data.supplier?.id,
    data.programInfo?.programId,
  ].every(Boolean);
  return (
    <Fragment>
      <tr>
        <td>
          <div className="flex items-center text-start gap-4">
            <button
              type="button"
              data-hidden={
                isSubLineItem || !data?.subLineItems?.length || undefined
              }
              data-active={showSubLineItems}
              className="text-primary data-[hidden]:opacity-0 data-[hidden]:pointer-events-none data-active:rotate-90 transition-transform"
              onClick={toggleSubLineItems}
            >
              <Icon name="ArrowRight2" variant="Bold" size={18} />
            </button>
            <button
              type="button"
              className="flex-center text-start gap-4"
              onClick={toggleInfo}
            >
              {isSubLineItem ? (
                <div className="flex-center w-12 h-12 rounded-full bg-white border border-primary text-primary">
                  <Icon name="Box1" variant="Linear" size={28} />
                </div>
              ) : (
                <div className="flex-center w-12 h-12 rounded-full text-primary bg-primary/10">
                  <Icon name="Box" variant="Bold" size={28} />
                </div>
              )}
              <div>
                <span className="block mb-1">
                  {data.translates?.at(0)?.displayName}{" "}
                  <Icon
                    name="InfoCircle"
                    size={18}
                    className={
                      hasSupplierAndProgram ? "text-primary" : "text-warning"
                    }
                  />
                </span>
                <span className="block mb-1 text-secondary">
                  #{data.articleNumber}
                </span>
                <Types.Product id={data.productType} />
              </div>
            </button>
          </div>
        </td>
        {isEnabled && (
          <td>
            <Button light onClick={toggleAction} className="btn-sm">
              <Icon name="More" size={18} className="rotate-90" />
            </Button>
          </td>
        )}
        <td className="space-y-1">
          <span className="block">{convertAmount(data.unitPrice?.amount)}</span>
          {!!data.totalDiscountAmount && !!data.discount && (
            <span className="block line-through text-sm text-secondary">
              {convertAmount(data.discount?.productPrice)}
            </span>
          )}
        </td>
        <td>x{data.quantity}</td>
        <td>
          <span className="block">{convertAmount(data.totalSalePrice)}</span>
        </td>
      </tr>
      <InfoMenu isOpen={showInfo} toggle={toggleInfo} data={data} />
      {isEnabled && (
        <Fragment>
          <ActionsModal
            data={data}
            isOpen={showAction}
            toggle={toggleAction}
            onEdit={toggleEdit}
            onRemove={toggleRemove}
            onAddSubLineItem={toggleSelectType}
            isSubLineItem={isSubLineItem}
          />
          <RemoveModal
            data={data}
            isOpen={showRemove}
            toggle={toggleRemove}
            isSubLineItem={isSubLineItem}
            lineItemId={lineItemId}
          />
          <SelectProductModal
            isOpen={showSelectType}
            toggle={toggleSelectType}
            onSelectProducts={toggleSearchMenu}
            onSelectImportBasket={toggleImportBasketMenu}
          />
          <SearchProductMenu
            data={data}
            isOpen={showEdit}
            toggle={toggleEdit}
            isSubLineItem={isSubLineItem}
            lineItemId={lineItemId}
          />
          {!isSubLineItem && (
            <Fragment>
              <SearchProductMenu
                isSubLineItem
                lineItemId={data.id}
                isOpen={showSearchMenu}
                toggle={toggleSearchMenu}
              />
              <ImportBasketMenu
                isSubLineItem
                lineItemId={data.id}
                isOpen={showImportBasketMenu}
                toggle={toggleImportBasketMenu}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      {showSubLineItems &&
        data.subLineItems.map((e) => (
          <ProductRow
            key={e.id}
            data={{ ...e, subLineItems: [] }}
            isSubLineItem
            lineItemId={data.id}
          />
        ))}
    </Fragment>
  );
}
