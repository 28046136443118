import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { OfferContext } from "../../";
import { Drawer, Form, Icon, Loading, Text } from "../../../../../components";
import { enums } from "../../../../../constants";
import { useAxios } from "../../../../../hooks";
import {
  lineItemDetailsType,
  lineItemProgramType,
  lineItemType,
  supplierType,
  togglePropsType,
} from "../../../../../types";
import Iwofurn from "./Iwofurn";
import ManualForm from "./ManualForm";
import PimProductsList from "./PimProductsList";
import Program from "./Program";
import Supplier from "./Supplier";
type searchProductContextType = {
  toggle: () => void;
  supplier: supplierType | null;
  setSupplier: Dispatch<SetStateAction<supplierType | null>>;
  clearSupplier: () => void;
  program: lineItemProgramType | null;
  setProgram: Dispatch<SetStateAction<lineItemProgramType | null>>;
  clearProgram: () => void;
  product: lineItemType | null;
  setProduct: Dispatch<SetStateAction<lineItemType | null>>;
  clearProduct: () => void;
  isPimProduct: boolean;
  isNew: boolean;
  isSubLineItem: boolean;
  lineItemId?: string | null;
};
type searchProductMenuProps = togglePropsType & {
  data?: lineItemDetailsType | null;
  isSubLineItem?: boolean;
  lineItemId?: string | null;
};
export const ProductContext = createContext({} as searchProductContextType);
export default function SearchProductMenu({
  isOpen,
  toggle,
  data: productData = null,
  lineItemId = null,
  isSubLineItem = false,
}: searchProductMenuProps) {
  const isNew = !productData?.id;
  const { axios, loading, progress } = useAxios();
  const { offerData, getOfferData } = useContext(OfferContext);
  const showLoading = loading.post || loading.update;
  const [initSupplier, initProgram, initProduct] = useMemo(() => {
    if (!productData) return [null, null, null];
    const { supplier: supplierInfo, programInfo, ...productInfo } = productData;
    const hasSupplier = !!supplierInfo?.id;
    const supplier = hasSupplier
      ? { ...supplierInfo, supplierId: supplierInfo.id ?? "" }
      : null;
    const program = hasSupplier && programInfo ? productData.programInfo : null;
    const product = {
      ...productInfo,
      programInfo,
      supplierCode: supplierInfo?.code,
    };
    return [supplier, program, product];
  }, [productData]);

  const [supplier, setSupplier] = useState<supplierType | null>(initSupplier);
  const [program, setProgram] = useState<lineItemProgramType | null>(
    initProgram
  );
  const [product, setProduct] = useState<lineItemType | null>(initProduct);

  const clearSupplier = () => {
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const clearProduct = () => {
    setProduct(null);
  };

  const isPimProduct = useMemo(() => {
    return product?.productType === enums.productType.pim;
  }, [product?.productType]);

  const isConfigurable = useMemo(() => {
    return program?.programType === enums.programType.configurable;
  }, [program?.programType]);

  const ActiveComponent = useMemo(() => {
    if (!supplier) return Supplier;
    if (!program) return Program;
    if (!product) return isConfigurable ? Iwofurn : PimProductsList;
    return ManualForm;
  }, [supplier, program, product, isConfigurable]);

  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.addProduct";
  }, [supplier, program]);

  const handleClearData = () => {
    // if (program || product) {
    //   setProduct(null);
    //   setProgram(null);
    //   return;
    // }
    if ([supplier, program, product, isNew].every(Boolean)) setProduct(null);
    if (program) return setProgram(null);
    if (supplier) return setSupplier(null);
  };

  // const resetData = () => {
  //   clearSupplier();
  //   clearProgram();
  //   clearProduct();
  // };

  const handleSetBasicData = () => {
    setSupplier(initSupplier);
    setProgram(initProgram);
    setProduct(initProduct);
  };
  // const toggle = () => {
  //   toggleDrawer();
  //   isNew ? resetData() : handleSetBasicData();
  // };
  const submitProduct = () => {
    const url = isSubLineItem
      ? `/productservice/api/offers/${offerData.offerId}/${lineItemId}/add-sub-line-item`
      : `/productservice/api/offers/${offerData.offerId}/add-line-item`;
    const body = {
      channelProductId: null,
      articleNumber: product?.articleNumber,
      price: product?.unitPrice,
      quantity: product?.quantity,
      shortDescription: product?.shortDescription,
      subLineItems: [],
      supplierArticleNumber: product?.supplierArticleNumber,
      supplierCode: supplier?.code ?? "",
      translates: product?.translates,
      productType: product?.productType ?? 3,
      discountProductPrice: product?.discount?.productPrice,
      discountFinalPrice: product?.discount?.finalPrice,
      discountDetail: product?.discount?.discountDetails,
      programId: program?.programId,
      programCode: program?.code,
      offerProgramAttributes: product?.programAttributes,
      programAttributes: product?.programAttributes,
      programType: program?.programType,
      programsAttributes: product?.programAttributes,
    };
    axios.patch(url, body).then(() => {
      const message = isSubLineItem
        ? "toast.success.addSubLineItem"
        : "toast.success.addLineItem";
      toast.success(message);
      getOfferData();
      toggle();
    });
  };
  const updateProduct = () => {
    const url = isSubLineItem
      ? `/productservice/api/offers/${offerData.offerId}/${lineItemId}/update-sub-line-item/${productData?.id}`
      : `/productservice/api/offers/${offerData.offerId}/update-line-item/${productData?.id}`;
    const body = {
      id: productData?.id,
      channelProductId: product?.channelProductId,
      articleNumber: product?.articleNumber,
      translates: product?.translates,
      price: product?.unitPrice,
      quantity: product?.quantity,
      shortDescription: product?.shortDescription,
      supplierCode: supplier?.code ?? "",
      supplierArticleNumber: product?.supplierArticleNumber,
      productType: product?.productType,
      subLineItems: product?.subLineItems,
      discountProductPrice: product?.discount?.productPrice,
      discountFinalPrice: product?.discount?.finalPrice,
      discountDetail: product?.discount?.discountDetails,
      programId: program?.programId,
      programCode: program?.code,
      programAttributes: product?.programAttributes,
      programType: program?.programType,
      programsAttributes: product?.programAttributes,
    };
    axios.patch(url, body).then(() => {
      const message = isSubLineItem
        ? "toast.success.editSubLineItem"
        : "toast.success.editLineItem";
      toast.success(message);
      getOfferData();
      toggle();
    });
  };
  const handleSubmit = () => {
    if (!product) return;
    isNew ? submitProduct() : updateProduct();
  };
  useEffect(handleSetBasicData, [isOpen]);
  // useEffect(handleSetBasicData, [initSupplier, initProgram, initProduct]);
  return (
    <Drawer
      as={Form}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
    >
      <Drawer.Menu className="relative overflow-hidden">
        {showLoading && <Loading.ImportProduct value={progress} />}
        <Drawer.Header className="space-y-4">
          <h6 className="block">
            <Text>{activeTitle}</Text>
          </h6>
          <section
            hidden={![supplier, program].some(Boolean)}
            className="flex flex-center text-start"
          >
            <div className="flex-1 space-y-4">
              {supplier && (
                <div className="flex flex-center w-full gap-4">
                  <Icon.Wrapper rounded>
                    <Icon name="Building" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="flex-1">
                    <h6 className="truncate">{supplier?.name}</h6>
                    <span className="block truncate text-secondary">
                      #{supplier?.iln}
                    </span>
                  </div>
                </div>
              )}
              {program && (
                <div className="flex flex-center w-full gap-4">
                  <Icon.Wrapper rounded>
                    <Icon name="DocumentText" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="flex-1">
                    <h6 className="truncate">{program?.title}</h6>
                    <span className="block truncate text-secondary">
                      #{program?.code}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col items-end justify-center gap-5">
              <button
                type="button"
                className="text-warning text-sm"
                onClick={handleClearData}
              >
                <Icon
                  name="ArrowForwardSquare"
                  variant="Bold"
                  size={25}
                  className="text-warning"
                />{" "}
                <Text>button.change</Text>
              </button>
            </div>
          </section>
        </Drawer.Header>
        <ProductContext.Provider
          value={{
            toggle,
            supplier,
            setSupplier,
            clearSupplier,
            program,
            setProgram,
            clearProgram,
            product,
            setProduct,
            clearProduct,
            isPimProduct,
            isNew,
            isSubLineItem,
            lineItemId,
          }}
        >
          {isOpen && <ActiveComponent />}
        </ProductContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
