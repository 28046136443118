import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { OfferFilter } from "../../../containers";
import { useAxios, useConverters, useToggleState } from "../../../hooks";
import { offerItemType, pagingDataType } from "../../../types";
import OfferActions from "./OfferActions";
type dataType = pagingDataType<offerItemType>;
type paramsType = {
  keyword?: string;
  pageNumber: number;
  pageSize?: number;
  offerStatuses?: number | null;
  cimNumber?: string;
  sortBy?: number | null;
};
export default function Offers() {
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();
  const [data, setData] = useState({} as dataType);
  const [showFilterMenu, toggleFilterMenu] = useToggleState(false);
  const [activeActionId, setActiveActionId] = useState<string | null>(null);
  const activeAction = data.items?.find((e) => e.offerId === activeActionId);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
  } as paramsType);
  const clearActiveActionId = () => {
    setActiveActionId(null);
  };
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "productservice/api/offers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Layout>
      <Layout.Header>
        <div className="flex-1">
          <SearchBox onSubmit={handleSetParams("keyword")} />
        </div>
        <Button light onClick={toggleFilterMenu}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button>
      </Layout.Header>
      <Layout.Body>
        <Wrapper>
          <Wrapper.Body>
            <Table.Wrapper>
              <Table>
                <Table.Body loading={loading.get}>
                  {data.items?.map((e) => (
                    <tr key={e.offerId}>
                      <td>
                        <Link
                          to={e.offerId}
                          className="flex-center w-fit gap-4"
                        >
                          <Icon.Wrapper>
                            <Icon name="DiscountShape" variant="Bold" />
                          </Icon.Wrapper>
                          <div className="space-y-1">
                            <h6 className="block">{e.title}</h6>
                            <span className="block text-secondary">
                              #{e.offerNumber}
                            </span>
                            <span className="block">
                              <Icon
                                name="LocationTick"
                                className="text-danger"
                                size={18}
                              />{" "}
                              {e.storeInfo?.title}
                            </span>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/customers/${e.customer.id}`}
                          className="space-y-1"
                        >
                          <h6 className="block">
                            <Icon
                              name="Link1"
                              size={18}
                              className="text-primary"
                            />{" "}
                            {e.customer?.personInfo.firstName}{" "}
                            {e.customer?.personInfo.lastName}
                          </h6>
                          <span className="block text-secondary">
                            #{e.customer?.cimNumber}
                          </span>
                        </Link>
                      </td>
                      <td>{convertAmount(e.totalAmount)}</td>
                      <td>
                        <span className="block">Vr {e.version}</span>
                        <span className="block text-secondary">
                          <Text>global.updatedAt</Text> (
                          {convertDate(e.updatedAt || e.creationAt)})
                        </span>
                      </td>
                      <td>
                        <Status.Offer id={e.statusInfo?.type} />
                      </td>
                      <td>
                        <Button
                          light
                          onClick={() => setActiveActionId(e.offerId)}
                        >
                          <Text>button.action</Text>
                          <Icon name="ArrowDown2" size={18} className="ml-1" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </Table.Body>
              </Table>
            </Table.Wrapper>
          </Wrapper.Body>
        </Wrapper>
        <Pagination
          pageNumber={params.pageNumber}
          setActivePage={handleSetParams("pageNumber")}
          totalItems={data.totalItems}
          totalPages={data.totalPages}
        />
      </Layout.Body>
      <OfferFilter
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
      <OfferActions
        isOpen={!!activeActionId}
        toggle={clearActiveActionId}
        data={activeAction}
      />
    </Layout>
  );
}
